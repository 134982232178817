import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { getSpecificNews } from "../services/newsapi";
import Loader from "./loader/loading";
import { Link } from "react-router-dom";

function Newsspecific() {
  let { id } = useParams();
  console.log(id);

  const [loading, setLoading] = useState(false);
  const [propsdata, setPropsdata] = useState("");
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);

  useEffect(() => {
    getNewsdata().then((data) => {
      setPropsdata(data.data.specificdata);
      setLoading(false);
    });
    async function getNewsdata() {
      setLoading(true);
      return await getSpecificNews(id);
    }
  }, []);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        arrayToDo = checkbox;
      } else {
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="program_container">
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="description"
              value={propsdata.description}
            />
            <div className="program_container_box">
              <h2>News Description</h2>
              <h1>{propsdata.description}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="link"
              value={propsdata.link}
            />
            <div className="program_container_box">
              <h2>News Link</h2>
              <a href={propsdata.link} target="_blank">
                <p style={{ fontSize: "20px" }}>{propsdata.link}</p>
              </a>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="bannerimg"
              value={propsdata.bannerimg}
            />
            <div className="program_container_box">
              <h2>News Banner Image</h2>
              <a href={propsdata.bannerimg} target="_blank">
                <p style={{ fontSize: "20px" }}>{propsdata.bannerimg}</p>
              </a>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="logo"
              value={propsdata.logo}
            />
            <div className="program_container_box">
              <h2>News Logo</h2>
              <a href={propsdata.logo} target="_blank">
                <p style={{ fontSize: "20px" }}>{propsdata.logo}</p>
              </a>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="act"
              value={propsdata.act}
            />
            <div className="program_container_box">
              <h2>Delete</h2>
              <h1>{propsdata.act}</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        {checkbox && checkbox.length >= 2 ? (
          <div className="floater_edit">
            <p>{checkbox.length - 1} Field Selected</p>
            <Link
              to={`/Editnews${propsdata._id}`}
              state={{ checkbox }}
              style={{ textDecoration: "none" }}
            >
              <button className="floater_button">
                <p>Edit</p>
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Newsspecific;
