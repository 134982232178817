import React, { useEffect } from "react";
import { useState } from "react";
import AddProgramModel from "./AddProgramModel";
import Loader from "./loader/loading";
import {
  getProgramTemplate,
  getProgramTemplateSchema,
  deleteTemplatePage,
} from "../services/templateapi";
import { useNavigate } from "react-router-dom";

function AddProgram() {
  const [templateId, setTemplateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [template, seTemplate] = useState();
  const [templateSchema, setTemplateSchema] = useState();

  const openAddprogramModel = () => {
    if (templateId) {
      setShow(true);
    } else {
      alert("Please enter a template");
    }
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    templateData().then((data) => {
      seTemplate(data.data);
      setLoading(false);
    });

    async function templateData() {
      setLoading(true);
      return await getProgramTemplate();
    }

    templateSchema().then((schema) => {
      setTemplateSchema(schema.data);
      setLoading(false);
    });

    async function templateSchema() {
      setLoading(false);
      return await getProgramTemplateSchema();
    }
  }, []);

  const finalStatus = (data) => {
    if (data == 1) {
      return "Started";
    } else if (data == 2) {
      return "Draft";
    } else if (data == 3) {
      return "Pending Approval";
    } else if (data == 4) {
      return "Rejected";
    } else if (data == 5) {
      return "Approved";
    }
  };

  let navigate = useNavigate();
  const navigateTemplate = (id) => {
    navigate("/TemplatePageCreation" + id);
  };

  const delteTemplatePage = async (id) => {
    setLoading(true);
    if (window.confirm("Are you sure you want to delete")) {
      await deleteTemplatePage(id);
      window.location.reload();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      {show && (
        <AddProgramModel
          show={show}
          templateId={templateId}
          handleClose={handleClose}
        />
      )}
      <div style={{ marginLeft: "5%" }}>
        <h5
          style={{
            fontSize: "16px",
            opacity: "0.85",
            color: "#3c4852",
            marginBottom: "16px",
          }}
        >
          Select Template
        </h5>
        <div>
          <div style={{ display: "flex" }}>
            <div className="">
              <select
                value={templateId}
                onChange={(e) => {
                  setTemplateId(e.target.value);
                }}
                className=""
                name="modeoflearning"
                id="modeoflearning"
                style={{
                  textDecoration: "none",
                  color: "#3c4852",
                  width: "600px",
                  height: "48px",
                  borderRadius: "4px",
                  border: "none",
                  backgroundColor: "#EFF0F1",
                  fontSize: "16px",
                  fontWeight: "normal",
                  paddingLeft: "10px",
                }}
              >
                <option value="" disabled selected>
                  Select Program...
                </option>
                {template &&
                  template.map((data) => {
                    return (
                      <option key={data._id} value={data._id}>
                        {data.schemaName}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div>
              <button
                onClick={openAddprogramModel}
                className="startProgramCreation"
              >
                Start Program Creation
              </button>
            </div>
          </div>
        </div>

        <div>
          <h5 className="addppglisttext">Program List</h5>
          <div>
            <table className="table">
              <thead style={{ border: "0.5px solid #3c4852" }}>
                <tr className="add_program_table">
                  <th scope="col">Program Title</th>
                  <th scope="col">Template</th>
                  <th scope="col">Status</th>
                  <th style={{ width: "150px" }} scope="col">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {templateSchema &&
                  templateSchema.map((data, idx) => {
                    return (
                      <tr className="addpgtabletr" key={idx}>
                        <th>
                          {(data.finalOutputJson &&
                            data.finalOutputJson.programName) ||
                            "--"}
                        </th>
                        <th>{data.schemaName || "--"}</th>
                        <th>{finalStatus(data.final_status)}</th>
                        <th>
                          <div className="addpg2btncon">
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateTemplate(data._id)}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/opspanel/action.svg"
                                height={20}
                                width={20}
                              />
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => delteTemplatePage(data._id)}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/opspanel/delete.svg"
                                height={24}
                                width={24}
                              />
                            </div>
                          </div>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddProgram;
