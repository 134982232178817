import React, { useState } from "react";
import Loader from "../loader/loading";
import { addSalesGroup } from "../../services/leaddistribution";

function AddSalesGroup(props) {
  const [group, setGroup] = useState("");
  const [businessHead, setBusinessHead] = useState("");
  const [loading, setLoading] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(group, businessHead);
    setLoading(true);
    let d = {
      email: businessHead,
      salesGroupName: group,
    };

    let response = await addSalesGroup(d);
    if (response.status === 200) {
      alert(response.data.data);
    } else if (response.status === 420) {
      alert(response.data.message);
    } else alert("Something went wrong");
    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <form onSubmit={submitHandler}>
          <div className="addprogram2container">
            <div>
              <h3 className="addprogramheading">
                Enter Group Name (20 characters limit max)
              </h3>
              <div>
                <input
                  value={group}
                  className="addprograminput"
                  placeholder="abc"
                  type="text"
                  id="target"
                  required
                  onChange={(e) => setGroup(e.target.value)}
                />
              </div>
            </div>

            <div>
              <h3 className="addprogramheading">Add Business Head</h3>
              <div>
                <input
                  value={businessHead}
                  className="addprograminput"
                  placeholder="abc@example.com"
                  type="text"
                  id="target"
                  required
                  onChange={(e) => setBusinessHead(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="addprogrambtns">
            <button onClick={props.close} className="addprogramcancelbtn">
              Cancel
            </button>
            <button
              onClick={() => submitHandler}
              className="addprogramsubmitbtn"
            >
              Done
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddSalesGroup;
