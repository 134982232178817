import React, { useState } from "react";
import Loader from "../loader/loading";
import { getLeadByPhone } from "../../services/leaddistribution";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";

function FindLeadByPhone(props) {
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState();
  const [leadData, setLeadData] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(phone);
    let data = await getLeadByPhone(phone);
    console.log(data);

    console.log(Object.keys(data.data).length, "length");

    if (!data.success) {
      alert("Unale to find the lead ");
      setLoading(false);
    } else if (data.success && Object.keys(data.data).length <= 0) {
      alert("Lead is not present in our database");
      setLoading(false);
    } else {
      setLeadData(data.data);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="addprogram2container">
          <div>
            <h3 className="addprogramheading">Enter Phone Number</h3>
            <div>
              <input
                value={phone}
                className="addprograminput"
                placeholder="0123456789"
                type="number"
                id="number"
                required
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
          </div>

          {leadData && Object.keys(leadData).length >= 0 && (
            <div style={{ width: "56vw", height: "450px" }}>
              {/* {leadData} */}
              <JSONInput
                id="a_unique_id"
                placeholder={leadData}
                width={"56vw"}
                locale={locale}
                viewOnly={true}
                height="450px"
              />
            </div>
          )}
        </div>

        <div className="addprogrambtns">
          <button onClick={props.close} className="addprogramcancelbtn">
            Cancel
          </button>
          <button
            onClick={(e) => submitHandler(e)}
            className="addprogramsubmitbtn"
          >
            Find
          </button>
        </div>
      </div>
    </>
  );
}

export default FindLeadByPhone;
