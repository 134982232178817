import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import Loader from "./loader/loading";
import { editAuxpage } from "../services/seoapproval";
import { getAuxapprovalspecificschema } from "../services/seoapproval";
import { getOtprequest } from "../services/approvalapi";

function Editauxpage() {
  const [loading, setLoading] = useState(false);
  const [editdata, setEditdata] = useState({});

  let { id } = useParams();
  let something = useLocation();
  const onchnage = function (e) {
    let body = { ...editdata };
    body[e.target.name] = e.target.value;

    setEditdata(body);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let body = { ...editdata };
      const data = await editAuxpage(id, body);

      console.log(data);

      sahil().then((something) => {
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      async function sahil() {
        return await getAuxapprovalspecificschema(data.data.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Enter Field is less then the required Validation");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit">
        <div className="main_container_divider">
          <div className="main_container_divid">
            <div className="change_content">
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#097252",
                }}
              >
                CHANGE CONTENT
              </p>
            </div>
            <form onSubmit={submitHandler}>
              <div>
                {something.state.checkbox
                  .filter((data, index) => index != 0)
                  .map((data) => {
                    return (
                      <div className="enquire_box_space">
                        <div className="form-inpt-contnr">
                          <p className="enquireFormplace">{data.name}</p>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <h1
                              style={{
                                fontSize: "18px",
                                opacity: ".75",
                                textWrap: "nowrap",
                              }}
                            >
                              PREVIOUS CONTENT
                            </h1>
                            &nbsp;&nbsp;&nbsp;
                            <p className="enquireFormplace2">{data.value}</p>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "60px",
                            }}
                          >
                            <h1
                              style={{
                                fontSize: "18px",
                                opacity: ".75",
                                textWrap: "nowrap",
                              }}
                            >
                              NEW CONTENT
                            </h1>
                            <input
                              defaultValue={data.value}
                              value={editdata[data.name]}
                              onChange={onchnage}
                              type="text"
                              className="form-inpt enquireFormInputedit"
                              name={data.name}
                              required
                              maxLength={10000}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="request_approval">
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                >
                  Request Approval
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editauxpage;
