import React, { useEffect, useState } from "react";
import Loader from "./loader/loading";
import "../Components/css/ManageProgram.css";
import { getProgramTags } from "../services/marketinggetapi";
import { sendFormData } from "../services/marketingapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { TagPicker } from "rsuite";
import "rsuite/dist/rsuite.min.css"; // Ensure you have the CSS for rsuite

function ManageProgram() {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedTag, setSelectedTag] = useState([]);
  const [tags, setTags] = useState([]);
  const [cacheData, setCacheData] = React.useState([]);
  const handleSelect = (value, item, event) => {
    // console.log(value, "value", item, "item", event, "event");
    setCacheData([...cacheData, item]);
  };

  useEffect(() => {
    getTags().then((something) => {
      console.log({ something });
      setTags(something.data);
      setLoading(false);
      console.log(something.data);
    });
    async function getTags() {
      setLoading(true);
      return await getProgramTags();
    }
  }, []);

  const [value, setValue] = useState([]);

  const submitHandler = async () => {
    setLoading(true);
    // return;
    // alert("inside else block.")
    console.log({
      ...selectedTag,
      subDomainUnderThisLabel: value,
    });
    const data = await sendFormData({
      ...selectedTag,
      subDomainUnderThisLabel: value,
    }).catch((err) => {
      if (err) {
        alert(err);
        return;
      }
    });

    setLoading(false);
    getApprovalAndGetOtps().then((values) => {
      // console.log(values,"this is my data");
      for (let val in values) {
        getOtprequest(
          {
            Fieldchange: values[val].data.data.field_name,
            Newdata: values[val].data.data.new_schema,
            Olddata: values[val].data.data.old_schema,
          },
          values[val].data.data._id
        );
      }
      // console.log(maildata, ":::::::::::::::;;maildata");
      setLoading(false);
      alert("Successfully Added");
      window.open("/Approvalwindow", "_self");
    });
    // (maildata)

    async function getApprovalAndGetOtps() {
      const mainData = data.data.data;
      // console.log({mainData});
      const dataForOtps = [];
      for (let index in mainData) {
        const response = await getApprovalspecificschema(mainData[index]._id);
        dataForOtps.push(response);
      }
      return dataForOtps;
    }
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {modal && (
            <form onSubmit={submitHandler}>
              <div className="modalDarkBg">
                <div className="formHolder">
                  <div onClick={() => setModal(false)} className="crossArrow">
                    <img
                      height="16px"
                      width="16px"
                      src="https://webcdn.imarticus.org/IL3/Group1171280043.svg"
                    />
                  </div>
                  <h4 style={{ borderBottom: "0.5px solid rgba(0,0,0,0.1)" }}>
                    {selectedTag.label}
                  </h4>
                  <h6 style={{ marginTop: "8px" }}>
                    Set domain type to main-domain
                  </h6>
                  <div className="radioButtonHolder_program_tag">
                    <div>
                      <input
                        type="radio"
                        checked={selectedTag.isMainDomain ? true : false}
                        name="isDomain"
                        onChange={(e) =>
                          setSelectedTag({
                            ...selectedTag,
                            isMainDomain: true,
                          })
                        }
                      />
                      <span>Yes</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        checked={!selectedTag.isMainDomain ? true : false}
                        onChange={(e) =>
                          setSelectedTag({
                            ...selectedTag,
                            isMainDomain: false,
                          })
                        }
                        name="isDomain"
                      />
                      <span>No</span>
                    </div>
                  </div>
                  <h6 style={{ marginTop: "16px" }}>Set Position Of Domain</h6>
                  <div className="inputField_program_tag">
                    <input
                      type="number"
                      value={selectedTag.position}
                      name="position"
                      onChange={(e) =>
                        setSelectedTag({
                          ...selectedTag,
                          position: e.target.value,
                        })
                      }
                    />
                  </div>
                  <h6 style={{ marginTop: "16px" }}>
                    Add or remove tags under this label
                  </h6>
                  {/* {JSON.stringify(tags)} */}
                  <div style={{ width: "100%" }}>
                    <TagPicker
                      cacheData={cacheData}
                      value={value}
                      onChange={setValue}
                      onSelect={handleSelect}
                      style={{ width: 500 }}
                      placeholder="Select Tags"
                      data={tags}
                    />
                    <button className="submit_button">Submit</button>
                  </div>
                </div>
              </div>
            </form>
          )}
          <div
            className="container-fluid"
            style={{ overflowX: "hidden", paddingBottom: "30px" }}
          >
            <div className="row" style={{ height: "100%" }}>
              <div className="home_container">
                <div className="home_title_div">
                  <h1 className="home_title">Manage Program Tags</h1>
                </div>
                <div>
                  <TableContainer className="mainTable">
                    <Table aria-label="custom pagination table">
                      <TableHead>
                        <TableRow className="leadtablefont">
                          <TableCell>label</TableCell>
                          <TableCell>value</TableCell>
                          <TableCell>isMainDomain</TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell component="th" scope="row">
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton
                                variant="rectangular"
                                width={100}
                                height={30}
                              />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {tags.map((data, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell component="th" scope="row">
                                    {data.label}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.value}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    {data.isMainDomain ? "yes" : "no"}
                                  </TableCell>
                                  <TableCell component="th" scope="row">
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                      height="16px"
                                      width="16px"
                                      onClick={() => {
                                        setSelectedTag(data);
                                        setValue(data.subDomainUnderThisLabel);
                                        setModal(true);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ManageProgram;
