import React, { useEffect, useState } from "react";
import Form from "react-jsonschema-form";
import { useNavigate, useParams } from "react-router-dom";
import "./css/webinar.css";

function ViewProgramContent(programData) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [uiSchemaa, setUiSchema] = useState();
  const [formdataSchema, setFormDataSchema] = useState();
  const [edituischema, setEdituischema] = useState();
  const [propsdata, setPropsdata] = useState("");
  let navigate = useNavigate();
  let id = programData.data._id;
  useEffect(() => {
    setPropsdata(programData.data.programSchema);
  }, [programData]);

  useEffect(() => {
    let jsondata = propsdata || "";
    for (let i in jsondata) {
      for (let j in jsondata[i]) {
        if (
          typeof jsondata[i][j] == "object" ||
          typeof jsondata[i][j] == "array" ||
          typeof jsondata[i][j] == "boolean"
        ) {
          console.log(typeof jsondata[i][j]);
          delete jsondata[i][j];
        }
      }
      for (let j in jsondata[i]) {
        let data1 = jsondata[i][j];
        delete jsondata[i][j];
        jsondata[i][j] = { data1: data1 };
        console.log("inside 2 for loop");
        console.log(jsondata[i][j]);
      }
    }

    let final_data = {};

    Object.keys(jsondata).forEach((dt) => {
      Object.keys(jsondata[dt]).forEach((dt1) => {
        final_data[`${dt}-${dt1}`] = jsondata[dt][dt1];
      });
    });

    console.log({ final_data });
    setData(final_data);

    var uiSchema = {};
    var formdatajson = {
      type: "object",
      properties: {},
    };
    var edituischemaa = {};
    let dbeditablekeys = [];
    for (var i in final_data) {
      dbeditablekeys.push(i);
    }

    console.log({ dbeditablekeys });

    for (var i = 0; i < dbeditablekeys.length; i++) {
      var targetKey = dbeditablekeys[i];
      uiSchema[targetKey] = {
        classNames: "webinar-subheading-main-location",
        checkbox: { classNames: "webinar-subheading-location" },
        data1: {
          "ui:disabled": true,
          classNames: "webinar-subheading-location1",
        },
        data: {
          classNames: "webinar-subheading-location1",
          "ui:widget": "hidden",
        },
      };

      setUiSchema(uiSchema);

      if (!targetKey.includes("image")) {
        formdatajson.properties[targetKey] = {
          type: "object",
          title: `${targetKey} `,
          properties: {
            checkbox: {
              type: "boolean",
            },
            data1: {
              type: "string",
              title: "Previous Content",
            },
            data: {
              type: "string",
              title: "New Content",
            },
          },
        };
      } else {
        formdatajson.properties[targetKey] = {
          type: "object",
          title: `${targetKey} `,
          properties: {
            checkbox: {
              type: "boolean",
            },
            data1: {
              type: "string",
              title: "Previous Content",
            },
            data: {
              type: "string",
              format: "data-url",
              title: "New Image",
            },
          },
        };
      }

      setFormDataSchema(formdatajson);

      if (!targetKey.includes("image")) {
        edituischemaa[targetKey] = {
          classNames: "webinar-subheading-main",
          checkbox: { classNames: "webinar-subheading", "ui:widget": "hidden" },
          data1: { "ui:disabled": true },
          data: {
            classNames: "webinar-subheading-description",
            "ui:description": "Maximum 30 Characters Allowed",
          },
        };
      } else {
        edituischemaa[targetKey] = {
          classNames: "webinar-subheading-main",
          checkbox: { classNames: "webinar-subheading", "ui:widget": "hidden" },
          data1: {
            "ui:disabled": true,
          },
          data: {
            classNames: "webinar-ImageTab",
            "ui:description": "Image resolution should be 585*362px",
            "ui:options": {
              "data-max-size": 16,
            },
          },
        };
      }
      setEdituischema(edituischemaa);
    }

    setLoading(false);
  }, [propsdata]);

  const handleclick = (e) => {
    navigate(`/ProgramlocationEdit${id}`, {
      state: {
        state: e.formData,
        edituischema: edituischema,
        formDataSchema: formdataSchema,
      },
    });
  };

  console.log({ id });

  return (
    <div>
      {formdataSchema && uiSchemaa && data && (
        <Form
          schema={formdataSchema}
          uiSchema={uiSchemaa}
          formData={data}
          onSubmit={(e) => {
            handleclick(e);
          }}
        >
          <div>
            <button type="submit" className="button">
              <p>Edit Fields</p>
            </button>
          </div>
        </Form>
      )}
    </div>
  );
}

export default ViewProgramContent;
