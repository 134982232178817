import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function getProgramTemplate() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/getProgramTemplate`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getProgramTemplateSchema() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/getProgramTemplateSchema`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function createTemplatePage(data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  return axios.post(
    `${configs.MARKETING_ENDPOINT}/marketingopspanel/createTemplatePage`,
    { ...data, aid: aid },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": cookies.get("at", {
          domain: configs.DOMAIN,
          path: "/",
        }),
      },
    }
  );
}

export async function getTemplate(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_template${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export async function updatePageTemplate(id, data) {
  const cookies = new Cookies();
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/update_pg_template${id}`,
      { ...data },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error;
  }
}

export async function approvePageTemplate(id, data) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/template_pg_approval${id}`,
      { ...data, aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error;
  }
}

export async function deleteTemplatePage(id) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/delete_template_pg${id}`,
      { aid: aid },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error;
  }
}

export async function previewTemplatePage(url, templateName) {
  const cookies = new Cookies();
  try {
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/preview_template_page`,
      { url: url, templateName: templateName },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    return error;
  }
}
