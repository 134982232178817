import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import {
  // getDeployment,
  // postDeployment,
  // getDeploymentStatus,
  deleteDeployment,
  getProductionDeployment,
  checkProductionDeployment,
  getProductionDeploymentStatus,
  getMailAccessUser,
} from "../services/deploymentapi";
import Pagination from "./Pagination";
import "./css/home.css";
import "./css/approval.css";

import Loader from "./loader/loading";
import { Deploymentrender, ProgramName } from "../services/helper";
import moment from "moment";
import Pagination2 from "./Pagination2";
import ProdConfirmationModel from "./ProdDeplomentConfirmationModel";
import Position from "rsuite/esm/Overlay/Position";
// import ProdConfirmationModel from "./Model"
import ProdOtpModel from "./ProdDeployOtpModel";
import { getProdDeploymentOtprequest } from "../services/approvalapi";

const PER_PAGE = 10;

function ProductionDeployment() {
  const [propsdata2, setPropsdata2] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmationModel, setShowConfirmationModel] = useState(false);
  const [showOtpModel, setShowOtpModel] = useState(false);
  const [currentDeploymentId, setCurrentDeploymentId] = useState("");
  const [mailUser, setMailUser] = useState([]);
  const [mailToSentTo, setMailToSentTo] = useState("");
  const [maildata, setMaildata] = useState({
    Fieldchange: "",
    Newdata: "",
    Olddata: "",
  });

  useEffect(() => {
    deployment().then((ddata) => {
      setPropsdata2(ddata.data.data.sendData);
      // console.log(something, "::::::::::ksahfkj");
    });
    async function deployment() {
      setLoading(true);
      return await getProductionDeployment();
    }

    deploymentlist().then((dstaus) => {
      setData(dstaus.data.data);
      setSize(dstaus.data.size);
      setLoading(false);
    });
    async function deploymentlist() {
      setLoading(true);
      return await getProductionDeploymentStatus(currentPage, PER_PAGE);
    }

    mailAccessUser().then((resp) => {
      // PRODUCTION_DEPLOYMENT_APPROVAL_ACCESS_USERS
      setMailUser(resp.data.data);
      setLoading(false);
    });

    async function mailAccessUser() {
      setLoading(true);
      return await getMailAccessUser();
    }
  }, []);

  useEffect(() => {
    if (currentDeploymentId) {
      setShowOtpModel(true);
    }
  }, [currentDeploymentId]);

  const handleConfirmationModelClose = () => setShowConfirmationModel(false);
  const handleConfirmationModelSuccess = async () => {
    try {
      if (mailToSentTo == "") {
        alert("Please select a user for verification first");
        return;
      }

      setShowConfirmationModel(false);
      setLoading(true);
      let deploymentstatus = await checkProductionDeployment();

      if (!deploymentstatus.data.data.status) {
        alert(deploymentstatus.data.data.msg);
        window.location.reload();
      } else {
        setMaildata({
          approval_name: deploymentstatus.data.data.approval_name,
          Fieldchange: "",
          Newdata: "",
          Olddata: "",
        });
        return getProdDeploymentOtprequest(
          {
            approval_name: deploymentstatus.data.data.approval_name,
            Fieldchange: "",
            Newdata: "",
            Olddata: "",
          },
          deploymentstatus.data.data.ids,
          mailToSentTo
        ).then(() => {
          setLoading(false);
          setCurrentDeploymentId(deploymentstatus.data.data.ids);
          // setShowOtpModel(true);
        });
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleOtpModelClose = () => setShowOtpModel(false);

  const [currentPage, setCurrentPAge] = useState(0);
  const [size, setSize] = useState(0);
  const [data, setData] = useState([]);

  function handleClick({ selected: selectedPage }) {
    // console.log("selectedpage", selectedPage);
    setCurrentPAge(selectedPage);
    deploymentlist().then((dstaus) => {
      setData(dstaus.data.data);
      setLoading(false);
    });
    async function deploymentlist() {
      setLoading(true);
      return await getProductionDeploymentStatus(selectedPage, PER_PAGE);
    }
  }

  const offset = currentPage * PER_PAGE;
  const pageCount = Math.ceil(size / PER_PAGE);

  const checkBeforStart = async () => {
    setShowConfirmationModel(true);
  };

  let deploymentrender = {
    1: "Not Started",
    2: "Running",
    3: "Failed",
    4: "Success",
  };

  return (
    <>
      {loading && <Loader />}
      {/* <div className="main_container_edit"> */}

      {showConfirmationModel && (
        <ProdConfirmationModel
          handleClose={handleConfirmationModelClose}
          handleSuccess={handleConfirmationModelSuccess}
          showConfirmationModel={showConfirmationModel}
          setShowConfirmationModel={setShowConfirmationModel}
          mailUser={mailUser}
          setMailToSentTo={setMailToSentTo}
          mailToSentTo={mailToSentTo}
        />
      )}

      {showOtpModel && (
        <ProdOtpModel
          setLoading={setLoading}
          getOtprequest={getProdDeploymentOtprequest}
          maildata={maildata}
          show={showOtpModel}
          handleClose={handleOtpModelClose}
          approvalId={currentDeploymentId}
          id={currentDeploymentId}
          mailToSentTo={mailToSentTo}
        />
      )}

      <div className="home_container">
        <div className="home_title_div">
          <h1 className="home_title">
            Approvals Pending Production Deployment
          </h1>
        </div>
        <div className="home_table">
          <table className="table table-striped">
            <thead>
              <tr className="uper_table_container">
                <th scope="col" style={{ width: "10%" }}>
                  Date/Time
                </th>
                <th scope="col" style={{ width: "20%", paddingRight: "10px" }}>
                  Program
                </th>
                <th scope="col">Schema Name</th>
                <th scope="col">Production Deployment Status</th>
              </tr>
            </thead>
            <tbody>
              {propsdata2 &&
                propsdata2.map((data, idx) => {
                  return (
                    <tr className="uper_table_container" key={idx}>
                      <th>
                        {moment(data.updatedAt).format("DD/MM/YYYY, h:mm:ss a")}
                      </th>
                      <th scope="row" style={{ paddingRight: "20px" }}>
                        {ProgramName(data)}
                      </th>
                      <th>{data.field_name.join(",") || "---"}</th>

                      <th>{Deploymentrender(data.final_status)}</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="deployment_btn_div">
          <button className="deployment_btn" onClick={checkBeforStart}>
            Start Production Deployment
          </button>
        </div>
      </div>

      <div className="home_container">
        <div className="home_title_div">
          <h1 className="home_title">Production Deployment Status</h1>
        </div>
        <div className="home_table">
          <table className="table table-striped">
            <thead>
              <tr className="uper_table_container">
                <th scope="col">Date/Time</th>
                <th scope="col" style={{ width: "10%", paddingRight: "10px" }}>
                  Deployment ID
                </th>
                <th scope="col">Environment</th>
                <th scope="col">Number of Request</th>
                <th scope="col">Production Deployment Status</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((data, idx) => {
                  return (
                    <tr className="uper_table_container" key={idx}>
                      <th>
                        {moment(data.createdAt).format("DD/MM/YYYY, h:mm:ss a")}
                      </th>
                      <th scope="row" style={{ paddingRight: "5px" }}>
                        {data._id}
                      </th>
                      <th>{data.env_type}</th>
                      <th>{data.linked_approval_ids.length}</th>
                      <th>
                        <Link
                          style={{ textDecoration: "none", color: "#3c4852" }}
                          to={`/Deploymentspecific${data._id}`}
                        >
                          {deploymentrender[data.deployment_status]}
                        </Link>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination2 handleClick={handleClick} pageCount={pageCount} />
      </div>
      {/* </div> */}
    </>
  );
}

export default ProductionDeployment;
