import React, { useEffect, useState } from "react";
import Loader from "./loader/loading";
import {
  getFbFormMapper,
  saveFbFormMapper,
} from "../services/leaddistribution";
function FbFormMapper(props) {
  const [loading, setLoading] = useState(false);
  const [fbFormKeysValues, setFbFormKeysValues] = useState({});
  const [edit, setEdit] = useState({
    key: "",
    editable: false,
  });

  const [editData, setEditData] = useState("");

  useEffect(() => {
    getData().then((data) => {
      setFbFormKeysValues(data.data);
      setLoading(false);
    });
    async function getData() {
      setLoading(true);
      return await getFbFormMapper();
    }
  }, []);

  console.log({ edit });

  const handleSubmit = async (e, key) => {
    e.preventDefault();
    try {
      console.log({ key });
      console.log({ editData });
      setLoading(true);
      let data = [...fbFormKeysValues.value[key]];
      console.log({ data });
      if (editData) {
        data.push(editData);
      }
      let editedData = {
        ...fbFormKeysValues.value,
        [key]: data,
      };
      console.log(editedData);
      let finalData = { data: editedData };
      const response = await saveFbFormMapper(finalData);
      if (response.status == 200) {
        alert("Successfully Added");
        window.location.reload();
        setLoading(false);
      } else {
        alert("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">FB Form Mapper</h1>
        </div>

        <div>
          <div className="groupmaintablediv">
            <div className="" style={{ overflowX: "scroll", width: "100%" }}>
              <div style={{ width: "800px" }}>
                <table className="table table-striped groupleadtable">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        style={{
                          width: "254px",
                          height: "48px",
                          textAlign: "center",
                        }}
                      >
                        Form Key
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "254px",
                          height: "48px",
                          textAlign: "center",
                        }}
                      >
                        Form Value Fields
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "254px",
                          height: "48px",
                          textAlign: "center",
                        }}
                      >
                        Edit
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fbFormKeysValues &&
                      fbFormKeysValues.value &&
                      Object.entries(fbFormKeysValues.value).map(
                        ([key, value], idx) => {
                          return (
                            <tr className="" key={idx}>
                              <th
                                scope="row"
                                style={{
                                  borderRight:
                                    "1px solid rgba(60, 72, 82, 0.25)",
                                  textAlign: "center",
                                }}
                              >
                                {key}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  borderRight:
                                    "1px solid rgba(60, 72, 82, 0.25)",
                                  textAlign: "center",
                                }}
                              >
                                {value &&
                                  value.map((d, i) => {
                                    return <p key={i}>{d}</p>;
                                  })}

                                {key == edit.key && edit.editable == true ? (
                                  <>
                                    {" "}
                                    <input
                                      value={editData}
                                      required
                                      onChange={(e) =>
                                        setEditData(e.target.value)
                                      }
                                      type="text"
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  borderRight:
                                    "1px solid rgba(60, 72, 82, 0.25)",
                                  textAlign: "center",
                                }}
                              >
                                {key == edit.key && edit.editable == true ? (
                                  <button onClick={(e) => handleSubmit(e, key)}>
                                    Save
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      setEdit({ key: key, editable: true })
                                    }
                                  >
                                    Edit
                                  </button>
                                )}
                              </th>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FbFormMapper;
