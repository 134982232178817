import React, { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { createUtm } from "../services/utmapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";
function CreateUtms() {
  const [loading, setLoading] = useState(false);
  const [callcenter, setCallCenter] = useState(false);
  const [otprecaptcha, setOtpRecaptcha] = useState(false);
  const [campaigntype, setCampaigntype] = useState("");
  const [utmMedium, setMedium] = useState("");
  const [utmSource, setSource] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaign, setCampaign] = useState("");
  const [utmPlacement, setUtmPlacement] = useState("");
  const [addName, setAddName] = useState("");
  const [addGroup, setAddGroup] = useState("");
  const [utmTerm, setUtmTerm] = useState("");
  const [utmAddContet, setUtmAddContent] = useState("");
  const [copyText, setcopyText] = useState("");
  const [copyImage, setcopyImage] = useState(
    "https://cdn.pegasus.imarticus.org/b2b/Copy.svg"
  );
  const [isCopied, setIsCopied] = useState(false);
  const [preview, setPreview] = useState(false);
  const [otpCallCenterCheck, setOTPCallCenterCheck] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(copyText);
    setcopyImage("https://cdn.pegasus.imarticus.org/b2b/Tick.svg");
    setIsCopied(true);
  };

  useEffect(() => {
    let timerid;
    timerid = setTimeout(() => {
      setcopyImage("https://cdn.pegasus.imarticus.org/b2b/Copy.svg");
      setIsCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timerid);
    };
  }, [isCopied]);

  const callCenterCheck = (e) => {
    setCallCenter(e);
    setOtpRecaptcha(e);
  };

  const otpRecaptchaValidation = (e) => {
    if (callcenter === true) {
      return;
    } else {
      setOtpRecaptcha(e);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await createUtm({
        utmCampaignType: campaigntype.trim(),
        utmMedium: utmMedium.trim(),
        utmSource: utmSource.trim(),
        leadSource: leadSource.trim(),
        utmCampaignName: campaignName.trim(),
        utmCampaign: campaign.trim(),
        utmPlacement: utmPlacement.trim(),
        utmAddName: addName.trim(),
        utmAddGroup: addGroup.trim(),
        utmTerm: utmTerm.trim(),
        utmAddContent: utmAddContet.trim(),
        callCenterLead: callcenter,
        otpRecaptchaValidation: otprecaptcha,
      });
      if (!data) {
        alert("Please fill all the details");
        setLoading(false);
        return;
      }
      mailData().then((something) => {
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        ).then(() => {
          alert("Successfully Added");
          setLoading(false);
          window.open("/Approvalwindow", "_self");
        });
      });
      async function mailData() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    let string = "";
    let s1 = campaigntype
      ? "utm_campaigntype=" + campaigntype.trim() + "&"
      : "";
    let s2 = utmMedium ? "utm_medium=" + utmMedium.trim() + "&" : "";
    let s3 = utmSource ? "utm_source=" + utmSource.trim() + "&" : "";
    let s4 = campaignName
      ? "utm_campaignname=" + campaignName.trim() + "&"
      : "";
    let s5 = campaign ? "utm_campaign=" + campaign.trim() + "&" : "";
    let s6 = utmPlacement ? "utm_placement=" + utmPlacement.trim() + "&" : "";
    let s7 = addName ? "utm_addname=" + addName.trim() + "&" : "";
    let s8 = addGroup ? "utm_addgroup=" + addGroup.trim() + "&" : "";
    let s9 = utmTerm ? "utm_term=" + utmTerm.trim() + "&" : "";
    let s10 = utmAddContet ? "utm_addcontet=" + utmAddContet.trim() + "&" : "";
    string = "?" + s1 + s2 + s3 + s4 + s5 + s6 + s7 + s8 + s9 + s10;
    string = string.substring(0, string.length - 1);
    setcopyText(string);
    if (string.length >= 2) {
      setPreview(true);
    }
  }, [
    campaigntype,
    utmMedium,
    utmSource,
    campaignName,
    campaign,
    utmPlacement,
    addName,
    addGroup,
    utmTerm,
    utmAddContet,
  ]);

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Create UTM</h1>
        </div>

        <div className="createUtmmaindiv">
          <div className="createUtmbottomhalf">
            <form onSubmit={submitHandler}>
              <div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Campaign Type{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    required
                    style={{ textTransform: "lowercase" }}
                    type="text"
                    value={campaigntype}
                    onChange={(e) => setCampaigntype(e.target.value)}
                    name="campaigntype"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Medium{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    required
                    style={{ textTransform: "uppercase" }}
                    type="text"
                    value={utmMedium}
                    onChange={(e) => setMedium(e.target.value)}
                    name="utmMedium"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter Lead Source{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    required
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                    name="leadSource"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Source{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    required
                    className="create_auxinput"
                    type="text"
                    style={{ textTransform: "lowercase" }}
                    value={utmSource}
                    onChange={(e) => setSource(e.target.value)}
                    name="utmSource"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Campaign Name
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    name="campaignName"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>Enter UTM Campaign</h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={campaign}
                    onChange={(e) => setCampaign(e.target.value)}
                    name="campaign"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>Enter UTM Placement</h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={utmPlacement}
                    onChange={(e) => setUtmPlacement(e.target.value)}
                    name="utmPlacement"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>Enter Ad Name</h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={addName}
                    onChange={(e) => setAddName(e.target.value)}
                    name="addName"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>Enter UTM Ad Group</h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={addGroup}
                    onChange={(e) => setAddGroup(e.target.value)}
                    name="addGroup"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Ad Content
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={utmAddContet}
                    onChange={(e) => setUtmAddContent(e.target.value)}
                    name="utmAddContet"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>Enter UTM Term</h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    type="text"
                    value={utmTerm}
                    onChange={(e) => setUtmTerm(e.target.value)}
                    name="utmTerm"
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "15px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="callcenter"
                    name="callcenter"
                    onChange={(e) => callCenterCheck(e.target.checked)}
                  />
                  <h6 style={{ paddingBottom: "10px" }}>
                    Call Center Lead{" "}
                    <span className="createUtmIbutton1">
                      (i){" "}
                      <span className="createUtmibtcallcenter">
                        If this option is slected non otp submitted leads will
                        not go to leadsquare
                      </span>
                    </span>
                  </h6>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "15px",
                  }}
                >
                  <input
                    type="checkbox"
                    id="otprecaptcha"
                    name="otprecaptcha"
                    checked={otprecaptcha}
                    onChange={(e) => otpRecaptchaValidation(e.target.checked)}
                  />
                  <h6 style={{ paddingBottom: "10px" }}>
                    OTP Recaptcha Validation{" "}
                    <span className="createUtmIbutton">
                      (i){" "}
                      <span className="createUtmibtnotp">
                        If this option is selected user will receive Recaptcha
                        and OTP validation while submitting lead
                      </span>{" "}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="request_approval" style={{ display: "flex" }}>
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                  style={{ marginLeft: "20px" }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="createUtmbottomhalf2">
            {preview ? (
              <div
                className="utmpreviewstick"
                style={{ display: `${preview}` }}
              >
                <div style={{ position: "sticky" }}>
                  <div className="program_container_box_copy">
                    <div style={{ width: "95%" }}>
                      <h1>{copyText || "--"}</h1>
                    </div>
                    <div onClick={handleCopy} className="copybtndivutmdetail">
                      <img src={copyImage} width={20} height={20} />
                      {/* <p>Copied</p> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateUtms;
