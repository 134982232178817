import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export default async function WebinarJsonForm(name) {
  console.log("====================================");
  console.log(name);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/webinar_form_json?name=${name}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error, "error from edit api");
    return Promise.reject(error);
  }
}
