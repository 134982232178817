import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loader from "./loader/loading";
import { editCourseApi, PlacementReportApi } from "../services/editCourseapi";
import { getApprovalspecificschema } from "../services/approvalapi";
import { getOtprequest } from "../services/approvalapi";
import { uploadImages, editNewsApi } from "../services/newsapi";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
const label = { inputProps: { "aria-label": "Switch demo" } };

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));

function Editnews() {
  const [loading, setLoading] = useState(false);
  const [editdata, setEditdata] = useState({});
  const [bannerimg, setbannerimg] = useState();
  const [logoimg, setlogoimg] = useState();
  const [checked, setChecked] = useState(true);

  let { id } = useParams();
  let lastcheckdata = useLocation();
  useEffect(() => {}, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let body = { ...editdata };
      for (let i = 0; i < Object.keys(body).length; i++) {
        // console.log("1")
        if (body[Object.keys(body)[i]]) {
          console.log(typeof body[Object.keys(body)[i]], "trim function");
          if (typeof body[Object.keys(body)[i]] == "string") {
            if (body[Object.keys(body)[i]].trim() === "") {
              setLoading(false);
              return alert("Empty Field Found");
            }
          } else if (body[Object.keys(body)[i]].length == 0) {
            setLoading(false);
            return alert("Empty Field Found");
          }
        }
      }
      if (!Object.keys(body).length) {
        alert("Please fill the data");
        setLoading(false);
        return;
      }

      console.log("body:::::::::", body, ":::::::body");
      const data = await editNewsApi(id, body);
      console.log("====================================");
      console.log(data.data.data, "from edit api");
      console.log("====================================");
      sahil().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        // console.log(maildata, ":::::::::::::::;;maildata");
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      // (maildata)
      async function sahil() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Enter Field is less then the required Validation");
    }
  };

  const onchnage = function (e) {
    let body = { ...editdata };
    body[e.target.name] = e.target.value;

    setEditdata(body);
  };

  const buttonClick = async (data) => {
    const formdata = new FormData();
    formdata.append("file", data);
    setLoading(true);
    let body = { ...editdata };
    const res = await uploadImages(id, formdata);
    if (res.success === true) {
      setbannerimg(res.data);
      body["bannerimg"] = res.data;
      setEditdata(body);
      alert("Image Uploaded ");
      setLoading(false);
    } else {
      alert("Image Size Exceeded, It should be under 80KB");
      setLoading(false);
    }
  };

  const buttonClick2 = async (data) => {
    const formdata = new FormData();
    formdata.append("file", data);
    setLoading(true);
    let body = { ...editdata };
    const res = await uploadImages(id, formdata);
    if (res.success === true) {
      setlogoimg(res.data);
      body["logo"] = res.data;
      setEditdata(body);
      alert("Image Uploaded ");
      setLoading(false);
    } else {
      alert("Image Size Exceeded, It should be under 80KB");
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let body = { ...editdata };
    body["act"] = event.target.checked;
    setEditdata(body);
  };

  console.log("====================================");
  console.log(checked, editdata);
  console.log("====================================");

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit">
        <div>
          <ul style={{ color: "#3C4852", fontSize: "14px" }}>
            <h6>Rules For Edit the News :</h6>
            <li>Max Word Limit for the description : 440 characters</li>
            <li>
              Recommendation Dimension of banner Image : 268*145px and Max-size
              : 80KB
            </li>
            <li>
              Recommendation Dimension of logo Image : 214*48px and Max-size :
              80KB
            </li>
          </ul>
        </div>
        <div className="main_container_divider">
          <div className="main_container_divid">
            <div className="change_content">
              <p
                tyle={{
                  fontSize: "20px",
                  fontWeight: "700",
                  color: "#097252",
                }}
              >
                {" "}
                CHANGE CONTENT
              </p>
            </div>
            <form onSubmit={submitHandler}>
              <div>
                {lastcheckdata.state.checkbox
                  .filter((data, index) => index != 0)
                  .map((data) => {
                    return (
                      <div className="enquire_box_space">
                        <div className="form-inpt-contnr">
                          <p className="enquireFormplace">{data.name}</p>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <h1
                              style={{
                                fontSize: "18px",
                                opacity: ".75",
                                whiteSpace: "nowrap",
                              }}
                            >
                              PREVIOUS CONTENT
                            </h1>
                            &nbsp;&nbsp;&nbsp;
                            {data.name === "bannerimg" ? (
                              data.value ? (
                                <a
                                  style={{ fontSize: "18px" }}
                                  href={data.value}
                                  target="_blank"
                                >
                                  {data.value}
                                </a>
                              ) : (
                                "---"
                              )
                            ) : data.name === "logo" ? (
                              data.value ? (
                                <a
                                  style={{ fontSize: "18px" }}
                                  href={data.value}
                                  target="_blank"
                                >
                                  {data.value}
                                </a>
                              ) : (
                                "---"
                              )
                            ) : data.name === "link" ? (
                              data.value ? (
                                <a
                                  style={{ fontSize: "18px" }}
                                  href={data.value}
                                  target="_blank"
                                >
                                  {data.value}
                                </a>
                              ) : (
                                "---"
                              )
                            ) : data.name === "act" ? (
                              data.value ? (
                                <p
                                  style={{ paddingLeft: "30px" }}
                                  className="enquireFormplace2"
                                >
                                  Active
                                </p>
                              ) : (
                                <p className="enquireFormplace2">Active</p>
                              )
                            ) : (
                              <p className="enquireFormplace2">{data.value}</p>
                            )}
                          </div>
                          {data.name === "bannerimg" ? (
                            <div>
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                  className="program_container_box"
                                >
                                  <h2>Upload News Banner Image</h2>
                                  <p style={{ color: "red" }}>
                                    Recommendation Dimension : 268*145, Max-size
                                    : 80KB
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      style={{ marginTop: "10px" }}
                                      type="file"
                                      accept="image/*"
                                      name="file"
                                      onChange={(e) => {
                                        buttonClick(e.target.files[0]);
                                      }}
                                    ></input>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h1
                                    style={{ fontSize: "18px", opacity: ".75" }}
                                  >
                                    NEW CONTENT
                                  </h1>

                                  {bannerimg ? (
                                    <a
                                      href={bannerimg}
                                      target="_blank"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {bannerimg}
                                    </a>
                                  ) : (
                                    "---"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : data.name === "logo" ? (
                            <div>
                              <div>
                                <div
                                  className="program_container_box"
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                >
                                  <h2>Upload News Logo</h2>
                                  <p style={{ color: "red" }}>
                                    Recommendation Dimension : 214*48, Max-size
                                    : 80KB
                                  </p>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <input
                                      style={{ marginTop: "10px" }}
                                      type="file"
                                      accept="image/*"
                                      name="file"
                                      onChange={(e) => {
                                        buttonClick2(e.target.files[0]);
                                      }}
                                    ></input>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <h1
                                    style={{ fontSize: "18px", opacity: ".75" }}
                                  >
                                    NEW CONTENT
                                  </h1>

                                  {logoimg ? (
                                    <a
                                      href={logoimg}
                                      target="_blank"
                                      style={{ marginLeft: "20px" }}
                                    >
                                      {logoimg}
                                    </a>
                                  ) : (
                                    "---"
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : data.name === "act" ? (
                            <>
                              <div>
                                <div>
                                  <div
                                    className="program_container_box"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2>Delete News</h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <GreenSwitch
                                        {...label}
                                        checked={checked}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "60px",
                              }}
                            >
                              <h1
                                style={{
                                  fontSize: "18px",
                                  opacity: ".75",
                                  textWrap: "nowrap",
                                }}
                              >
                                NEW CONTENT
                              </h1>
                              <input
                                defaultValue={data.value}
                                value={editdata[data.name]}
                                onChange={onchnage}
                                type="text"
                                className="form-inpt enquireFormInputedit"
                                name={data.name}
                                required
                                maxLength={500}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="request_approval">
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                >
                  Request Approval
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Editnews;
