import React, { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { Link, useNavigate } from "react-router-dom";
import { getWebinarEventsApi } from "../services/webinarapi";
import { grey } from "@mui/material/colors";

function Webinar() {
  const [loading, setLoading] = useState(false);
  const [webinarData, setWebinarData] = useState([]);
  const [activeWebinarData, setActiveWebinarData] = useState([]);
  const [inactiveWebinarData, setInactiveWebinarData] = useState([]);
  const [activeSection, setActiveSection] = useState("active");

  const navigate = useNavigate();

  useEffect(() => {
    getWebinars().then((something) => {
      setWebinarData(something.data.data);

      const activeWebinars = something.data.data.filter(
        (obj) => obj.domain.length > 0
      );
      const inactiveWebinars = something.data.data.filter(
        (obj) => obj.domain.length === 0
      );

      setActiveWebinarData(activeWebinars);
      setInactiveWebinarData(inactiveWebinars);

      setLoading(false);
    });

    async function getWebinars() {
      setLoading(true);
      return await getWebinarEventsApi();
    }
  }, []);

  const addWebinar = () => {
    navigate("/AddWebinar");
  };

  return (
    <>
      {loading && <Loader />}

      <div className="container-fluid" style={{ height: "100vh" }}>
        <div className="row" style={{ height: "100%" }}>
          <div className="home_container">
            <div className="home_title_div home_program_div">
              <h1 className="home_title">Webinar List</h1>
              <div>
                <button className="addprogrambtn" onClick={addWebinar}>
                  + Add Webinar
                </button>
              </div>
            </div>

            <div className="active_inactive_flex">
              <div
                className="active_inactive_tab"
                onClick={() => setActiveSection("active")}
                style={
                  activeSection == "active" ? { background: "#e7e7e7" } : {}
                }
              >
                <p>ACTIVE WEBINAR</p>
              </div>
              <div
                className="active_inactive_tab"
                onClick={() => setActiveSection("inactive")}
                style={
                  activeSection == "inactive" ? { background: "#e7e7e7" } : {}
                }
              >
                <p>INACTIVE WEBINAR</p>
              </div>
            </div>

            {activeSection === "active" ? (
              <>
                <div className="home_table">
                  <h4>All</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr className="uper_table_container">
                        <th scope="col">Serial No.</th>
                        <th
                          scope="col"
                          style={{ width: "28%", paddingRight: "10px" }}
                        >
                          Webinar Topic
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {activeWebinarData.length > 0 &&
                        activeWebinarData.map((data, idx) => {
                          return (
                            <tr className="uper_table_container" key={idx}>
                              <th scope="col">{idx + 1}</th>

                              <th scope="row" style={{ paddingRight: "20px" }}>
                                {data.topic}
                              </th>
                              <th>
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "#fff",
                                  }}
                                  to={`/EditWebinar${data._id}`}
                                >
                                  <img
                                    src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                    alt="edit icon"
                                    height="20px"
                                    width="20px"
                                  />
                                </Link>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>

                <div className="home_table">
                  <h4>Header</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr className="uper_table_container">
                        <th scope="col">Serial No.</th>
                        <th
                          scope="col"
                          style={{ width: "28%", paddingRight: "10px" }}
                        >
                          Webinar Topic
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {activeWebinarData.length > 0 &&
                        activeWebinarData
                          .filter((obj) =>
                            obj.placeholderTag.includes("HEADER")
                          )
                          .map((data, idx) => {
                            return (
                              <tr className="uper_table_container" key={idx}>
                                <th scope="col">{idx + 1}</th>

                                <th
                                  scope="row"
                                  style={{ paddingRight: "20px" }}
                                >
                                  {data.topic}
                                </th>
                                <th>
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "#fff",
                                    }}
                                    to={`/EditWebinar${data._id}`}
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                      alt="edit icon"
                                      height="20px"
                                      width="20px"
                                    />
                                  </Link>
                                </th>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>

                <div className="home_table">
                  <h4>Recommended</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr className="uper_table_container">
                        <th scope="col">Serial No.</th>
                        <th
                          scope="col"
                          style={{ width: "28%", paddingRight: "10px" }}
                        >
                          Webinar Topic
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {activeWebinarData.length > 0 &&
                        activeWebinarData
                          .filter((obj) =>
                            obj.placeholderTag.includes("RECOMMENDED")
                          )
                          .map((data, idx) => {
                            return (
                              <tr className="uper_table_container" key={idx}>
                                <th scope="col">{idx + 1}</th>
                                <th
                                  scope="row"
                                  style={{ paddingRight: "20px" }}
                                >
                                  {data.topic}
                                </th>
                                <th>
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "#fff",
                                    }}
                                    to={`/EditWebinar${data._id}`}
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                      alt="edit icon"
                                      height="20px"
                                      width="20px"
                                    />
                                  </Link>
                                </th>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>

                <div className="home_table">
                  <h4>Recorded</h4>
                  <table className="table table-striped">
                    <thead>
                      <tr className="uper_table_container">
                        <th scope="col">Serial No.</th>

                        <th
                          scope="col"
                          style={{ width: "28%", paddingRight: "10px" }}
                        >
                          Webinar Topic
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {activeWebinarData.length > 0 &&
                        activeWebinarData
                          .filter((obj) =>
                            obj.placeholderTag.includes("RECORDED")
                          )
                          .map((data, idx) => {
                            return (
                              <tr className="uper_table_container" key={idx}>
                                <th scope="col">{idx + 1}</th>

                                <th
                                  scope="row"
                                  style={{ paddingRight: "20px" }}
                                >
                                  {data.topic}
                                </th>
                                <th>
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "#fff",
                                    }}
                                    to={`/EditWebinar${data._id}`}
                                  >
                                    <img
                                      src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                      alt="edit icon"
                                      height="20px"
                                      width="20px"
                                    />
                                  </Link>
                                </th>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="home_table">
                <table className="table table-striped">
                  <thead>
                    <tr className="uper_table_container">
                      <th
                        scope="col"
                        style={{ width: "28%", paddingRight: "10px" }}
                      >
                        Webinar Topic
                      </th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {inactiveWebinarData.length > 0 &&
                      inactiveWebinarData.map((data, idx) => {
                        return (
                          <tr className="uper_table_container" key={idx}>
                            <th scope="row" style={{ paddingRight: "20px" }}>
                              {data.topic}
                            </th>
                            <th>
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#fff",
                                }}
                                to={`/EditWebinar${data._id}`}
                              >
                                <img
                                  src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                  alt="edit icon"
                                  height="20px"
                                  width="20px"
                                />
                              </Link>
                            </th>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Webinar;
