import React, { useEffect, useState } from "react";
import { getUtm } from "../services/utmapi";
import Loader from "./loader/loading";
import { Link } from "react-router-dom";
import "./css/home.css";
import moment from "moment";

function UtmHome() {
  useEffect(() => {
    getUtmData().then((data) => {
      setPropsdata(data.data.data);
      setLoading(false);
    });
    async function getUtmData() {
      setLoading(true);
      return await getUtm();
    }
  }, []);

  const [propsdata, setPropsdata] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loader />}
      <div
        className="container-fluid"
        style={{ height: "100vh", maxWidth: "1500px" }}
      >
        <div className="row" style={{ height: "100%" }}>
          <div className="utm_home_container">
            <div className="home_title_div create_location_page_div">
              <h1 className="home_title">Urchin Tracking Module(UTM)</h1>
              <div className>
                <a href="/CreateUtms">
                  <button className="create_location_button">
                    Add New UTM
                  </button>
                </a>
              </div>
            </div>

            <div>
              <table className="table table-striped">
                <thead>
                  <tr className="uper_table_container">
                    <th scope="col">UTM Campaign Type</th>
                    <th scope="col">UTM Source</th>
                    <th scope="col">Lead Source</th>
                    <th scope="col">Last Change Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {propsdata.length > 0 &&
                    propsdata.map((data, idx) => {
                      return (
                        <tr className="uper_table_container" key={idx}>
                          <th scope="row">{data.utmCampaignType}</th>
                          <th scope="row">{data.utmSource}</th>
                          <th scope="row">{data.leadSource}</th>
                          <th>
                            {moment(data.updatedAt).format(
                              "DD/MM/YYYY, h:mm:ss a"
                            )}
                          </th>
                          <th>
                            <Link
                              style={{ textDecoration: "none", color: "#fff" }}
                              to={`/UtmDetail${data._id}`}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                alt="edit icon"
                                height="20px"
                                width="20px"
                              />
                            </Link>
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UtmHome;
