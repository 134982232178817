import React from "react";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import Loader from "./loader/loading";
import { getApprovalspecificschema } from "../services/approvalapi";
import { getOtprequest } from "../services/approvalapi";
import { editUtmDetail } from "../services/utmapi";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { green, teal } from "@mui/material/colors";
import Checkbox from "@mui/material/Checkbox";
const label2 = { inputProps: { "aria-label": "Switch demo" } };
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));

function UtmEdit() {
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(true);
  let lastcheckdata = useLocation();
  let { id } = useParams();
  let something = useLocation();
  const [editdata, setEditdata] = useState({});
  const [loading, setLoading] = useState(false);
  const onchnage = function (e) {
    let body = { ...editdata };
    body[e.target.name] = e.target.value;
    setEditdata(body);
  };

  useEffect(() => {
    for (let i = 0; i < something.state.checkbox.length; i++) {
      if (something.state.checkbox[i].name == "callCenterLead") {
        setChecked3(something.state.checkbox[i].value == "true" ? true : false);
      }
      if (something.state.checkbox[i].name == "otpRecaptchaValidation") {
        setChecked2(something.state.checkbox[i].value == "true" ? true : false);
      }
    }
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let body = { ...editdata };
      console.log("body:::::::::", body, ":::::::body");
      for (let i = 0; i < Object.keys(body).length; i++) {
        // console.log("1")
        if (body[Object.keys(body)[i]]) {
          console.log(typeof body[Object.keys(body)[i]], "trim function");
          if (typeof body[Object.keys(body)[i]] == "string") {
            if (body[Object.keys(body)[i]].trim() === "") {
              setLoading(false);
              return alert("Empty Field Found");
            }
          } else if (body[Object.keys(body)[i]].length == 0) {
            setLoading(false);
            return alert("Empty Field Found");
          }
        }
      }

      const data = await editUtmDetail(id, body);
      sahil().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      async function sahil() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Enter Field is less then the required Validation");
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let body = { ...editdata };
    body["act"] = !event.target.checked;
    setEditdata(body);
  };

  const handleChange2 = (event) => {
    setChecked2(event.target.checked);
    let body = { ...editdata };
    body["otpRecaptchaValidation"] = event.target.checked;
    setEditdata(body);
  };

  const handleChange3 = (event) => {
    setChecked3(event.target.checked);
    let body = { ...editdata };
    body["callCenterLead"] = event.target.checked;
    setEditdata(body);
  };

  console.log({ checked });

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit" style={{ width: "80%" }}>
        <div className="main_container_divider">
          <div className="main_container_divid">
            <div className="change_content">
              <p>CHANGE CONTENT</p>
            </div>
            <form onSubmit={submitHandler}>
              <div>
                {something.state.checkbox
                  .filter((data, index) => index != 0)
                  .map((data) => {
                    return (
                      <div className="enquire_box_space">
                        <div className="form-inpt-contnr">
                          <p className="enquireFormplace">{data.name}</p>
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <h1
                              style={{
                                fontSize: "18px",
                                opacity: ".75",
                                width: "18%",
                                whiteSpace: "nowrap",
                              }}
                            >
                              PREVIOUS CONTENT
                            </h1>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <p className="enquireFormplaceutm">{data.value}</p>
                          </div>
                          {data.name === "act" ? (
                            <>
                              <div>
                                <div>
                                  <div
                                    className="program_container_box"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2>Delete Utm</h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <GreenSwitch
                                        {...label2}
                                        checked={checked}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : data.name === "otpRecaptchaValidation" ? (
                            <>
                              <div>
                                <div>
                                  <div
                                    className="program_container_box"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2>
                                      Otp Recaptcha Validation Enable/Disable
                                    </h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Checkbox
                                        {...label}
                                        checked={checked2}
                                        onChange={handleChange2}
                                        sx={{
                                          color: teal[800],
                                          "&.Mui-checked": {
                                            color: teal[600],
                                          },
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 20,
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : data.name === "callCenterLead" ? (
                            <>
                              <div>
                                <div>
                                  <div
                                    className="program_container_box"
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    <h2>Call Center Lead Enable/Disable</h2>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Checkbox
                                        {...label}
                                        checked={checked3}
                                        onChange={handleChange3}
                                        sx={{
                                          color: teal[800],
                                          "&.Mui-checked": {
                                            color: teal[600],
                                          },
                                          "& .MuiSvgIcon-root": {
                                            fontSize: 20,
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline",
                              }}
                            >
                              <h1
                                style={{
                                  fontSize: "18px",
                                  opacity: ".75",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                NEW CONTENT
                              </h1>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <input
                                defaultValue={data.value}
                                value={editdata[data.name]}
                                onChange={onchnage}
                                type="text"
                                className="form-inpt enquireFormInputEditUtm"
                                name={data.name}
                                required
                                maxLength={10000}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="request_approval">
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                >
                  Request Approval
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UtmEdit;
