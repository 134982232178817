import React, { useState, useEffect } from "react";
import Loader from "./loader/loading";
import {
  createWebinarLeadsource,
  getWebinarLeadSource,
} from "../services/leaddistribution";

function WebinarCampaign(props) {
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [webinarLeadSource, setWebinarLeadSource] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await createWebinarLeadsource({
        leadSource: leadSource.trim(),
        utmCampaignName: campaignName.trim(),
      });
      if (data.status == 200) {
        alert("Successfully Added");
        window.location.reload();
        setLoading(false);
      } else {
        alert("Something went wrong");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  useEffect(() => {
    getWebinarSource().then((data) => {
      setWebinarLeadSource(data.data);
      setLoading(false);
    });

    async function getWebinarSource() {
      setLoading(true);
      return await getWebinarLeadSource();
    }
  }, []);

  console.log(webinarLeadSource);

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Create Webinar Campaign Lead Source</h1>
        </div>

        <div className="createUtmmaindiv">
          <div className="createUtmbottomhalf">
            <form onSubmit={submitHandler}>
              <div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter UTM Campaign Name
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    required
                    type="text"
                    value={campaignName}
                    onChange={(e) => setCampaignName(e.target.value)}
                    name="campaignName"
                  />
                </div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Enter Lead Source
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput"
                    required
                    type="text"
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                    name="leadSource"
                  />
                </div>
              </div>
              <div className="request_approval" style={{ display: "flex" }}>
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                  style={{ marginLeft: "20px" }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div>
          <div className="groupmaintablediv">
            <div className="" style={{ overflowX: "scroll", width: "100%" }}>
              <div style={{ width: "800px" }}>
                <table className="table table-striped groupleadtable">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        style={{
                          width: "254px",
                          height: "48px",
                          textAlign: "center",
                        }}
                      >
                        Webinar Utm Name
                      </th>
                      <th
                        scope="col"
                        style={{
                          width: "254px",
                          height: "48px",
                          textAlign: "center",
                        }}
                      >
                        Webinar Lead Source
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {webinarLeadSource &&
                      webinarLeadSource.value &&
                      Object.entries(webinarLeadSource.value).map(
                        ([key, value], idx) => {
                          return (
                            <tr className="" key={idx}>
                              <th
                                scope="row"
                                style={{
                                  borderRight:
                                    "1px solid rgba(60, 72, 82, 0.25)",
                                  textAlign: "center",
                                }}
                              >
                                {key}
                              </th>
                              <th
                                scope="row"
                                style={{
                                  borderRight:
                                    "1px solid rgba(60, 72, 82, 0.25)",
                                  textAlign: "center",
                                }}
                              >
                                {value}
                              </th>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebinarCampaign;
