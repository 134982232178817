import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "../loader/loading";
import {
  exportLeadsToCsv,
  getSubSalesGroup,
  getPgLsKeyAndCost,
} from "../../services/leaddistribution";
import { downloadFile } from "../../services/helper";

function ExportLead(props) {
  let DATE = new Date();
  DATE.setMonth(DATE.getMonth() - 2);
  var minDate = DATE.toISOString().slice(0, 10);

  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [program, setProgram] = useState("");
  const [salesGroup, setSalesGroup] = useState("");
  const [subSalesGroupArray, setSubSalesGroupArray] = useState();
  const [salesGroupArray, setSalesGroupArray] = useState(props.salesGroupArray);
  const [dropdownProgram, setDropdownPrograms] = useState("");
  const [lsplsArray, setLsplsArray] = useState(props.lsplsArray);
  const [subSalesGroup, setSubSalesGroup] = useState();
  const [limit, setLimit] = useState();

  useEffect(() => {
    if (salesGroup) {
      getSubSalesGroups()
        .then((data) => {
          setSubSalesGroupArray(data.data);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });

      async function getSubSalesGroups() {
        setLoading(true);
        return await getSubSalesGroup(salesGroup);
      }

      getRevenueProgram()
        .then((data) => {
          console.log(data);
          setDropdownPrograms(data.data);
        })
        .finally(() => {
          setLoading(false);
        });

      async function getRevenueProgram() {
        return await getPgLsKeyAndCost(salesGroup);
      }
    }
  }, [salesGroup]);

  const handleExport = async (e) => {
    e.preventDefault();
    console.log(program);
    console.log(salesGroup);
    console.log(leadSource);
    console.log(subSalesGroup);
    console.log(fromDate);
    console.log(toDate);
    console.log(limit);

    if (fromDate && toDate && limit) {
      setLoading(true);
      let response = await exportLeadsToCsv(
        fromDate,
        toDate,
        salesGroup,
        program,
        leadSource,
        subSalesGroup,
        limit
      );

      console.log({ response });

      if (response.status == 200) {
        await downloadFile(response);
        setLoading(false);
      } else if (response.status === 420) {
        alert(response.data.message);
        setLoading(false);
      } else {
        alert("Unable to Export the File");
        setLoading(false);
      }
    } else {
      alert("Please Enter Date and Number of Leads");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => props.setShow(false)}
        className="leaddistributionmodel"
      >
        <Modal.Header closeButton className="leadmodelheaderheading">
          Export Leads
        </Modal.Header>

        <div style={{ width: "500px" }}>
          <div>
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <div className="timetablecontainer">
                <p>Lead Generation Time</p>
                <div className="timetabletimecontainer">
                  <div>
                    <p>From</p>
                    <input
                      className="timeleadtabledrop"
                      type="date"
                      id="monthyear"
                      name="monthyear"
                      value={fromDate}
                      min={minDate}
                      required="true"
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div>
                    <p>To</p>
                    <input
                      className="timeleadtabledrop"
                      type="date"
                      id="monthyear"
                      name="monthyear"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="timetablecontainer">
                <p>Sales Group</p>
                <div>
                  <select
                    value={salesGroup}
                    onChange={(e) => setSalesGroup(e.target.value)}
                    className="timeleadtabledrop"
                    name="sidesalesgroup"
                    id="sidesalesgroup"
                  >
                    <option selected disabled value="">
                      Sales Group
                    </option>
                    <option value="">All Sales Group</option>
                    {salesGroupArray &&
                      salesGroupArray.map((data) => {
                        return <option value={data._id}>{data.email}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="timetablecontainer">
                <p>Program</p>
                <select
                  value={program}
                  onChange={(e) => setProgram(e.target.value)}
                  className="timeleadtabledrop"
                  name="sideprogram"
                  id="sideprogram"
                  disabled={salesGroup == "" ? true : false}
                >
                  <option selected disabled value="">
                    Program
                  </option>
                  <option value="">All Program</option>
                  {dropdownProgram &&
                    dropdownProgram.map((data) => {
                      return (
                        <option value={data.leadsquareProgramKey}>
                          {data.leadsquareProgramKey}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="timetablecontainer">
                <p>Lead Source</p>
                <div>
                  <select
                    className="timeleadtabledrop"
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                    name="leadsource"
                    id="leadsource"
                  >
                    <option disabled selected value="">
                      Lead Source
                    </option>
                    <option value="">All Lead Source</option>
                    {lsplsArray &&
                      lsplsArray.map((data) => {
                        return <option value={data}>{data}</option>;
                      })}
                  </select>
                </div>
              </div>

              <div className="timetablecontainer">
                <p>Sub-sales Group</p>
                <div>
                  <select
                    value={subSalesGroup}
                    onChange={(e) => setSubSalesGroup(e.target.value)}
                    className="timeleadtabledrop"
                    name="subsalesgroup"
                    id="subsalesgroup"
                    disabled={
                      props?.leadDistubtionData?.salesGroup == "" ? true : false
                    }
                  >
                    <option value="" disabled selected>
                      All
                    </option>
                    <option value="">All Sub Sales Group</option>
                    {subSalesGroupArray &&
                      subSalesGroupArray.map((data, i) => {
                        return (
                          <option key={i} value={data._id}>
                            {data.email}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>

              <div className="timetablecontainer">
                <p>Number Of Lead</p>
                <div>
                  <input
                    type="number"
                    id="limit"
                    className="timeleadtabledrop"
                    name="limit"
                    required={true}
                    value={limit}
                    onChange={(e) => setLimit(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="addprogrambtns">
              <button
                onClick={() => props.setShow(false)}
                className="addprogramcancelbtn"
              >
                Cancel
              </button>
              <button
                onClick={(e) => handleExport(e)}
                className="addprogramsubmitbtn"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ExportLead;
