import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Form from "react-jsonschema-form";
import Loader from "./loader/loading";
import { editLocationProgramContent } from "../services/locationProgram";
import { getOtprequest } from "../services/approvalapi";
import { getApprovalspecificschema } from "../services/approvalapi";
import { dataBase64String } from "../constants";

function ProgramlocationEdit() {
  let data = useLocation();
  let { id } = useParams();
  console.log({ id });
  const [loading, setLoading] = useState(false);

  const formatDataBackToNormal = (data) => {
    let keys = [];
    let formData = {};
    Object.keys(data)
      .map((key) => key.split("-")[0])
      .forEach((key) => {
        if (keys.indexOf(key) == -1) {
          keys.push(key);
        }
      });
    keys.forEach((key) => {
      const sub_keys = Object.keys(data)
        .filter((k) => k.includes(`${key}-`))
        .map((k) => k.split("-")[1]);
      formData[key] = {};
      sub_keys.forEach((k) => {
        formData[key][k] = data[`${key}-${k}`];
      });
    });

    return formData;
  };

  let formatedData = formatDataBackToNormal(data.state.state);

  for (let i in data.state.state) {
    if (i.includes("image")) {
      let data1 = data.state.state[i].data1;
      data.state.state[i].data = dataBase64String.data;
    } else {
      let data1 = data.state.state[i].data1;
      data.state.state[i].data = data1;
    }
  }

  let LocationUiSchema = data.state.edituischema;

  modifyStaticWebinarSchemaAsPerFieldsToEdit();
  function modifyStaticWebinarSchemaAsPerFieldsToEdit() {
    for (let webinarSectionKey in data.state.state) {
      let webinarSectionObject = data.state.state[webinarSectionKey];
      let count = 0;
      if (webinarSectionObject.checkbox == true) {
        count += 1;
      }
      if (webinarSectionObject.checkbox != true) {
        LocationUiSchema[webinarSectionKey]["ui:widget"] = "hidden";
      }
      if (count == 0) {
        LocationUiSchema[webinarSectionKey]["ui:widget"] = "hidden";
      }
    }
  }

  const handleSubmit = async (e) => {
    console.log(e.formData, "res");
    let formData = formatDataBackToNormal(e.formData);
    console.log({ formData });
    let obj = {};
    for (let i in formatedData) {
      obj[i] = {};
      for (let j in formatedData[i]) {
        if (formatedData[i][j].checkbox) {
          obj[i][j] =
            formData[i][j].data === undefined ? "" : formData[i][j].data;
          if (
            formData[i][j].data === undefined &&
            typeof formData[i][j].data1 === "object"
          ) {
            obj[i][j] = {};
          }
          if (
            formData[i][j].data === undefined &&
            Array.isArray(formData[i][j].data1)
          ) {
            obj[i][j] = [];
          }
        }
      }
      for (let i in obj) {
        if (Object.keys(obj[i]).length === 0) {
          delete obj[i];
        }
      }
    }
    console.log(obj, "data");
    setLoading(true);
    let resBack = await editLocationProgramContent(id, obj);
    console.debug({ resBack });
    if (resBack.status === 200) {
      approvalCall().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      async function approvalCall() {
        return await getApprovalspecificschema(resBack.data.data);
      }
    } else {
      setLoading(false);
      alert(resBack.data.message);
    }
    try {
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert("Enter Field is less then the required Validation");
    }
  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="main_container_edit_viewCourse">
          <Form
            schema={data.state.formDataSchema}
            uiSchema={data.state.edituischema}
            formData={data.state.state}
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ProgramlocationEdit;
