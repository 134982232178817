import React, { useState } from "react";
import Loader from "../loader/loading";

function AddAdvisor() {
  const [advisorName, setAdvisorName] = useState("");
  const [advisorEmail, setAdvisorEmail] = useState("");
  const [salesGroup, setSalesGroup] = useState("");
  const [subSalesGroup, setSubSalesGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(advisorName, advisorEmail, salesGroup, subSalesGroup);
  };

  const submitHandler2 = async (e) => {
    e.preventDefault();
    console.log(file);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="adddvisormaincontainer">
        {/* single */}
        <div>
          <form onSubmit={submitHandler}>
            <div className="addadvisorsinglecontainer">
              <h3 className="addAdvisorheading">Add Single Advisor</h3>

              <div className="addsingleadvisor2container">
                <div>
                  <h3 className="addprogramheading">Enter Advisor Name</h3>
                  <div>
                    <input
                      value={advisorName}
                      className="addadvisorinput"
                      placeholder="abc"
                      type="text"
                      id="target"
                      required
                      onChange={(e) => setAdvisorName(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <h3 className="addprogramheading">Enter Advisor Email ID</h3>
                  <div>
                    <input
                      value={advisorEmail}
                      className="addadvisorinput"
                      placeholder="abc@abc.com"
                      type="text"
                      id="target"
                      required
                      onChange={(e) => setAdvisorEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <h3 className="addprogramheading">Select Sales Group</h3>
                  <div>
                    <select
                      value={salesGroup}
                      onChange={(e) => setSalesGroup(e.target.value)}
                      required
                      className="addadvisorinput"
                      name="cars"
                      id="cars"
                    >
                      <option disabled selected value="">
                        Select Sales Group
                      </option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>

                <div>
                  <h3 className="addprogramheading">Select Sub-sales Group</h3>
                  <div>
                    <select
                      value={subSalesGroup}
                      onChange={(e) => setSubSalesGroup(e.target.value)}
                      required
                      className="addadvisorinput"
                      name="cars"
                      id="cars"
                    >
                      <option disabled selected value="">
                        Select Sub-sales Group
                      </option>
                      <option value="saab">Saab</option>
                      <option value="mercedes">Mercedes</option>
                      <option value="audi">Audi</option>
                    </select>
                  </div>
                </div>
              </div>

              <div>
                <button onClick={submitHandler} className="addsinlgeadvisorbtn">
                  Add Single Advisor
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* multipe */}
        <div>
          <form onSubmit={submitHandler2}>
            <div className="addadvisormultiplecontainer">
              <h3 className="addAdvisorheading">
                Upload Multiple Advisors{" "}
                <a
                  href="https://cdn.pegasus.imarticus.org/sample-file/Advisorsample%20-%20Sheet1.csv"
                  target="_blank"
                  className="addAdvisorheadingspan"
                >
                  i
                </a>
              </h3>
              <div className="addsingleadvisor2container">
                <div>
                  <input
                    className="addadvisorfileinput"
                    value={file}
                    type="file"
                    id="myfile"
                    name="myfile"
                    onChange={(e) => setFile(e.target.value)}
                  />
                </div>

                <buttom
                  onClick={submitHandler2}
                  className="addadvisormultiplefilebtn"
                >
                  Upload Multiple Request
                </buttom>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAdvisor;
