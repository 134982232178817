import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function getAllNewsScheam() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_newsschema`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getSpecificNews(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_newsschema?id=${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function uploadImages(id, img) {
  console.log("====================================");
  console.log(img);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/imageupload?id=${id}`,
      img,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}
// media
export async function getFolder(id, data) {
  console.log("====================================");
  console.log(data);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/folder?id=${id}`,
      data,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}
// show folders
export async function showFolder(id) {
  console.log("====================================");
  // console.log(data);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/getallfolder${
        id ? `?id=${id}` : ""
      }`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function uploadMediaImages(id, img, folderkey) {
  console.log("====================================");
  console.log(img, folderkey);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/mediaupload?id=${id}&folder=${folderkey}`,
      img,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return error.response;
  }
}

export async function createNews(body) {
  console.log("====================================");
  console.log(body);
  console.log("====================================");
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/add_new_news`,
      {
        aid: cookies.get("aid", { domain: configs.DOMAIN, path: "/" }),
        pid: cookies.get("pid", { domain: configs.DOMAIN, path: "/" }),
        data: body,
      },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log("helo ::: ", { error });
    return null;
  }
}

export async function editNewsApi(id, data) {
  try {
    const cookies = new Cookies();
    let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
    // Fetch data from external API
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/editnewsschema?id=${id}`,
      {
        ...data,
        aid: aid,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    console.log(error, "error from edit api");
    return Promise.reject(error);
  }
}
