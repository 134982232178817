import React, { useEffect, useState } from "react";
import { leadApiHelper, sendEventToLS } from "../services/marketingapi";
import { ValidateLSKey } from "../services/helper";
import axios from "axios";
import { configs } from "../constants";
import CountryCodes from "./CountryCodes";
import locationvalue from "../Components/json/masterLocationsAndAreaOfInterest.json";
import dataArr from "../Components/json/program.json";
import Loader from "./loader/loading";
import { getUserdetail } from "../services/getUserdetail";

const CreateLead = () => {
  const [loggedInUserEmail, setLoggedInUserEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");

  const [error, setError] = useState();
  const [contact, setContact] = useState("");
  const [alternativeContact, setAlternativeContact] = useState("");
  const [email, setEmail] = useState("");
  const [stateValue, setState] = useState("");
  const [locationValue, setLocation] = useState("");
  const [locationID, setLocationID] = useState("");
  const [countryValue, setCountry] = useState("");
  const [highestQualification, setHighestQualification] = useState("");
  const [areaofinterestValue, setAreaofinterest] = useState("");
  const [modeoflearningID, setModeoflearningID] = useState("");
  //   const [
  //     shouldEnableRecaptchaAndVerifyOTPForm,
  //     setShouldEnableRecaptchaAndVerifyOTPForm,
  //   ] = useState(false);
  const [higherQualification, setHigherQualification] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [alternativeCountryCode, setAlternativeCountryCode] = useState("");

  //   const [requestId, setRequestID] = useState("");
  const [tnc, setTnc] = useState(true);
  const [whatsAppConcent, setWhatsAppConcent] = useState(true);
  //   const [utag, setUTAG] = useState(false);
  //   const [onlineLocation, setOnlineLocation] = useState("");
  const [professionalExp, setProfessionalExp] = useState("");
  const [customLeadSource, setCustomLeadSource] = useState("");
  const [courseList, setCourseList] = useState([]);
  const getlocation = async () => {
    try {
      const response = await axios.get(
        `${configs.API_ENDPOINT}/leadsquare/location`
      );
      console.log(response);
      setLocationID(response.data.data.locationID);
      setCountry(response.data.data.country);
    } catch (error) {
      console.log(error);
    }
  };

  const helperFucntion = async () => {
    const response = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanelpublic/get_navbar_and_footer_data`,
      {
        headers: {
          skey: configs.skey,
        },
      }
    );
    setCourseList(response.data.data.allData);
    const userData = await getUserdetail();
    setLoggedInUserEmail(userData.email);
    console.log({ response });
    if (window) {
      getlocation();
    }
  };

  useEffect(() => {
    helperFucntion();
  }, []);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (customLeadSource.length == 0) {
      setError("Please select leadsource.");
      return;
    }
    try {
      setLoading(true);
      setError(null);
      const data = await leadApiHelper({
        FirstName: name,
        mx_Training_Location: stateValue === "" ? locationValue : stateValue,
        mx_Location_ID: locationID,
        CountryCode: countryCode || 91,
        Phone: contact,
        EmailAddress: email,
        mx_Website_Page_URL: window.location.href,
        HTTPReferrerURL: document.referrer,
        mx_Program_from_website: ValidateLSKey(courseList, areaofinterestValue)
          ? areaofinterestValue
          : "",
        mx_Program_3: ValidateLSKey(courseList, areaofinterestValue)
          ? areaofinterestValue
          : "",
        Source: customLeadSource,
        mx_City: city,
        mx_country: countryValue,
        mx_Lead_Source_Detail: customLeadSource,
        leadSource: customLeadSource,
        // mx_lead_device: device,
        mx_Book_a_time_slot: "none",
        a_tnc: tnc,
        agreeToCourseUpdatesOnWhatsAppOrSms: whatsAppConcent,
        mx_Message: message ? message : undefined,
        // mx_UI_Interface_button: formClickedButton,
        mx_H_Qualification: highestQualification
          ? highestQualification
          : undefined,
        // a_mode: mode,
        mx_Experience: professionalExp ? professionalExp : undefined,
        mx_Mode_of_Delivery: modeoflearningID ? modeoflearningID : undefined,
        mx_Alternate_Phone_Number: alternativeContact
          ? `+${alternativeCountryCode || 91}-${alternativeContact}`
          : undefined,
        is_activity_enabled: true,
      });
      //   setRequestID(data.RequestID);
      const eventResponse = await sendEventToLS({
        RelatedProspectId: data.RequestID,
        eventName: "LIVE_CHAT_WITH_IMARTICUS",
        eventCustomNote: loggedInUserEmail,
      });
      console.log({ eventResponse });
      setLoading(false);
      alert("Lead Created");
      setName("");
      setCity("");

      setError("");
      setContact("");
      setAlternativeContact("");
      setEmail("");
      setState("");
      setLocation("");
      setCountry("");
      setHighestQualification("");
      setAreaofinterest("");
      setModeoflearningID("");
      setHigherQualification("");
      setMessage("");
      setCountryCode("");
      setAlternativeCountryCode("");
      setProfessionalExp("");
      setCustomLeadSource("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  console.log({ courseList });
  return (
    <>
      {loading && <Loader />}
      <div style={{ maxWidth: "700px" }}>
        <form onSubmit={submitHandler}>
          <div className="enquire_box_space">
            <div className="form-inpt-contnr">
              <input
                value={name}
                type="text"
                onChange={(e) => setName(e.target.value)}
                className="form-inpt enquireFormInput"
                placeholder="Full Name*"
                id="FirstName"
                name="FirstName"
                required
                maxLength={50}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="enquire_box_space">
            <div className="form-inpt-contnr">
              <input
                type="email"
                value={email}
                className="form-inpt enquireFormInput"
                placeholder="Enter Email ID*"
                id="EmailAddress"
                onChange={(e) => setEmail(e.target.value)}
                name="EmailAddress"
                required
                maxLength={320}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div className="mobile_number_input enquire_box_space">
            <div style={{ textDecoration: "none", color: "#000000" }}>
              <CountryCodes
                value={countryCode}
                onChange={(e) => setCountryCode(+e.target.value)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className="form-inpt-contnr">
                <input
                  type="tel"
                  value={contact}
                  className="form-inpt enquireFormInput2"
                  placeholder="Contact Number*"
                  onChange={(e) => setContact(e.target.value)}
                  name="Phone"
                  id="Phone"
                  required
                  maxLength={20}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>

          {/* Area of Interset */}
          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={areaofinterestValue}
              onChange={(e) => setAreaofinterest(e.target.value)}
              className="form-inpt enquireFormInput"
              name="areaofinterest"
              id="areaofinterest"
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
              required={true}
            >
              <option value="" disabled selected>
                Preferred Program*
              </option>
              {courseList &&
                courseList.length > 0 &&
                courseList.map((data) => (
                  <option
                    key={data.leadsquareProgramKey}
                    value={data.leadsquareProgramKey}
                  >
                    {data.programName}
                  </option>
                ))}
            </select>
          </div>

          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={locationValue}
              onChange={(e) => setLocation(e.target.value)}
              className="form-inpt enquireFormInput"
              name="c_state"
              id="c_state"
              required={true}
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option value="" selected>
                Select Closest Location*
              </option>
              {locationvalue.center.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          {locationValue === "Other" && (
            <div className="form-inpt-contnr enquire_box_space">
              <select
                value={stateValue}
                onChange={(e) => setState(e.target.value)}
                className="form-inpt enquireFormInput"
                name="c_state"
                id="c_state"
                required={true}
                style={{
                  textDecoration: "none",
                  color: "#000000",
                  width: "100%",
                }}
              >
                <option value="" disabled selected>
                  Select Your State*
                </option>
                {locationvalue.state.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
                <optgroup label="Union Territories">
                  {locationvalue.UnionTerritories.map((item) => {
                    return (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    );
                  })}
                </optgroup>
              </select>
            </div>
          )}
          {/* <div className="enquire_box_space">
            <div className="form-inpt-contnr">
              <input
                value={city}
                type="text"
                onChange={(e) => setCity(e.target.value)}
                className="form-inpt enquireFormInput"
                placeholder="City Name*"
                id="cityName"
                name="cityName"
                required
                maxLength={100}
                style={{ width: "100%" }}
              />
            </div>
          </div> */}
          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={countryValue}
              onChange={(e) => setCountry(e.target.value)}
              className="form-inpt enquireFormInput"
              name="c_state"
              id="c_state"
              required={true}
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option value="" disabled selected>
                Select Country
              </option>
              {dataArr.countryData.map((item) => {
                return (
                  <option
                    key={item.name.split(" ")[0]}
                    value={item.name.split(" ")[0]}
                  >
                    {item.name.split(" ")[0]}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={highestQualification}
              onChange={(e) => setHighestQualification(e.target.value)}
              className="form-inpt enquireFormInput"
              name="c_state"
              id="c_state"
              // required={true}
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option value="" disabled selected>
                Select Qualification
              </option>
              {[
                ...dataArr.thankyoupage.highestQualification,
                ...dataArr.highestQualification,
              ].map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={professionalExp}
              onChange={(e) => setProfessionalExp(e.target.value)}
              className="form-inpt enquireFormInput"
              name="c_state"
              id="c_state"
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option value="" disabled selected>
                Professional Experience (No. of years)
              </option>
              {[...dataArr.thankyoupage.ProfessionalExperience].map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>

          {/* mode of training */}
          <div className="form-inpt-contnr enquire_box_space">
            <select
              value={modeoflearningID}
              onChange={(e) => {
                setModeoflearningID(e.target.value);
              }}
              className="form-inpt enquireFormInput"
              name="modeoflearning"
              id="modeoflearning"
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option value="" disabled selected>
                Mode of Training
              </option>
              <option key="Classroom" value="Classroom">
                Classroom
              </option>
              <option key="Online" value="Online">
                Live Online Training
              </option>
            </select>
          </div>

          <div className="form-inpt-contnr enquire_box_space">
            <textarea
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              className="form-inpt enquireFormInput"
              name="modeoflearning"
              id="modeoflearning"
              style={{ color: "#000000", width: "100%" }}
              rows={20}
              placeholder="Enter Message"
            ></textarea>
          </div>

          {/* <div className="form-inpt-contnr enquire_box_space">
            <select
              onChange={(e) => setHigherQualification(e.target.value)}
              value={higherQualification}
              className="form-inpt enquireFormInput"
              name="modeoflearning"
              id="modeoflearning"
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
            >
              <option key={"-"} value={""} disabled>
                Academic stream*
              </option>
              {dataArr.thankyoupage.highestQualification.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div> */}

          <div className="form-inpt-contnr enquire_box_space">
            <select
              onChange={(e) => setCustomLeadSource(e.target.value)}
              value={customLeadSource}
              className="form-inpt enquireFormInput"
              name="modeoflearning"
              id="modeoflearning"
              style={{
                textDecoration: "none",
                color: "#000000",
                width: "100%",
              }}
              required={true}
            >
              <option disabled selected value={""}>
                Select LeadSource*
              </option>
              {dataArr.whatsappBotLeadSource.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="mobile_number_input enquire_box_space">
            <div style={{ textDecoration: "none", color: "#000000" }} className>
              <CountryCodes
                value={alternativeCountryCode}
                onChange={(e) => setAlternativeCountryCode(+e.target.value)}
              />
            </div>
            <div style={{ width: "100%" }}>
              <div className="form-inpt-contnr">
                <input
                  type="tel"
                  value={alternativeContact}
                  className="form-inpt enquireFormInput2"
                  placeholder="Alternative Contact Number"
                  size={10}
                  onChange={(e) => {
                    const cleaned = e.target.value
                      .replace(/\D/g, "")
                      .slice(0, 10);
                    setAlternativeContact(cleaned);
                  }}
                  name="Phone"
                  id="Phone"
                  pattern="\d{10}"
                  maxLength={10}
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          </div>

          <div className="E_enquireCheckbox__next">
            <input
              type="checkbox"
              style={{ marginRight: "10px" }}
              id="a_tnc"
              name="a_tnc"
              onChange={(e) => setTnc(e.target.checked)}
              defaultChecked
            />
            <label htmlFor="a_tnc">
              I accept the{" "}
              <a
                style={{ textDecoration: "none" }}
                href="/terms-and-conditions/"
              >
                <span style={{ color: "#0c6baa" }}>Terms & Conditions</span>
              </a>
            </label>
          </div>
          <div className="E_enquireCheckboxWhatsAppConcent">
            <input
              type="checkbox"
              style={{ marginRight: "10px" }}
              id="a_tnc"
              name="a_tnc"
              onChange={(e) => setWhatsAppConcent(!whatsAppConcent)}
              defaultChecked
              checked={whatsAppConcent}
            />
            <label
              htmlFor="a_tnc"
              style={{
                lineHeight: "22px",
              }}
            >
              I agree to get program-related info on WhatsApp & SMS
            </label>
          </div>
          <div className="submit_button">
            <button
              type="submit"
              className="cus-btn user-form-submit form-submit-text enquireFormButton"
              id="request-otp-button"
              style={{}}
              // disabled={isLeadSubmitButtonDisabled}
            >
              Submit
            </button>
          </div>
          <div
            submit-error
            className="form-bottom-alerts form-bottom-alert-error center"
            style={{ marginBottom: "40px" }}
          >
            {error && <p className="error_msg_bottom">{error}</p>}
          </div>
        </form>
        {/* <div className="bottom_color" /> */}
      </div>
    </>
  );
};
export default CreateLead;
