const APP_ENV = process.env.REACT_APP_BUILD_ENV;
const skey =
  "s0OOrcC36C1zSVVbb9J8HgYqQQdrRyR3SteWPSLbpeyvNG8oVgXs3U5fw4QW1JECcOUQPKxZzDWzW6ngWNsHu1vb3CV3hwmXSZFFZWeE28lKDtK20kIrkNmp8O6tMVvrftJNGwOJrGJ7fi3ztlWnzakbaGrvq9oKxH7YKOVSIZokl1dokRbs7MWlNAkW8XfoOWv5ODL7IgC1SN2b7tA2HPPNE4O6I4qIAOO4xpdEITmopMkcur4YRyECA1sb6NUlPvwOXYleqYxSzIzBuxCDuNoiZyqDaF5wbIUZDZRSc82w5Ov8oFIKaHZPRwRu0gKgqaPltaRNEy0SUYwq62vhH3Yau0WL2TvynMel3Ap7vQEW6lY7uoHobtcIhpSxAG8x8XUbAqTCD54Pupni1vP3BhpHvHXbjbCMnK21";

var configs = {
  PATH: "/",
  DOMAIN: "localhost",
  MARKETING_ENDPOINT: "http://localhost:3000/marketing",
  API_ENDPOINT: "http://localhost:3000/marketing",
  DASHBOARD: "http://localhost:3000/eckhomepage/amp_homepage",
  WEBSITE_LINK: "http://localhost:8900",
  DEV_PORT_LINK: "http://localhost:8900",
  skey: skey,
};
if (APP_ENV == "testing") {
  configs = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    MARKETING_ENDPOINT: "https://apiht.pegasust.imarticus.org/marketing",
    API_ENDPOINT: "https://apiht.pegasust.imarticus.org/marketing",
    DASHBOARD: "https://pegasust.imarticus.org/",
    WEBSITE_LINK: "https://d17aspdhv32msc.cloudfront.net",
    DEV_PORT_LINK: "https://previewt.imarticus.org",
    skey: skey,
  };
} else if (APP_ENV == "staging") {
  configs = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    MARKETING_ENDPOINT: "https://apihs.pegasuss.imarticus.org/marketing",
    API_ENDPOINT: "https://apihs.pegasuss.imarticus.org/marketing",
    DASHBOARD: "https://pegasuss.imarticus.org/",
    WEBSITE_LINK: "https://d3ovmnwh385tbf.cloudfront.net",
    DEV_PORT_LINK: "https://previews.imarticus.org",
    skey: skey,
  };
} else if (APP_ENV == "production") {
  configs = {
    PATH: "/",
    DOMAIN: ".imarticus.org",
    MARKETING_ENDPOINT: "https://webhook.pegasus.imarticus.org/marketing",
    API_ENDPOINT: "https://web.pegasus.imarticus.org/marketing",
    DASHBOARD: "https://pegasus.imarticus.org/",
    WEBSITE_LINK: "https://d2chcjz5v00yax.cloudfront.net",
    DEV_PORT_LINK: "https://preview.imarticus.org",
    skey: skey,
  };
}

const dataBase64String = {
  data: "data:image/webp;name=placeholder.webp;base64,UklGRtwAAABXRUJQVlA4TM8AAAAvDUADEAfBOJKkNoMn/xx5+ZcHKQ1GbSNJcvFnNQQGxhC4dl+lto0kZf6j779HjhkXUds28hj0EN34vw7CMWkAf9WzD0gY3t7b3cdf6auJs9hn46+ngf8IHPwVUWBJtiLK54/A/2Oq4KvZvxpwBRCAgIBHAIexbTXRQzeZc9bL9t/mB2qI6L+QgKBIhkiqpxv+eyNzewh8BkkjCVc1QUdZH8DvVRA4e8O5MG5CXP4pgJjsWwKXERV+Nt5BwV6lR626FIOk/ol6kdkeHs65lgQA",
};

export { configs, dataBase64String };
