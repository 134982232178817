import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { uploadImages, createNews, getFolder } from "../services/newsapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";

function MediaUploaderModel(props) {
  console.log(props, "modal call");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [bannerimg, setbannerimg] = useState();
  const [logoimg, setlogoimg] = useState();
  const [data, setData] = useState({
    folder: "",
  });

  //   const submitHandler = async (e) => {
  //     e.preventDefault();
  //     console.log(link, description);
  //     let body = {
  //       description: description.trim(),
  //       link: link,
  //       bannerimage: bannerimg,
  //       logo: logoimg,
  //     };
  //     try {
  //       setLoading(true);
  //       let data = await createNews(body);
  //       if (!data) {
  //         alert("Please fill all the details");
  //         setLoading(false);
  //         return;
  //       }
  //       console.log("====================================");
  //       console.log(data.data, "from edit api");
  //       console.log("====================================");
  //       sahil().then((something) => {
  //         console.log(something, "askjhdfkjhasjkfhkjas");
  //         getOtprequest(
  //           {
  //             Fieldchange: something.data.data.field_name,
  //             Newdata: something.data.data.new_schema,
  //             Olddata: something.data.data.old_schema,
  //           },
  //           something.data.data._id
  //         ).then(() => {
  //           setLoading(false);
  //           alert("Successfully Added");
  //           props.handleClose();
  //           window.open("/Approvalwindow", "_self");
  //         });
  //         // console.log(maildata, ":::::::::::::::;;maildata");
  //       });
  //       // (maildata)
  //       async function sahil() {
  //         return await getApprovalspecificschema(data.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       setLoading(false);
  //       alert(error);
  //     }
  //   };

  let id = "newimageupload";

  const buttonClick = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData({ ...data, [name]: value });
    console.log(data);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    const res = await getFolder(id, data);
    console.log(res, "my response");
    if (res.success === true) {
      alert("Folder Created ");
      setLoading(false);
    } else {
      if (res.status == 612) {
        alert("Folder already present. Please try to rename it");
        setLoading(false);
      } else {
        console.log("in else 2");
        alert("Something Wrong");
        setLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="news_model_container"
      >
        <Modal.Header closeButton> Add Folder</Modal.Header>
        <div className="new_model_div" style={{ padding: "18px" }}>
          <form onSubmit={submitHandler}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1 style={{ fontSize: "18px", opacity: ".75" }}>
                  Enter Folder Name
                </h1>
                <p style={{ fontSize: "14px", color: "red" }}>
                  Word Limit : 3 to 20 characters
                </p>
              </div>
              <input
                type="text"
                name="folder"
                value={data.folder}
                onChange={buttonClick}
                className="form-inpt newsFormInput"
                maxLength={40}
                required
              ></input>
            </div>

            <div className="request_approval">
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Create
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default MediaUploaderModel;
