import React, { useState } from "react";
import Loader from "./loader/loading";
import "../Components/css/createaux.css";
import { createAuxPage } from "../services/seoapproval";

function Createauxpage() {
  const [loading, setLoading] = useState(false);
  const [purl, setPurl] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let pageurl;
      let secondurl;
      if (purl[0] !== "/") {
        pageurl = "/" + purl;
      } else {
        pageurl = purl;
      }
      if (pageurl[pageurl.length - 1] !== "/") {
        secondurl = pageurl + "/";
      } else {
        secondurl = pageurl;
      }

      console.log(secondurl, "purl");
      const data = await createAuxPage(secondurl);
      if (!data) {
        alert("Enter Field is less then the required Validation");
      } else {
        alert("Successfully Added");
        window.open("/AuxiliaryHome", "_self");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Create Aux Page</h1>
        </div>
        <form onSubmit={submitHandler}>
          <div>
            <div>
              <h6 style={{ paddingBottom: "10px" }}>Enter Page Url</h6>
              <input
                placeholder="PageUrl* eg /thank-you"
                className="create_auxinput"
                required
                type="text"
                value={purl}
                onChange={(e) => setPurl(e.target.value)}
                name="purl"
              />
            </div>
          </div>
          <div className="request_approval">
            <button
              type="submit"
              id="edit-submit-btn"
              className="request_approval_btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default Createauxpage;
