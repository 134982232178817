import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import "./css/approval.css";
import Loader from "./loader/loading";
import MyVerticallyCenteredModal from "./Model";
import {
  getOtprequest,
  reject_data,
  getApprovalspecificschema,
} from "../services/approvalapi";

import ViewNewSchemaDetail from "./ViewNewSchemaDetail";
import ViewOldSchemaDetail from "./ViewOldSchemaDetail";

function Approvalspecific() {
  const [propsdata, setPropsdata] = useState("");
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(true);
  const [maildata, setMaildata] = useState({
    Fieldchange: "",
    Newdata: "",
    Olddata: "",
  });
  const [approvalID, setApprovalID] = useState("");
  let { id } = useParams();
  // console.log(id, ":::::::::::::::");

  useEffect(() => {
    sahil().then((something) => {
      setPropsdata(something.data.data);
      setMaildata({
        Fieldchange: something.data.data.field_name,
        Newdata: something.data.data.new_schema,
        Olddata: something.data.data.old_schema,
      });
      setApprovalID(something.data.data._id);
      setLoading(false);
    });
    async function sahil() {
      setLoading(true);
      return await getApprovalspecificschema(id);
    }
  }, []);

  function apicall() {
    setLoading(true);
    setShow(true);
    console.log(maildata);
    setLoading(false);
  }

  let history = useNavigate();

  const rejection = async () => {
    try {
      setLoading(true);
      let data = await reject_data(id);
      setLoading(false);
      alert("Rejection Successful");
      window.open("/Approvalwindow", "_self");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}

      {!loading && (
        <div className="main_container_edit">
          {/* <Model /> */}
          {show && (
            <MyVerticallyCenteredModal
              setLoading={setLoading}
              getOtprequest={getOtprequest}
              maildata={maildata}
              show={show}
              handleClose={handleClose}
              approvalId={approvalID}
              id={propsdata._id}
            />
          )}
          <div>
            <h4>Fields Changed</h4>
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                columnGap: "5px",
                whiteSpace: "nowrap",
              }}
            >
              {propsdata.field_name &&
                propsdata.field_name.map((data, idx) => {
                  return (
                    <p
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        color: "red",
                      }}
                    >
                      {data} {idx == propsdata.field_name.length - 1 ? "" : ","}{" "}
                    </p>
                  );
                })}
            </div>
          </div>
          <div className="main_container_divider_approval">
            <div className="main_container_divid1">
              <div className="change_content">
                <p>Before</p>
              </div>
              <div style={{ marginTop: "10px" }}>
                <ViewOldSchemaDetail propsdata={propsdata} />
              </div>
            </div>

            <div className="main_container_divide2">
              <div className="change_content">
                <p>After</p>
              </div>

              <div style={{ marginTop: "10px" }}>
                <ViewNewSchemaDetail propsdata={propsdata} />
              </div>
            </div>
          </div>
          <div
            className="approvedandrejectbtn"
            style={{ marginBottom: "60px", marginTop: "30px" }}
          >
            <button className="approved" onClick={apicall}>
              Approve
            </button>
            <button className="reject" onClick={rejection}>
              Reject
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Approvalspecific;
