import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { uploadImages, createNews } from "../services/newsapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";

function NewsModel(props) {
  console.log(props, "modal call");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [bannerimg, setbannerimg] = useState();
  const [logoimg, setlogoimg] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(link, description);
    let body = {
      description: description.trim(),
      link: link,
      bannerimage: bannerimg,
      logo: logoimg,
    };
    try {
      setLoading(true);
      let data = await createNews(body);
      if (!data) {
        alert("Please fill all the details");
        setLoading(false);
        return;
      }
      console.log("====================================");
      console.log(data.data, "from edit api");
      console.log("====================================");
      sahil().then((something) => {
        console.log(something, "askjhdfkjhasjkfhkjas");
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        ).then(() => {
          setLoading(false);
          alert("Successfully Added");
          props.handleClose();
          window.open("/Approvalwindow", "_self");
        });
        // console.log(maildata, ":::::::::::::::;;maildata");
      });
      // (maildata)
      async function sahil() {
        return await getApprovalspecificschema(data.data);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  let id = "newimageupload";

  const buttonClick = async (data) => {
    const formdata = new FormData();
    formdata.append("file", data);
    setLoading(true);
    const res = await uploadImages(id, formdata);
    if (res.success === true) {
      setbannerimg(res.data);
      alert("Image Uploaded ");
      setLoading(false);
    } else {
      alert("Image Size Exceeded, It should be under 80KB");
      setLoading(false);
    }
  };

  const buttonClick2 = async (data) => {
    const formdata = new FormData();
    formdata.append("file", data);
    setLoading(true);
    const res = await uploadImages(id, formdata);
    if (res.success === true) {
      setlogoimg(res.data);
      alert("Image Uploaded ");
      setLoading(false);
    } else {
      alert("Image Size Exceeded, It should be under 80KB");
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="news_model_container"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="new_model_div" style={{ padding: "18px" }}>
          <form onSubmit={submitHandler}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1 style={{ fontSize: "18px", opacity: ".75" }}>
                  News Description*
                </h1>
                <p style={{ fontSize: "14px", color: "red" }}>
                  Word Limit : 440 characters
                </p>
              </div>
              <input
                value={description}
                type="text"
                onChange={(e) => setDescription(e.target.value)}
                className="form-inpt newsFormInput"
                name="Description"
                required
                maxLength={440}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: "15px",
              }}
            >
              <h1 style={{ fontSize: "18px", opacity: ".75" }}>News Link*</h1>
              <input
                value={link}
                type="text"
                onChange={(e) => setLink(e.target.value)}
                className="form-inpt newsFormInput"
                name="Url"
                required
                maxLength={200}
              />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                marginBottom: "20px",
                width: "100%",
              }}
              className="program_container_box"
            >
              <h2>New Banner Image Upload*</h2>
              <p style={{ color: "red" }}>
                Recommendation Dimension : 268*145px, Max-size 80KB
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="file"
                  accept="image/*"
                  name="file"
                  onChange={(e) => {
                    buttonClick(e.target.files[0]);
                  }}
                ></input>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                width: "100%",
              }}
              className="program_container_box"
            >
              <h2>New Logo Image Upload*</h2>
              <p style={{ color: "red" }}>
                Recommendation Dimension : 214*48px, Max-size 80KB
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="file"
                  accept="image/*"
                  name="file"
                  onChange={(e) => {
                    buttonClick2(e.target.files[0]);
                  }}
                ></input>
              </div>
            </div>
            <div className="request_approval">
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Add News
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default NewsModel;
