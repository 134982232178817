import React, { useState, useEffect } from "react";
import Loader from "../loader/loading";
import {
  getPgLsKeyAndCost,
  getSubSalesGroup,
  addAdmissionTarget,
} from "../../services/leaddistribution";
import TargetApprovalModel from "../TargetApprovalModel";

function AddAdmissionTarget(props) {
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState("");
  const [subSalesGroup, setSubSalesGroup] = useState();
  const [programAndCost, setProgramAndCost] = useState();
  const [mainTarget, setMainTarget] = useState(null);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const [finalData, setFinalData] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(month, mainTarget);
    let data = {
      date: month,
      target: mainTarget,
    };
    setFinalData(data);

    addAdmissionTarget(data);
    setLoading(false);

    // setShow(true);
  };

  useEffect(() => {
    SalesGroup().then((data) => {
      setSubSalesGroup(data.data);

      GetProgramAndCost().then((data2) => {
        setProgramAndCost(data2.data);

        let tempArr = {};
        for (let i = 0; i < data2.data.length; i++) {
          let temp2 = {};
          for (let j = 0; j < data.data.length; j++) {
            temp2[data.data[j].subSalesGroupName] = 0;
          }
          tempArr[data2.data[i].leadsquareProgramKey] = temp2;
        }

        setMainTarget(tempArr);
        setLoading(false);
      });

      async function GetProgramAndCost() {
        setLoading(true);
        return await getPgLsKeyAndCost();
      }

      setLoading(false);
    });

    async function SalesGroup() {
      setLoading(true);
      return await getSubSalesGroup();
    }
  }, []);

  const setTargetForSles = (lskey, salesGroup, e) => {
    console.log(lskey, salesGroup, e.target.value);
    let tempMainTarget = { ...mainTarget };
    tempMainTarget[lskey][salesGroup] = e.target.value;
    setMainTarget(tempMainTarget);
  };

  return (
    <>
      {loading && <Loader />}
      {show && (
        <TargetApprovalModel
          setLoading={setLoading}
          show={show}
          handleClose={handleClose}
          approvalId={""}
          id={""}
          data={finalData}
          approvalApi={""}
        />
      )}
      <div>
        <form onSubmit={submitHandler}>
          <div className="addprogram2container">
            <div>
              <p className="addadmissiontargetmonth">Select Month</p>
              <div>
                <input
                  className="addadmissiontargetmonthdropdown"
                  type="month"
                  id="birthday"
                  name="birthday"
                  value={month}
                  required={true}
                  onChange={(e) => setMonth(e.target.value)}
                />
              </div>
            </div>

            {/* table matrix */}
            <div className="addadmissiontargettablemain">
              <div
                className=""
                style={{ overflow: "scroll", width: "100%", height: "100%" }}
              >
                <div style={{ width: "7200px" }}>
                  <table className="table table-striped groupleadtable">
                    <thead>
                      <tr className="">
                        <th
                          scope="col"
                          style={{ width: "300px", padding: "0px 8px" }}
                        >
                          Sub Sales Group
                        </th>
                        {programAndCost &&
                          programAndCost.map((data) => {
                            return (
                              <th scope="col" style={{ width: "184px" }}>
                                {data.leadsquareProgramKey}
                              </th>
                            );
                          })}
                      </tr>
                      {subSalesGroup &&
                        subSalesGroup.map((data) => {
                          return (
                            <tr style={{ backgroundColor: "#fff" }}>
                              <th
                                scope="col"
                                style={{ width: "300px", textWrap: "norap" }}
                              >
                                {data.subSalesGroupName}
                              </th>
                              {mainTarget &&
                                programAndCost &&
                                programAndCost.map((data2) => {
                                  return (
                                    <td
                                      style={{ borderRight: "1px solid #ddd" }}
                                    >
                                      <div style={{ padding: "0px 8px" }}>
                                        <div>
                                          <p style={{ paddingBottom: "4px" }}>
                                            Target Revenue
                                          </p>
                                          <input
                                            disabled
                                            type="text"
                                            value={
                                              mainTarget[
                                                data2.leadsquareProgramKey
                                              ][data.subSalesGroupName] *
                                              (data2.programCost || 0)
                                            }
                                          />
                                        </div>
                                        <div style={{ paddingTop: "8px" }}>
                                          <p style={{ paddingBottom: "4px" }}>
                                            Admission Target
                                          </p>
                                          <input
                                            type="text"
                                            onChange={(e) =>
                                              setTargetForSles(
                                                data2.leadsquareProgramKey,
                                                data.subSalesGroupName,
                                                e
                                              )
                                            }
                                            value={
                                              mainTarget[
                                                data2.leadsquareProgramKey
                                              ][data.subSalesGroupName]
                                            }
                                          />
                                        </div>
                                      </div>
                                    </td>
                                  );
                                })}
                            </tr>
                          );
                        })}
                    </thead>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="addprogrambtns">
            <button onClick={props.close} className="addprogramcancelbtn">
              Cancel
            </button>
            <button
              onClick={() => submitHandler}
              className="addprogramsubmitbtn"
            >
              Done
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddAdmissionTarget;
