import React from "react";
import Cookies from "universal-cookie";
import configs from "../constants";
import Loader from "./loader/loading";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getSeopageSchema } from "../services/seoapproval";
import "./css/home.css";
import moment from "moment";

function AuxiliaryHome() {
  const cookies = new Cookies();
  useEffect(() => {
    seoData().then((data) => {
      setPropsdata(data.data.data);
      setLoading(false);
    });

    async function seoData() {
      setLoading(true);
      return await getSeopageSchema();
    }
  }, []);

  const [propsdata, setPropsdata] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid" style={{ height: "100vh" }}>
        <div className="row" style={{ height: "100%" }}>
          <div className="home_container">
            <div className="home_title_div">
              <h1 className="home_title">Auxiliary Pages</h1>
            </div>
            <div className="home_table">
              <table className="table table-striped">
                <thead>
                  <tr className="uper_table_container">
                    <th
                      scope="col"
                      style={{ width: "28%", paddingRight: "10px" }}
                    >
                      Auxiliary Page
                    </th>
                    <th scope="col">Last Change Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {propsdata.length > 0 &&
                    propsdata.map((data, idx) => {
                      return (
                        <tr className="uper_table_container" key={idx}>
                          <th scope="row" style={{ paddingRight: "20px" }}>
                            {data.pageUrl}
                          </th>
                          <th>
                            {moment(data.updatedAt).format(
                              "DD/MM/YYYY, h:mm:ss a"
                            )}
                          </th>
                          <th>
                            <Link
                              style={{ textDecoration: "none", color: "#fff" }}
                              to={`/Viewseodetail${data._id}`}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                alt="edit icon"
                                height="20px"
                                width="20px"
                              />
                            </Link>
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AuxiliaryHome;
