import React, { useEffect, useState } from "react";
import Loader from "../loader/loading";
import {
  addSubSalesGroup,
  getSalesGroup,
} from "../../services/leaddistribution";

function AddSubSalesGroup(props) {
  const [subSalesGroup, setSubSalesGroup] = useState("");
  const [salesGroup, setSalesGroup] = useState("");
  const [manager, setManager] = useState("");
  const [loading, setLoading] = useState();
  const [dropdownData, setDropdownData] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let d = {
      email: manager,
      salesGroupId: salesGroup,
      subSalesGroupName: subSalesGroup,
    };

    let response = await addSubSalesGroup(d);

    if (response.status === 200) {
      alert(response.data.data);
    } else if (response.status === 420) {
      alert(response.data.message);
    } else alert("Something went wrong");
    setLoading(false);
  };

  useEffect(() => {
    getSalesGroupName().then((data) => {
      console.log(data);
      setDropdownData(data.data);
      setLoading(false);
    });

    async function getSalesGroupName() {
      setLoading(true);
      return await getSalesGroup();
    }
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div>
        <form onSubmit={submitHandler}>
          <div className="addprogram2container">
            <div>
              <h3 className="addprogramheading">
                Enter Sub-sales Group Name (20 characters limit max)
              </h3>
              <div>
                <input
                  value={subSalesGroup}
                  className="addprograminput"
                  placeholder="abc"
                  type="text"
                  id="target"
                  required
                  onChange={(e) => setSubSalesGroup(e.target.value)}
                />
              </div>
            </div>

            <div>
              <h3 className="addprogramheading">Add Manager</h3>
              <div>
                <input
                  value={manager}
                  className="addprograminput"
                  placeholder="abc@example.com"
                  type="text"
                  id="target"
                  required
                  onChange={(e) => setManager(e.target.value)}
                />
              </div>
            </div>

            <div>
              <h3 className="addprogramheading">Select Sales Group</h3>
              <div>
                <select
                  value={salesGroup}
                  onChange={(e) => setSalesGroup(e.target.value)}
                  required
                  className="addprograminput"
                  name="cars"
                  id="cars"
                >
                  <option disabled selected value="">
                    Select Sales Group
                  </option>
                  {dropdownData &&
                    dropdownData.map((data) => {
                      return (
                        <option value={data._id}>{data.salesGroupName}</option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="addprogrambtns">
            <button onClick={props.close} className="addprogramcancelbtn">
              Cancel
            </button>
            <button
              onClick={() => submitHandler}
              className="addprogramsubmitbtn"
            >
              Done
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddSubSalesGroup;
