import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";

import {
  getFilterGoogleCapiEventsData,
  getGoogleCapiEventsData,
} from "../services/leadsquareapi";
import { Search } from "@mui/icons-material";

function GoogleCapiDashboard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [leaddata, setleadData] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [filterData, setFilterData] = useState({
    email: "",
    contactNumber: "",
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChangeFilterData = (event) => {
    setFilterData((pre) => {
      return { ...pre, [event.target.name]: event.target.value };
    });
  };

  const handleSearchClick = () => {
    filterleadData().then((data) => {
      setleadData(data.data.data);
      setLoading(false);
    });

    async function filterleadData() {
      setLoading(true);
      const data = {
        isGoogleSync: isChecked,
        email: filterData.email,
        contactNumber: filterData.contactNumber,
      };

      return await getFilterGoogleCapiEventsData(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    leadData().then((data) => {
      setleadData(data.data.data);
      console.log(data.data.data);
      setLoading(false);
    });

    async function leadData() {
      setLoading(true);
      return await getGoogleCapiEventsData();
    }
    //to get the array to be displayed
  }, []);

  return (
    <>
      <div className="home_title_div">
        <h1 className="home_title">Google Capi Dashboard </h1>
      </div>
      <div className="filter_container">
        <h3 className="filter_title">Filter By :</h3>
        <div className="filter_field">
          <label>Email Id : </label>
          <input
            type="text"
            name="email"
            value={filterData.email}
            onChange={handleChangeFilterData}
          />
        </div>
        <div className="filter_field">
          <label>Contact No. : </label>
          <input
            type="number"
            name="contactNumber"
            value={filterData.contactNumber}
            onChange={handleChangeFilterData}
          />
        </div>
        <div className="filter_field">
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            Google Synced
          </label>
        </div>
        <div className="filter_search" onClick={handleSearchClick}>
          Search
        </div>
      </div>
      <TableContainer className="mainTable">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className="leadtablefont">
              <TableCell>_id </TableCell>
              <TableCell>name </TableCell>
              <TableCell>email </TableCell>
              <TableCell>cc </TableCell>
              <TableCell>phone </TableCell>
              <TableCell>aid </TableCell>
              <TableCell>pid </TableCell>
              <TableCell>state </TableCell>
              <TableCell>city </TableCell>
              <TableCell>country </TableCell>
              <TableCell>name_hash </TableCell>
              <TableCell>email_hash </TableCell>
              <TableCell>cc_hash</TableCell>
              <TableCell>cc_ph_hash</TableCell>
              <TableCell>googleSync</TableCell>
              <TableCell>googleSyncRetry</TableCell>
              {/* <TableCell>{JSON.stringify(row.googleAdsResponse)  || '--'}</TableCell> */}
              <TableCell>act</TableCell>
              <TableCell>gclid</TableCell>
              <TableCell>row.customData</TableCell>
              <TableCell>row.customData</TableCell>
              <TableCell>conversionDateTime</TableCell>
              <TableCell>conversionValue</TableCell>
              <TableCell>currencyCode</TableCell>
              <TableCell>conversionActionId</TableCell>
              <TableCell>createdAt</TableCell>
              <TableCell>updatedAt</TableCell>
              <TableCell>googleSyncAt </TableCell>
              <TableCell>jobId</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : leaddata?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {leaddata.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row.index}>
                      <TableCell>{row._id || "--"}</TableCell>
                      <TableCell>{row.name || "--"}</TableCell>
                      <TableCell>{row.email || "--"}</TableCell>
                      <TableCell>{row.cc || "--"}</TableCell>
                      <TableCell>{row.phone || "--"}</TableCell>
                      <TableCell>{row.aid || "--"}</TableCell>
                      <TableCell>{row.pid || "--"}</TableCell>
                      <TableCell>{row.state || "--"}</TableCell>
                      <TableCell>{row.city || "--"}</TableCell>
                      <TableCell>{row.country || "--"}</TableCell>
                      <TableCell>{row.name_hash || "--"}</TableCell>
                      <TableCell>{row.email_hash || "--"}</TableCell>
                      <TableCell>{row.cc_hash || "--"}</TableCell>
                      <TableCell>{row.cc_ph_hash || "--"}</TableCell>
                      <TableCell>{row.googleSync ? "true" : "false"}</TableCell>
                      <TableCell>{row.googleSyncRetry || "--"}</TableCell>
                      {/* <TableCell>{JSON.stringify(row.googleAdsResponse)  || '--'}</TableCell> */}
                      <TableCell>
                        {row.act ? "true" : "false" || "--"}
                      </TableCell>
                      <TableCell>{row.gclid || "--"}</TableCell>
                      <TableCell>
                        {JSON.stringify(row.customData) || "--"}
                      </TableCell>
                      <TableCell>
                        {JSON.stringify(row.customData) || "--"}
                      </TableCell>
                      <TableCell>{row.conversionDateTime || "--"}</TableCell>
                      <TableCell>{row.conversionValue || "--"}</TableCell>
                      <TableCell>{row.currencyCode || "--"}</TableCell>
                      <TableCell>{row.conversionActionId || "--"}</TableCell>
                      <TableCell>{row.createdAt || "--"}</TableCell>
                      <TableCell>{row.updatedAt || "--"}</TableCell>
                      <TableCell>{row.googleSyncAt || "--"}</TableCell>
                      <TableCell>{row.jobId || "--"}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default GoogleCapiDashboard;
