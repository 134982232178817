import React, { useEffect, useState } from "react";
import Loader from "./loader/loading";
import "../Components/css/haptikProgramNameAutomation.css";
import {
  currentHaptikProgram,
  getServerSideProps,
} from "../services/marketinggetapi";
import { SendExperienceData } from "../services/marketingapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";

function HaptikProgramNameAutomation() {
  const [loading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({
    programName: "",
    leadsquareProgramKey: "",
  });
  const [currentCourses, setcurrentCourses] = useState([]);
  const [displaycurrentCourses, setDisplaycurrentCourses] = useState([]);
  const [experience, setExperience] = useState("");
  const [qualification, setQualification] = useState("");
  const [courses, setCourse] = useState([]);
  const [hightestQualification, setHightestQualification] = useState([]);
  const [professionalExperience, setProfessionalExperience] = useState([]);
  const [isSelectBoxSelected, setSelectBoxSelected] = useState({
    qualification: false,
    experience: false,
  });
  useEffect(() => {
    GetCurrentHaptikProgram().then((something) => {
      setcurrentCourses(something);
      const Qualification = new Set();
      const ProfessionaExperience = new Set();
      something.map((data) => Qualification.add(data.mx_H_Qualification));
      something.map((data) => ProfessionaExperience.add(data.mx_Experience));
      Qualification.forEach((data) => {
        // console.log(...hightestQualification, data)
        setHightestQualification((prevState) => [...prevState, data]);
      });
      ProfessionaExperience.forEach((data) => {
        // console.log(...hightestQualification, data)
        setProfessionalExperience((prevState) => [...prevState, data]);
      });
    });
    async function GetCurrentHaptikProgram() {
      setLoading(true);
      const res = await currentHaptikProgram();
      // console.log("this is data", res.props.propsdata.Data)
      return res.props.propsdata.Data;
    }
  }, []);

  useEffect(() => {
    getCourse().then((something) => {
      setCourse(something.data.allData);
      setLoading(false);
      // console.log(something.data.allData);
    });
    async function getCourse() {
      setLoading(true);
      return await getServerSideProps();
    }
  }, []);

  useEffect(() => {
    const mydata = currentCourses.filter(
      (data) =>
        data.mx_Experience == experience &&
        data.mx_H_Qualification == qualification
    );
    setDisplaycurrentCourses(mydata);
  }, [currentCourses, experience, qualification]);
  const submitHandler = async () => {
    setLoading(true);
    console.log({ selectedProgram });
    // return;
    if (experience.length == 0) {
      alert("Please Select Experience.");
      setLoading(false);
    } else if (qualification.length == 0) {
      alert("Please Select Qualification.");
      setLoading(false);
    } else if (selectedProgram.leadsquareProgramKey.length == 0) {
      alert("Please Select Program.");
      setLoading(false);
    } else {
      // alert("inside else block.")
      setLoading(false);
      const data = await SendExperienceData({
        mx_Experience: experience,
        mx_H_Qualification: qualification,
        programName: selectedProgram.programName,
        leadsquareProgramKey: selectedProgram.leadsquareProgramKey,
      });

      getApprovalAndGetOtps().then((values) => {
        // console.log(values,"this is my data");
        for (let val in values) {
          getOtprequest(
            {
              Fieldchange: values[val].data.data.field_name,
              Newdata: values[val].data.data.new_schema,
              Olddata: values[val].data.data.old_schema,
            },
            values[val].data.data._id
          );
        }
        // console.log(maildata, ":::::::::::::::;;maildata");
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });
      // (maildata)

      async function getApprovalAndGetOtps() {
        const mainData = data.data.data;
        // console.log({mainData});
        const dataForOtps = [];
        for (let index in mainData) {
          const response = await getApprovalspecificschema(mainData[index]._id);
          dataForOtps.push(response);
        }
        return dataForOtps;
      }
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div
          className="container-fluid"
          style={{ overflowX: "hidden", paddingBottom: "30px" }}
        >
          <div className="row" style={{ height: "100%" }}>
            <div className="home_container">
              <div className="home_title_div">
                <h1 className="home_title">
                  Program Name based on Experience and Qualification for Haptik
                  Bot
                </h1>
              </div>
              <div>
                <div className="checkboxAndExperienceHolder">
                  <div>
                    <h6 style={{ "padding-bottom": "10px" }}>
                      Select Experience
                    </h6>
                    <div className="ProgramCheckbox">
                      <select
                        onChange={(e) => {
                          setExperience(e.target.value);
                          setSelectBoxSelected({
                            ...isSelectBoxSelected,
                            experience: true,
                          });
                        }}
                      >
                        <option disabled selected>
                          ------Select Experience-------
                        </option>
                        {professionalExperience.map((data, idx) => {
                          return (
                            <option key={idx} value={data}>
                              {data}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div>
                    <h6 style={{ "padding-bottom": "10px" }}>
                      Select Qualification
                    </h6>
                    <div className="ProgramCheckbox">
                      <select
                        onChange={(e) => {
                          setQualification(e.target.value);
                          setSelectBoxSelected({
                            ...isSelectBoxSelected,
                            qualification: true,
                          });
                        }}
                      >
                        <option disabled selected>
                          ------Select Qualification-------
                        </option>
                        {hightestQualification.map((data, idx) => {
                          return (
                            <option key={idx} value={data}>
                              {data}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                {/* {JSON.stringify(currentCourses)} */}
                {displaycurrentCourses.length > -1 &&
                  displaycurrentCourses.map((data, index) => {
                    return (
                      <div className="changeProgramContainer" key={index}>
                        <h6 style={{ "padding-bottom": "10px" }}>
                          Current Program For Selected Experience And
                          Qualification
                        </h6>
                        <h5 style={{ "padding-bottom": "10px" }}>
                          {data.programName}
                        </h5>
                        <div className="ProgramCheckbox">
                          <select
                            onChange={(e) =>
                              setSelectedProgram(JSON.parse(e.target.value))
                            }
                          >
                            <option disabled selected>
                              ------Select program-------
                            </option>
                            {courses.length > 0 &&
                              courses.map((data, idx) => {
                                return (
                                  <option
                                    key={idx}
                                    value={JSON.stringify({
                                      leadsquareProgramKey:
                                        data.leadsquareProgramKey,
                                      programName: data.programTitle,
                                    })}
                                  >
                                    {data.programTitle}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <div className="submitButton" onClick={submitHandler}>
                          Submit
                        </div>
                      </div>
                    );
                  })}
                {displaycurrentCourses.length == 0 &&
                  (isSelectBoxSelected.experience &&
                    isSelectBoxSelected.qualification) == true && (
                    <h4 style={{ padding: "20px 0px" }}>
                      No Data Found For selected Qualification And Experience
                    </h4>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HaptikProgramNameAutomation;
