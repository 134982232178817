import React, { useState, useEffect } from "react";
import Loader from "./loader/loading";
import {
  getPgLsKeyAndCost,
  getTagets,
  getSubSalesGroup,
} from "../services/leaddistribution";
import { convertUtcToIst, getSubSalesGroupName } from "../services/helper";

function LeadDistributionTarget(props) {
  const [dropdownProgram, setDropdownPrograms] = useState("");
  const [program, setProgram] = useState("");
  const [date, setDate] = useState("");
  const [currData, setCurrData] = useState(null);
  const [prevData, setPrevData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [subSalesGroup, setSubSalesGroup] = useState();

  useEffect(() => {
    getRevenueProgram().then((data) => {
      console.log(data);
      setDropdownPrograms(data.data);
    });

    async function getRevenueProgram() {
      return await getPgLsKeyAndCost();
    }

    getSubSalesGroupName().then((data) => {
      console.log(data);
      setSubSalesGroup(data.data);
      setLoading(false);
    });

    async function getSubSalesGroupName() {
      setLoading(true);
      return await getSubSalesGroup();
    }
  }, []);

  const submitHandler = async (e) => {
    if (date == "" || program == "") {
      alert("Please select the Date and Program ");
      return;
    }
    e.preventDefault();
    setLoading(true);
    console.log(date, program);

    let data = await getTagets(date, program);
    data = data.data;
    console.log(data);

    if (data) {
      setCurrData(data.currData);
      setPrevData(data.prevData);
    }
    setLoading(false);
  };

  console.log({ prevData }, { currData });

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Lead Target</h1>
        </div>

        <div>
          <div className="timetablemaincontainer">
            <div className="timetablecontainer">
              <p>Month </p>
              <div className="timetabletimecontainer">
                <div>
                  <input
                    className="timeleadtabledrop"
                    type="month"
                    id="monthyear"
                    name="monthyear"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="timetablecontainer">
              <p>Program</p>
              <div>
                <select
                  className="timeleadtabledrop"
                  onChange={(e) => {
                    console.log(e.target.value, "program");
                    setProgram(e.target.value);
                  }}
                  value={program}
                  name="program"
                  id="program"
                >
                  <option disabled selected value="">
                    Program
                  </option>
                  {dropdownProgram &&
                    dropdownProgram.map((data) => {
                      return (
                        <option
                          style={{ color: "black", background: "#ffff" }}
                          value={`${data.leadsquareProgramKey}`}
                        >
                          {data.leadsquareProgramKey}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div>
              <button
                style={{
                  height: "42px",
                  width: "130px",
                  border: "none",
                  borderRadius: "4px",
                  color: "#fff",
                  background: "#055646",
                  fontWeight: "600",
                }}
                type="button"
                onClick={(e) => submitHandler(e)}
              >
                Search
              </button>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "36px",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: "50%",
              paddingRight: "40px",
              borderRight: "1px solid grey",
            }}
          >
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#3c4852",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Current
            </h1>

            <div>
              {currData && currData.length > 0 ? (
                <>
                  {currData &&
                    currData.map((data1, i) => {
                      return (
                        <div key={i}>
                          <h4
                            style={{ marginBottom: "20px", fontSize: "18px" }}
                          >
                            Time: {convertUtcToIst(data1.updatedAt)}
                          </h4>

                          <div
                            style={{
                              display: "flex",
                              borderBottom: "1px solid black",
                              justifyContent: "space-between",
                              marginBottom: "24px",
                            }}
                          >
                            <h4 style={{ fontSize: "18px" }}>
                              Sales Head Email
                            </h4>
                            <h4 style={{ fontSize: "18px" }}>Target</h4>
                          </div>
                          {data1 &&
                            data1.target.map((data2, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid black",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <h4 style={{ fontSize: "18px" }}>
                                    {getSubSalesGroupName(
                                      subSalesGroup,
                                      data2.subSalesGroup
                                    )}
                                  </h4>
                                  <h4 style={{ fontSize: "18px" }}>
                                    {data2.admissionTarget}
                                  </h4>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <h4
                    style={{
                      fontSize: "18px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    No Current data found
                  </h4>
                </>
              )}
            </div>
          </div>
          <div style={{ width: "50%", paddingLeft: "40px" }}>
            <h1
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: "#3c4852",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Previous
            </h1>

            <div>
              {prevData && prevData.length > 0 ? (
                <>
                  {prevData &&
                    prevData.map((data1, i) => {
                      return (
                        <div key={i} style={{ marginBottom: "40px" }}>
                          <h4
                            style={{ marginBottom: "20px", fontSize: "18px" }}
                          >
                            Time: {convertUtcToIst(data1.updatedAt)}
                          </h4>

                          <div
                            style={{
                              display: "flex",
                              borderBottom: "1px solid black",
                              justifyContent: "space-between",
                              marginBottom: "24px",
                            }}
                          >
                            <h4 style={{ fontSize: "18px" }}>
                              Sales Head Email
                            </h4>
                            <h4 style={{ fontSize: "18px" }}>Target</h4>
                          </div>
                          {data1 &&
                            data1.target.map((data2, i) => {
                              return (
                                <div
                                  key={i}
                                  style={{
                                    display: "flex",
                                    borderBottom: "1px solid black",
                                    justifyContent: "space-between",
                                    marginBottom: "16px",
                                  }}
                                >
                                  <h4 style={{ fontSize: "18px" }}>
                                    {getSubSalesGroupName(
                                      subSalesGroup,
                                      data2.subSalesGroup
                                    )}
                                  </h4>
                                  <h4 style={{ fontSize: "18px" }}>
                                    {data2.admissionTarget}
                                  </h4>
                                </div>
                              );
                            })}
                        </div>
                      );
                    })}
                </>
              ) : (
                <>
                  <h4
                    style={{
                      fontSize: "18px",
                      marginTop: "20px",
                      textAlign: "center",
                    }}
                  >
                    No previous data found
                  </h4>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadDistributionTarget;
