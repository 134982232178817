import React, { useState } from "react";
import Loader from "./loader/loading";
import { CreateLocationApi } from "../services/locationapi";

function CreateLocation() {
  const [loading, setLoading] = useState(false);
  const [locationName, setLocationname] = useState("");

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const data = await CreateLocationApi(locationName.trim());
      if (!data) {
        alert("Enter Field is less then the required Validation");
      } else {
        alert("Successfully Added");
        window.open("/LocationHome", "_self");
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Create Location Page</h1>
        </div>
        <form onSubmit={submitHandler}>
          <div>
            <div>
              <h6 style={{ paddingBottom: "10px" }}>Enter Location</h6>
              <input
                placeholder="Location* eg Chennai"
                className="create_auxinput"
                required
                type="text"
                value={locationName}
                onChange={(e) => setLocationname(e.target.value)}
                name="purl"
              />
            </div>
          </div>
          <div className="request_approval">
            <button
              type="submit"
              id="edit-submit-btn"
              className="request_approval_btn"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default CreateLocation;
