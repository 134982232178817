import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";

import { getFbLead } from "../services/leadsquareapi";

function FbLeadDashboard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [leaddata, setleadData] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    leadData().then((data) => {
      setleadData(data.data);
      console.log(data.data);
      setLoading(false);
    });

    async function leadData() {
      setLoading(true);
      return await getFbLead();
    }
    //to get the array to be displayed
  }, []);

  const convertUnixToHumanReadable = (time) => {
    const dateObject = new Date(time * 1000); // Convert seconds to milliseconds
    const humanReadableTime = dateObject.toLocaleString(); // Format the date and time

    return humanReadableTime;
  };

  return (
    <>
      <TableContainer className="mainTable">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className="leadtablefont">
              <TableCell>Created Time</TableCell>
              <TableCell>Fb Lead Gen Id</TableCell>
              <TableCell>Page Id</TableCell>
              <TableCell>Form Id</TableCell>
              <TableCell>Lead Table Id</TableCell>
              <TableCell>Campaign</TableCell>
              <TableCell>Page</TableCell>
              <TableCell>Form</TableCell>
              <TableCell>Ad Name</TableCell>
              <TableCell>Ad Set</TableCell>
              <TableCell>Ad ID</TableCell>
              <TableCell>Ad Group ID</TableCell>
              <TableCell>Campaign ID</TableCell>
              <TableCell>Home Listing</TableCell>
              <TableCell>Platform</TableCell>
              <TableCell>Lead Retrive</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : leaddata?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {leaddata.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row.index}>
                      <TableCell style={{ width: "250px" }}>
                        {convertUnixToHumanReadable(row.createdTime) || "--"}
                      </TableCell>
                      <TableCell>{row.leadgenId || "--"}</TableCell>
                      <TableCell>{row.pageId || "--"}</TableCell>
                      <TableCell>{row.formId || "--"}</TableCell>
                      <TableCell>{row.leadTableId || "--"}</TableCell>
                      <TableCell>{row.campaign || "--"}</TableCell>
                      <TableCell>{row.page || "--"}</TableCell>
                      <TableCell>{row.form || "--"}</TableCell>
                      <TableCell>{row.adName || "--"}</TableCell>
                      <TableCell>{row.adSet || "--"}</TableCell>
                      <TableCell>{row.adID || "--"}</TableCell>
                      <TableCell>{row.adGroupID || "--"}</TableCell>
                      <TableCell>{row.campaignID || "--"}</TableCell>
                      <TableCell>{row.homeListing || "--"}</TableCell>
                      <TableCell>{row.platform || "--"}</TableCell>
                      <TableCell>
                        {row.leadRetrive ? "true" : "false"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default FbLeadDashboard;
