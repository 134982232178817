import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, Modal } from "react-bootstrap";
import "./css/approval.css";

function BootstrapModal(props) {
  // const [show, setShow] = useState(false);

  const handleChange = (event) => {
    props.setMailToSentTo(event.target.value);
  };

  return (
    <>
      <Modal
        show={props.showConfirmationModel}
        onHide={props.handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p className="prod_confirmation_text">
            Are you sure you want to deploy your changes on Production?
            <br />
            <br />
            Choose a user for verification.
          </p>
          <div className="prod_confirmation_form">
            <select value={props.mailToSentTo} onChange={handleChange}>
              <option value={""}>Select an option</option>
              {props.mailUser.map((user, index) => {
                return (
                  <option key={index} value={user}>
                    {user}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="prod_btn_grup">
            <div className="prod_cancel_btn" onClick={props.handleClose}>
              Close
            </div>
            <div
              className="prod_confirmation_btn"
              onClick={props.handleSuccess}
            >
              Yes Proceed
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default BootstrapModal;
