import React, { useState, useEffect } from "react";
import "../Components/css/leaddistribution.css";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Loader from "./loader/loading";
import Pagination2 from "./Pagination2";
import LeadDistributionModel from "./LeadDistribution/LeadDistributionModel";
import { Link, useNavigate } from "react-router-dom";
import {
  getSubSalesGroup,
  getPls,
  getLeads,
  getAggregateData,
  exportLeadsToCsv,
} from "../services/leaddistribution";
import moment from "moment";
import {
  getSalesGroupEmail,
  getSalesGroupName,
  getSubSalesGroupName,
  getSubSalesGroupEmail,
  getAdvisorEmail,
  getParameterByName,
  convertUtcToIst,
  convertUnixTimeToIst,
} from "../services/helper";
import { Skeleton } from "@mui/material";
import { configs } from "../constants";
import ExportLead from "./LeadDistribution/ExportLead";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { teal } from "@mui/material/colors";
const label = { inputProps: { "aria-label": "Switch demo" } };
const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: teal[600],
    "&:hover": {
      backgroundColor: alpha(teal[600], theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: teal[600],
  },
}));

const PER_PAGE = 10;

function LeadDistributionPanel(props) {
  let navigate = useNavigate();

  let URL = window.location.href;
  let DATE = new Date();

  DATE.setMonth(DATE.getMonth() - 2);
  var minDate = DATE.toISOString().slice(0, 10);

  console.log(URL);
  const [todayDate, setTodayDate] = useState(DATE);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPAge] = useState(0);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [subSalesGroup, setSubSalesGroup] = useState("");
  const [lsplsArray, setLsplsArray] = useState();
  const [subSalesGroupArray, setSubSalesGroupArray] = useState();
  const [leads, setLeads] = useState();
  const [salesGroup, setSalesGroup] = useState();
  const [program, setProgram] = useState();
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [loadingLeadTableData, setLoadingLeadTableData] = useState(true);
  const [aggregateData, setAggregateData] = useState();
  const [finalAggregateData, setFinalAggregateData] = useState();
  const [leadExportModel, setLeadExportModel] = useState(false);
  const [isFirst, setIsFirst] = useState(true);
  const [isFirstString, setIsFirstString] = useState("true");
  const [firstRender, setFirstRender] = useState(false);

  const open = Boolean(anchorEl);

  const [modelShow, setModelShow] = useState({ Boolean: true, String: null });

  useEffect(() => {
    let today = new Date();
    let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    let formatDate = (date) => {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    console.log("Start of the month:", formatDate(startOfMonth));
    console.log("Today's date:", formatDate(today));

    // setFromDate(formatDate(startOfMonth));
    // setToDate(formatDate(today));

    let fromDate = getParameterByName("fromDate") || formatDate(startOfMonth);
    let toDate = getParameterByName("toDate") || formatDate(today);
    let subSalesGroup = getParameterByName("subSalesGroup");
    let leadSource = getParameterByName("leadSource");

    setFromDate(fromDate);
    setToDate(toDate);
    setSubSalesGroup(subSalesGroup);
    setLeadSource(leadSource);

    getSubSalesGroups().then((data) => {
      setSubSalesGroupArray(data.data);
      setLoading(false);
    });

    async function getSubSalesGroups() {
      setLoading(true);
      return await getSubSalesGroup();
    }

    getlspls().then((data) => {
      setLsplsArray(data.data);
      setLoading(false);
    });

    async function getlspls() {
      setLoading(true);
      return await getPls();
    }

    getLeadss().then((data) => {
      setLeads(data.data);
      setLoading(false);
      setSize(data.data.count);
      setLoadingLeadTableData(false);
    });

    async function getLeadss() {
      setLoading(true);
      setLoadingLeadTableData(true);
      return await getLeads(
        fromDate,
        toDate,
        "",
        "",
        "",
        "",
        currentPage,
        PER_PAGE,
        isFirst
      );
    }

    getAggregate().then((data) => {
      console.log(data);
      setAggregateData(data.data);
      setFinalAggregateData(data.data);
      setLoadingTableData(false);
    });
    async function getAggregate() {
      setLoadingTableData(true);

      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
      let formattedDate = year + "-" + month;
      let formDate = formattedDate + "-" + "01";
      let toDate =
        formattedDate + "-" + ("0" + currentDate.getDate()).slice(-2);
      console.log(formattedDate);
      return await getAggregateData(formDate, toDate, isFirst);
    }
  }, []);

  useEffect(() => {
    console.log(program);
    console.log(salesGroup);
    console.log(leadSource);
    console.log(subSalesGroup);
    console.log(fromDate);
    console.log(toDate);

    if (
      (program ||
        salesGroup ||
        fromDate ||
        toDate ||
        subSalesGroup ||
        leadSource ||
        isFirstString) &&
      firstRender
    ) {
      getLeadss().then((data) => {
        setLeads(data.data);
        setLoading(false);
        setSize(data.data.count);
      });

      async function getLeadss() {
        setLoading(true);
        return await getLeads(
          fromDate,
          toDate,
          salesGroup,
          program,
          leadSource,
          subSalesGroup,
          currentPage,
          PER_PAGE,
          isFirst
        );
      }
      window.history.pushState(
        {},
        "",
        `?program=${encodeURIComponent(
          program
        )}&salesGroup=${salesGroup}&fromDate=${fromDate}&toDate=${toDate}&subSalesGroup=${subSalesGroup}&leadSource=${leadSource}`
      );
    }
  }, [
    subSalesGroup,
    fromDate,
    toDate,
    leadSource,
    program,
    salesGroup,
    isFirst,
  ]);

  useEffect(() => {
    setSalesGroup(props?.leadDistubtionData?.salesGroup);
    setProgram(props?.leadDistubtionData?.program);
  }, [
    props?.leadDistubtionData?.salesGroup,
    props?.leadDistubtionData?.program,
  ]);

  useEffect(() => {
    if ((fromDate || toDate) && firstRender) {
      let currentDate = new Date();
      let year = currentDate.getFullYear();
      let month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      let formattedDate = year + "-" + month;
      let currentFromDate = fromDate || formattedDate + "-" + "01";
      let currentToDate = toDate || formattedDate + "-" + currentDate.getDate();

      getAggregate().then((data) => {
        console.log(data);
        setAggregateData(data.data);
        if (props?.leadDistubtionData?.salesGroup) {
          let apiData = data.data;
          let filterData = apiData.filter(
            (d) => d.salesGroupId == props.leadDistubtionData.salesGroup
          );
          setFinalAggregateData(filterData);
          setLoadingTableData(false);
        } else {
          setFinalAggregateData(data.data);
          setLoadingTableData(false);
        }
      });
      async function getAggregate() {
        setLoadingTableData(true);

        console.log({ fromDate });
        return await getAggregateData(currentFromDate, currentToDate, isFirst);
      }
    }
  }, [fromDate, toDate, isFirst]);

  useEffect(() => {
    if (props?.leadDistubtionData?.salesGroup) {
      setFirstRender(true);
      getSubSalesGroups().then((data) => {
        setSubSalesGroupArray(data.data);
        setLoading(false);
      });

      async function getSubSalesGroups() {
        setLoading(true);
        return await getSubSalesGroup(props?.leadDistubtionData?.salesGroup);
      }

      let data =
        aggregateData &&
        aggregateData.filter(
          (data) => data.salesGroupId == props.leadDistubtionData.salesGroup
        );
      console.log(data);
      setFinalAggregateData(data);
    }
  }, [props?.leadDistubtionData?.salesGroup]);

  const pageCount = Math.ceil(size / PER_PAGE);
  function handleClick2({ selected: selectedPage }) {
    setCurrentPAge(selectedPage);
    getLeadss().then((data) => {
      setLeads(data.data);
      setLoading(false);
    });

    async function getLeadss() {
      setLoading(true);
      return await getLeads(
        fromDate,
        toDate,
        salesGroup,
        program,
        leadSource,
        subSalesGroup,
        selectedPage,
        PER_PAGE,
        isFirst
      );
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (value) => {
    handleClose();
  };

  const handleModelClose = (event) => {
    setModelShow({ Boolean: false });
  };

  const resetFilter = () => {
    props.resetLeadDistributionFilter();
    setFromDate("");
    setToDate("");
    setLeadSource("");
    setSubSalesGroup("");
    setLeadSource("");
    setSubSalesGroup("");
    setSalesGroup("");
    setProgram("");
    window.history.pushState(
      {},
      "",
      `?program=&salesGroup=&fromDate=&toDate=&subSalesGroup=&leadSource=`
    );
    window.location.reload();
  };

  const handleChange = (event) => {
    console.log(event.target.checked);
    setFirstRender(true);
    setIsFirst(event.target.checked);
    if (event.target.checked) {
      setIsFirstString("true");
    } else {
      setIsFirstString("false");
    }
  };

  return (
    <>
      {loading && <Loader />}
      {modelShow.Boolean && modelShow.String != null && (
        <LeadDistributionModel
          show={modelShow}
          handleClose={handleModelClose}
        />
      )}

      {leadExportModel && (
        <ExportLead
          show={leadExportModel}
          setShow={setLeadExportModel}
          salesGroupArray={props?.leadDistubtionData.salesGroupArray}
          lsplsArray={lsplsArray}
        />
      )}
      <div>
        {/* time */}
        <div className="timetablemaincontainer">
          <div className="timetablecontainer">
            <p>Lead Generation Time</p>
            <div className="timetabletimecontainer">
              <div>
                <input
                  className="timeleadtabledrop"
                  type="date"
                  id="monthyear"
                  name="monthyear"
                  // min={minDate}
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                    setFirstRender(true);
                  }}
                />
              </div>
              <div>
                <input
                  className="timeleadtabledrop"
                  type="date"
                  id="monthyear"
                  name="monthyear"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                    setFirstRender(true);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="timetablecontainer">
            <p>Lead Source</p>
            <div>
              <select
                className="timeleadtabledrop"
                value={leadSource}
                onChange={(e) => {
                  setLeadSource(e.target.value);
                  setFirstRender(true);
                }}
                name="leadsource"
                id="leadsource"
              >
                <option disabled selected value="">
                  Lead Source
                </option>
                <option value="">All Lead Source</option>
                {lsplsArray &&
                  lsplsArray.map((data) => {
                    return <option value={data}>{data}</option>;
                  })}
              </select>
            </div>
          </div>

          <div className="timetablecontainer">
            <p>Sub-sales Group</p>
            <div>
              <select
                value={subSalesGroup}
                onChange={(e) => {
                  setSubSalesGroup(e.target.value);
                  setFirstRender(true);
                }}
                className="timeleadtabledrop"
                name="subsalesgroup"
                id="subsalesgroup"
                disabled={
                  props?.leadDistubtionData?.salesGroup == "" ? true : false
                }
              >
                <option value="" disabled selected>
                  All
                </option>
                <option value="">All Sub Sales Group</option>
                {subSalesGroupArray &&
                  subSalesGroupArray.map((data, i) => {
                    return (
                      <option key={i} value={data._id}>
                        {data.email}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>

          <div className="timetablecontainer">
            <p> </p>
            <div>
              <button
                className="resetFilterButton"
                onClick={() => resetFilter()}
              >
                Reset Filter
              </button>
            </div>
          </div>
        </div>

        {/* revenue */}
        <div className="revenuemaincontainer">
          <div className="revenuecontainer">
            <h4>Total Target Revenue</h4>
            {loadingLeadTableData ? (
              <p>Loading...</p>
            ) : (
              <h2>₹ {leads && leads.admissionAmount}</h2>
            )}
          </div>

          <div className="revenuecontainer">
            <div style={{ display: "flex", gap: "5px" }}>
              <h4>Total Admission Targets</h4>
            </div>
            {loadingLeadTableData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leads && leads.admsissionCount}</h2>
            )}
          </div>

          <div className="revenuecontainer">
            <div style={{ display: "flex", gap: "5px" }}>
              <h4>Unique Leads</h4>
              <h4 className="tooltipl">
                <img
                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                  height={20}
                  width={20}
                />
                <span class="tooltiptextl2">
                  {" "}
                  All leads shown here are created for the first time in LS with
                  unique mobile number.
                </span>{" "}
              </h4>
            </div>
            {loadingLeadTableData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leads && leads.ucount}</h2>
            )}
          </div>

          <div className="revenuecontainer">
            <div style={{ display: "flex" }}>
              <h4>Total Number of Leads</h4>
              <h4 className="tooltipl">
                <img
                  src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                  height={20}
                  width={20}
                />
                <span class="tooltiptextl2">
                  This shows the total number of leads created for the selected
                  dates. This includes the leads which are created more than
                  once by a lead with same mobile number.
                </span>{" "}
              </h4>
            </div>
            {loadingLeadTableData ? (
              <p>Loading...</p>
            ) : (
              <h2> {leads && leads.count}</h2>
            )}
          </div>

          <div className="revenuebtncontainer">
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                className="revenueeaddeditbtn"
              >
                Add/Edit
                <ArrowDropDownIcon style={{ fontSize: "28px" }} />
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setModelShow({
                      Boolean: true,
                      String: "Add/Edit Programs & Cost",
                    });
                  }}
                  className="Popitem"
                  value="addEditProgram"
                >
                  Add/Edit Programs & Cost
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/LeadDistributionPanelAdvisors");
                  }}
                  className="Popitem"
                  value="addEditProgram"
                >
                  View Advisors List
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    setModelShow({
                      Boolean: true,
                      String: "Find Lead",
                    });
                  }}
                  className="Popitem"
                  value="addEditProgram"
                >
                  Find Lead
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    navigate("/LeadDistributionTarget");
                  }}
                  className="Popitem"
                  value="addEditProgram"
                >
                  Lead Distribution Target
                </MenuItem>
              </Menu>
            </div>

            <div
              className="revenueexportbtn"
              onClick={() => setLeadExportModel(true)}
            >
              Export
            </div>
          </div>
        </div>

        {/* group view */}
        <div className="groupmaincontainer">
          <div className="groupmainheadingdiv">
            <h3>Lead View</h3>

            <div style={{ marginTop: "20px" }}>
              <div style={{ display: "flex", gap: "5px" }}>
                <h5>Unique Lead </h5>
                <h5 style={{ color: "#3c4852" }} className="tooltipl">
                  <img
                    src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                    height={20}
                    width={20}
                  />
                  <span class="tooltiptextl">
                    This shows the details of leads created depending upon the
                    lead type selected below. By default it shows "unique"
                    leads. You can change the toggle below.
                  </span>{" "}
                </h5>
              </div>

              <div>
                <GreenSwitch
                  {...label}
                  checked={isFirst}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="groupmaintablediv">
            <div className="" style={{ overflowX: "scroll", width: "100%" }}>
              <div style={{ width: "2268px" }}>
                <table className="table table-striped groupleadtable">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        style={{ width: "254px", height: "48px" }}
                      >
                        Lead Generation Time
                      </th>
                      <th
                        scope="col"
                        style={{ width: "254px", height: "48px" }}
                      >
                        Lead Allocated At
                      </th>
                      <th scope="col" style={{ width: "145px" }}>
                        Lead Source{" "}
                      </th>
                      <th scope="col" style={{ width: "145px" }}>
                        Lead Name
                      </th>
                      <th scope="col" style={{ width: "292px" }}>
                        Lead Email Id
                      </th>
                      <th scope="col" style={{ width: "292px" }}>
                        Lead Phone Number
                      </th>
                      <th scope="col" style={{ width: "150px" }}>
                        Product
                      </th>
                      <th scope="col" style={{ width: "150px" }}>
                        Original Ls Key
                      </th>
                      <th scope="col" style={{ width: "129px" }}>
                        Allocated Sales Group
                      </th>
                      <th scope="col" style={{ width: "152px" }}>
                        Business Head
                      </th>
                      <th scope="col" style={{ width: "196px" }}>
                        Sub-sales Group
                      </th>
                      <th scope="col" style={{ width: "214px" }}>
                        Sales Head
                      </th>
                      <th scope="col" style={{ width: "284px" }}>
                        Lead Owner
                      </th>
                      <th scope="col" style={{ width: "100px" }}>
                        Should Lead Saved On Ls
                      </th>
                      <th scope="col" style={{ width: "100px" }}>
                        <div style={{ display: "flex", gap: "5px" }}>
                          <p style={{ fontWeight: "600", fontSize: "16px" }}>
                            Lead Saved On Ls{" "}
                          </p>
                          <h4 className="tooltipl">
                            <img
                              src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                              height={20}
                              width={20}
                            />
                            <span class="tooltiptextl2">
                              {" "}
                              If "Should lead saved on LS" is true and "Lead
                              saved on LS" is false for more than 15 min then,
                              Due to some technical issues lead doesn't save on
                              ls
                            </span>{" "}
                          </h4>
                        </div>
                      </th>
                      <th scope="col" style={{ width: "100px" }}>
                        Unique
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingLeadTableData ? (
                      <tr
                        className="uper_table_container"
                        style={{ border: "none" }}
                      >
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                      </tr>
                    ) : (
                      leads &&
                      leads.leads.map((data, idx) => {
                        return (
                          <tr className="ld_table_center" key={idx}>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {convertUtcToIst(data.createdAt)}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.advisorAssignAt
                                ? convertUnixTimeToIst(data.advisorAssignAt)
                                : "--"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.pls}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.FirstName}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.EmailAddress || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.Phone || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.mx_Program_3 || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.originalLsKey || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {getSalesGroupName(
                                props.leadDistubtionData &&
                                  props.leadDistubtionData.salesGroupArray,
                                data.salesGroupId
                              ) || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {getSalesGroupEmail(
                                props.leadDistubtionData &&
                                  props.leadDistubtionData.salesGroupArray,
                                data.salesGroupId
                              ) || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {getSubSalesGroupName(
                                subSalesGroupArray,
                                data.subSalesGroupId
                              ) || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {getSubSalesGroupEmail(
                                subSalesGroupArray,
                                data.subSalesGroupId
                              ) || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.OwnerIdEmailAddress || "---"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.should_lead_be_saved_on_ls ? "Yes" : "No"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {data.is_lead_saved_ls ? "Yes" : "No"}
                            </th>
                            <th
                              scope="row"
                              style={{
                                borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                              }}
                            >
                              {"isFirstTouch" in data
                                ? data.isFirstTouch
                                  ? "Yes"
                                  : "No"
                                : "N/A"}
                            </th>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Pagination2 handleClick={handleClick2} pageCount={pageCount} />
            </div>
          </div>
        </div>

        {/* aggregate */}
        <div className="groupmaincontainer">
          <div
            className="groupmainheadingdiv"
            style={{
              display: "flex",
              gap: "5px",
              alignItems: "baseline",
              borderBottom: "1px solid rgba(60, 72, 82, 0.25)",
            }}
          >
            <h3 style={{ borderBottom: "none" }}>Aggregate Analysis</h3>
            <h4 className="tooltipl">
              <img
                src="https://webcdn.imarticus.org/demo/ic_baseline-info.svg"
                height={20}
                width={20}
              />
              <span class="tooltiptextl2">
                {" "}
                This aggregate allocation is for distribution of "Unique leads"
                only
              </span>{" "}
            </h4>
          </div>

          <div className="groupmaintablediv">
            <div className="" style={{ overflowX: "scroll", width: "100%" }}>
              <div style={{ width: "1500px" }}>
                <table className="table table-striped groupleadtable">
                  <thead>
                    <tr className="">
                      <th
                        scope="col"
                        style={{
                          width: "258px",
                          padding: "0px 8px",
                          height: "48px",
                        }}
                      >
                        Sales Group
                      </th>
                      <th
                        scope="col"
                        style={{ width: "294px", padding: "0px 8px" }}
                      >
                        Sub-sales Group
                      </th>
                      <th
                        scope="col"
                        style={{ width: "294px", padding: "0px 8px" }}
                      >
                        Leads Allocated
                      </th>

                      {lsplsArray &&
                        lsplsArray
                          .sort((a, b) => a - b)
                          .map((data, index) => {
                            return (
                              <th
                                scope="col"
                                style={{ width: "114px", padding: "0px 8px" }}
                                key={index}
                              >
                                {data}
                              </th>
                            );
                          })}
                    </tr>
                  </thead>
                  <tbody>
                    {loadingTableData ? (
                      <tr
                        className="uper_table_container"
                        style={{ border: "none" }}
                      >
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                        <th>
                          <Skeleton
                            variant="rectangular"
                            width={100}
                            height={30}
                          />
                        </th>
                      </tr>
                    ) : (
                      finalAggregateData &&
                      finalAggregateData.map((data, idx) => {
                        return (
                          <tr
                            className="uper_table_container ld_table_center"
                            key={idx}
                          >
                            <th>
                              {getSalesGroupName(
                                props.leadDistubtionData &&
                                  props.leadDistubtionData.salesGroupArray,
                                data.salesGroupId
                              ) || "---"}
                            </th>
                            <th>
                              {getSubSalesGroupName(
                                subSalesGroupArray,
                                data.subSalesGroupId
                              ) || "---"}
                            </th>
                            <th>{data.total_pls_count}</th>
                            <th>{data.acp_count}</th>
                            <th>{data.affiliate_count}</th>
                            <th>{data.btldatabase_count}</th>
                            <th>{data.community_count}</th>
                            <th>{data.display_count}</th>
                            <th>{data.fb_count}</th>
                            <th>{data.mailer_count}</th>
                            <th>{data.referral_count}</th>
                            <th>{data.search_count}</th>
                          </tr>
                        );
                      })
                    )}
                  </tbody>
                </table>
                <p>
                  Note : It might take upto 20 minutes for Aggregate Analysis to
                  be updated
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadDistributionPanel;
