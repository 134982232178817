import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function getDeployment() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_deployment`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

// prod
export async function getProductionDeployment() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_production_deployment`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function postDeployment() {
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/create_staging_deployment`,
      {},
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

// prod
export async function checkProductionDeployment() {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });

  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/check_production_deployment`,
      { aid: aid },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function createProductionDeployment(approvalID) {
  const cookies = new Cookies();
  let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });

  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/create_production_deployment`,
      { aid: aid, deployId: approvalID },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getDeploymentStatus(currentPage, size) {
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_deployment_status`,
      { pageNumber: currentPage, ipp: size },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

// prod
export async function getProductionDeploymentStatus(currentPage, size) {
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_production_deployment_status`,
      { pageNumber: currentPage, ipp: size },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getMailAccessUser() {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_mail_access_user`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log();
    return {};
  }
}

export async function getDeploymentSpecificStatus(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_deployment_specific_status?_id=${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function getDeploymentName(approvalID) {
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_approval_data`,
      {
        approvalID,
      },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

// export async function viewDeploymentSpecific(id) {
//   const cookies = new Cookies();
//   try {
//     const res = await axios.get(
//       `${configs.MARKETING_ENDPOINT}/marketingopspanel/view_deployment_data?_id=${id}`,
//       {
//         headers: {
//           "x-access-token": cookies.get("at", {
//             domain: configs.DOMAIN,
//             path: "/",
//           }),
//         },
//       }
//     );

//     return res.data;
//   } catch (error) {
//     console.log(error);
//     return {};
//   }
// }
