import React from "react";
import { Modal } from "react-bootstrap";

function TemplateUrlModel(props) {
  let url = props.url;
  if (url.length >= 74) {
    url = url.slice(0, 74) + "...";
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="model_container_addprogram"
      >
        <div style={{ padding: "49px 24px" }}>
          <img
            src="https://assets.pegasus.imarticus.org/location/Vector.svg"
            height={126}
            width={126}
          />
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "600",
              color: "#3c4852",
              marginBottom: "16px",
              marginTop: "40px",
              opacity: "0.75",
            }}
          >
            Preview is Ready
          </h1>
          <div
            style={{
              border: "1px solid rgba(60, 72, 82, 0.50)",
              padding: "10px 16px",
              borderRadius: "8px",
            }}
          >
            <a
              href={props.url}
              target="_blank"
              style={{
                display: "flex",
                gap: "20px",
                justifyContent: "space-between",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "rgba(60, 72, 82, 0.75)",
                }}
              >{`${url}`}</p>
              <img
                src="https://assets.pegasus.imarticus.org/location/Frame.svg"
                height={24}
                width={24}
              />
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default TemplateUrlModel;
