import React from "react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import "./css/editcourse.css";

function ViewCourseAttribute(data) {
  const [propsdata, setPropsdata] = useState(data.data);
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);
  useEffect(() => {
    setPropsdata(data.data);
  }, []);

  useEffect(() => {}, [checkbox.length]);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        arrayToDo = checkbox;
      } else {
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }
  return (
    <div style={{ position: "relative" }}>
      <div className="main_container_edit_viewCourse_attribute">
        <div className="program_container">
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="leadsquareProgramKey"
              value={propsdata.leadsquareProgramKey}
            />
            <div className="program_container_box">
              <h2>Leadsquare Program Key</h2>
              <h1>{propsdata.leadsquareProgramKey}</h1>
            </div>
          </div>
          {propsdata.programTitle && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="programTitle"
                value={propsdata.programTitle}
              />
              <div className="program_container_box">
                <h2>Program Title</h2>
                <h1>{propsdata.programTitle}</h1>
              </div>
            </div>
          )}
          {propsdata.programName && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="programName"
                value={propsdata.programName}
              />
              <div className="program_container_box">
                <h2>Program Name</h2>
                <h1>{propsdata.programName}</h1>
              </div>
            </div>
          )}
          {propsdata.metaDescription && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="metaDescription"
                value={propsdata.metaDescription}
              />
              <div className="program_container_box">
                <h2>Meta Description</h2>
                <h1>{propsdata.metaDescription}</h1>
              </div>
            </div>
          )}
          {propsdata.metaKeyword && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="metaKeyword"
                value={propsdata.metaKeyword}
              />
              <div className="program_container_box">
                <h2>Meta Keywords</h2>
                <h1>{propsdata.metaKeyword}</h1>
              </div>
            </div>
          )}
          {propsdata.ogTitle && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogTitle"
                value={propsdata.ogTitle}
              />
              <div className="program_container_box">
                <h2>Og:Title</h2>
                <h1>{propsdata.ogTitle}</h1>
              </div>
            </div>
          )}
          {propsdata.ogDescription && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogDescription"
                value={propsdata.ogDescription}
              />
              <div className="program_container_box">
                <h2>Og:Description</h2>
                <h1>{propsdata.ogDescription}</h1>
              </div>
            </div>
          )}
          {propsdata.applicationDeadline && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="applicationDeadline"
                value={propsdata.applicationDeadline || "---"}
              />
              <div className="program_container_box">
                <h2>Application Deadline</h2>
                <h1>{propsdata.applicationDeadline || "---"}</h1>
              </div>
            </div>
          )}
          {propsdata.startDate && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="startDate"
                value={propsdata.startDate || "---"}
              />
              <div className="program_container_box">
                <h2>Start Date</h2>
                <h1>{propsdata.startDate || "---"}</h1>
              </div>
            </div>
          )}
          {propsdata.locationNames && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="location"
                value={propsdata.locationNames || "---"}
              />
              <div className="program_container_box">
                <h2>Program Locations</h2>
                <h1>
                  {(propsdata &&
                    propsdata.locationNames.length > 0 &&
                    propsdata.locationNames.join(", ")) ||
                    "--"}
                </h1>
              </div>
            </div>
          )}
          {propsdata.modeOfLearning && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="modeoflearning"
                value={propsdata.modeOfLearning || "---"}
              />
              <div className="program_container_box">
                <h2>Mode of Learning</h2>
                <h1>
                  {(propsdata &&
                    propsdata.modeOfLearning.length > 0 &&
                    propsdata.modeOfLearning.join(", ")) ||
                    "--"}
                </h1>
              </div>
            </div>
          )}
          {propsdata.brochureFileName && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="brochureFileName"
                value={propsdata.brochureFileName}
              />
              <div className="program_container_box">
                <h2>Brochure Link</h2>
                <a
                  href={propsdata.brochureFileName}
                  target="_blank"
                  style={{ fontWeight: 500, overflowWrap: "break-word" }}
                >
                  {propsdata.brochureFileName
                    ? propsdata.brochureFileName
                    : "---"}
                </a>
              </div>
            </div>
          )}
          {/* placement report field */}
          {propsdata.placementReportName && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="placementReportName"
                value={propsdata.placementReportName}
              />
              <div className="program_container_box">
                <h2>Placement Report Link</h2>
                <a
                  href={propsdata.placementReportName}
                  target="_blank"
                  style={{ fontWeight: 500, overflowWrap: "break-word" }}
                >
                  {propsdata.placementReportName
                    ? propsdata.placementReportName
                    : "---"}
                </a>
              </div>
            </div>
          )}
          {propsdata.optionalFormFields && (
            <div className="program_container_inside">
              <input
                onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="optionalFormFields"
                value={propsdata.optionalFormFields}
              />
              <div className="program_container_box">
                <h2>Update Form Fields</h2>
                <p>Note: Changes will only affect IL-3.0 Form</p>
                <h1>
                  {(propsdata &&
                    propsdata.optionalFormFields.length > 0 &&
                    propsdata.optionalFormFields.join(", ")) ||
                    "--"}
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>
        {checkbox && checkbox.length >= 2 ? (
          <div className="floater_edit_program">
            <p>{checkbox.length - 1} Field Selected</p>
            <Link
              to={`/Editcourse${propsdata._id}?schema=locationSpecificProgram`}
              state={{ checkbox }}
              style={{ textDecoration: "none" }}
            >
              <button className="floater_button">
                <p>Edit</p>
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ViewCourseAttribute;
