import React from "react";
import { Modal } from "react-bootstrap";
import { convertUtcToIst } from "../../services/helper";
function ViewCheckInModel(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={props.handleClose}
      className="leaddistributionmodel"
    >
      <Modal.Header closeButton className="leadmodelheaderheading">
        Last 100 Transition Status
      </Modal.Header>

      <div
        className="adddvisormaincontainer"
        style={{ overflow: "scroll", width: "100%" }}
      >
        <div style={{ width: "500px", maxHeight: "80vh" }}>
          <table className="table table-striped groupleadtable">
            <thead style={{ height: "40px" }}>
              <tr className="">
                <th scope="col" style={{ width: "70px", padding: "0px 8px" }}>
                  Before Transition
                </th>
                <th scope="col" style={{ width: "70px", padding: "0px 8px" }}>
                  Current
                </th>
                <th scope="col" style={{ width: "120px", padding: "0px 8px" }}>
                  Transition At
                </th>
              </tr>
            </thead>
            <tbody>
              {props &&
                props.data &&
                props.data.map((data, idx) => {
                  return (
                    <tr className="" key={idx}>
                      <th
                        style={{
                          borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                        }}
                      >
                        <div>{data.beforeTransition ? "True" : "False"}</div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                        }}
                      >
                        <div>{data.currentTransition ? "True" : "False"}</div>
                      </th>
                      <th
                        style={{
                          borderRight: "1px solid rgba(60, 72, 82, 0.25)",
                        }}
                      >
                        <div>
                          {data.transitionTime &&
                            convertUtcToIst(data.transitionTime)}
                        </div>
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

export default ViewCheckInModel;
