import React from "react";
import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Loader from "./loader/loading";
import { createTemplatePage } from "../services/templateapi";
import { useNavigate } from "react-router-dom";

function AddProgramModel(props) {
  const [loading, setLoading] = useState(false);
  const [lskey, setLskey] = useState("");
  const [url, setUrl] = useState("");
  const [templateId, setTemplateId] = useState(props.templateId);
  const navigate = useNavigate();
  const submitHandler = async (e) => {
    e.preventDefault();

    let pageurl;
    let secondurl;
    let temp = url;
    if (!/^[a-zA-Z\-\/]*$/.test(temp)) {
      alert("Please Do not use special character in the url");
      return;
    }
    if (url[0] !== "/") {
      pageurl = "/" + url;
    } else {
      pageurl = url;
    }
    if (pageurl[pageurl.length - 1] !== "/") {
      secondurl = pageurl + "/";
    } else {
      secondurl = pageurl;
    }

    const body = {
      leadsquareProgramKey: lskey,
      link: secondurl,
      schema_form_render_id: templateId,
    };

    setLoading(true);
    createTemplatePage(body)
      .then((data) => {
        console.log({ data });
        console.log("This is my data");
        setLoading(false);
        navigate("/TemplatePageCreation" + data.data.data);
      })
      .catch((error) => {
        alert("Duplicate Entry");
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="model_container_addprogram"
      >
        <Modal.Header closeButton className="addprogrammodelheader">
          Fill the details
        </Modal.Header>
        <div className="new_model_div" style={{ padding: "18px" }}>
          <form onSubmit={submitHandler}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "15px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1 style={{ fontSize: "18px", opacity: ".75" }}>
                  Enter Leadsquared Key
                </h1>
              </div>
              <input
                value={lskey}
                type="text"
                onChange={(e) => setLskey(e.target.value)}
                className="form-inpt addprograminput"
                name="lskey"
                placeholder="CIBOP"
                required
                minLength={3}
                maxLength={440}
                style={{ padding: "8px" }}
              />
            </div>

            <div
              style={{
                display: "flex",
                marginBottom: "32px",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  fontSize: "18px",
                  opacity: ".75",
                  textAlign: "initial",
                }}
              >
                Enter Program Page URL In Lowecase
              </h1>
              <input
                value={url}
                type="text"
                style={{ textTransform: "lowercase", padding: "8px" }}
                onChange={(e) => {
                  let temp = e.target.value;
                  temp = temp.toLowerCase();
                  setUrl(temp);
                }}
                className="form-inpt addprograminput"
                name="url"
                placeholder="/certified-investment-banking-operations-program/"
                required
                maxLength={200}
              />
            </div>
            <div className="addprogrambtndiv">
              <button
                type="submit"
                id="edit-submit-btn"
                className="addprogrambtnstart"
              >
                Start Program Creation
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default AddProgramModel;
