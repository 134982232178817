import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAllNewsScheam, showFolder } from "../services/newsapi";
import Loader from "./loader/loading";
import { Link, useHref } from "react-router-dom";
import MediaUploaderModel from "./MediaUploaderModel";
import FolderIcon from "@mui/icons-material/Folder";
import moment from "moment";
import FolderComponent from "./ImageUploader";

function Mediauploader() {
  const [newsdata, setNewsdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [folder, setFolder] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await showFolder();
    console.log(res.data, "mydata");
    setFolder(res.data.data);
  };
  console.log(folder, "folder");
  const opennewsmodel = () => {
    setShow(true);
  };

  const handleClose = () => {
    return setShow(false), window.location.reload(true);
  };
  let navigate = useNavigate();

  const onClick = (event) => {
    if (event.detail == 2) {
      console.log("Double Clicked");
      navigate("/ImageUploader");
      // navigate(`/ImageUploader${data._id}`)
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid" style={{ height: "100vh" }}>
        {show && <MediaUploaderModel show={show} handleClose={handleClose} />}
        <div className="home_title_div news_heading_buttondiv">
          <h1 className="home_title">Image Folders</h1>
          <div>
            <button onClick={opennewsmodel} className="add_news">
              Add Folder
            </button>
          </div>
        </div>
        <div className="program_container">
          <div className="folder_grid">
            {folder.map((data) => {
              return (
                <>
                  <div className="folder_grid_inside">
                    <Link
                      style={{ textDecoration: "none", color: "#fff" }}
                      to={`/ImageUploader${data._id}`}
                    >
                      <img
                        src="https://webcdn.imarticus.org/undefined/myfolder.svg"
                        alt="folder icon"
                        height="54px"
                        width="65px"
                      />
                    </Link>

                    <div className="foldername">
                      {/* <FolderComponent style={{display:'hidden'}} folder={folder} /> */}
                      <p>{data.folder}</p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Mediauploader;
