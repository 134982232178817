import React from "react";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "./loader/loading";
import { getSeopageSchema } from "../services/seoapproval";
import { configs } from "../constants";

function Viewseodetail() {
  let { id } = useParams();
  const [propsdata, setPropsdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);

  useEffect(() => {
    specificdata().then((specificdata) => {
      setPropsdata(specificdata.data.specificdata);
    });

    async function specificdata() {
      return await getSeopageSchema(id);
    }
  }, []);

  useEffect(() => {}, [checkbox.length]);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        arrayToDo = checkbox;
      } else {
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="program_name_container">
          <h1 className="program_name">Auxiliary Page Url</h1>
          <a href={configs.WEBSITE_LINK + propsdata.pageUrl} target="_blank">
            <h1
              style={{ fontSize: "21px", lineHeight: "24px", opacity: "75%" }}
            >
              {propsdata.pageUrl}
            </h1>
          </a>
        </div>

        <div className="program_container">
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="pageTitle"
              value={propsdata.pageTitle}
            />
            <div className="program_container_box">
              <h2>Page Title</h2>
              <h1>{propsdata.pageTitle}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="metaDescription"
              value={propsdata.metaDescription}
            />
            <div className="program_container_box">
              <h2>Meta Description</h2>
              <h1>{propsdata.metaDescription}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="metaKeyword"
              value={propsdata.metaKeyword}
            />
            <div className="program_container_box">
              <h2>Meta Keyword</h2>
              <h1>{propsdata.metaKeyword}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogDescription"
              value={propsdata.ogDescription}
            />
            <div className="program_container_box">
              <h2>Og Description</h2>
              <h1>{propsdata.ogDescription}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="ogTitle"
              value={propsdata.ogTitle}
            />
            <div className="program_container_box">
              <h2>Og Title</h2>
              <h1>{propsdata.ogTitle}</h1>
            </div>
          </div>
        </div>
      </div>
      <div>
        {checkbox && checkbox.length >= 2 ? (
          <div className="floater_edit">
            <p>{checkbox.length - 1} Field Selected</p>
            <Link
              to={`/Editauxpage${propsdata._id}`}
              state={{ checkbox }}
              style={{ textDecoration: "none" }}
            >
              <button className="floater_button">
                <p>Edit</p>
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>

      {propsdata.pageJson ? (
        <div className="main_container_edit_viewCourse">
          <div className="main_container_edit_viewCourse_2screen">
            <h1 className="program_page_content">Page Content</h1>
            <div className="program_container_box_content">
              <h4>Page Content</h4>
              <div>
                <Link to={`/ViewPageContent${propsdata._id}`}>
                  <button className="edit_program_json">
                    Edit Page Content
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Viewseodetail;
