import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "./loader/loading";

import { getProgramtag } from "../services/marketinggetapi";
import { TagPicker } from "rsuite";
import { useNavigate } from "react-router-dom";
import { addWebinarApi, webinarApprovalApi } from "../services/webinarapi";

import "./css/editcourse.css";

import { getWebinarEventsApi } from "../services/webinarapi";
import { getOtprequest } from "../services/approvalapi";

function ViewWebinarDetails() {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [domainTag, setDomainTag] = useState("");
  const [originalWebinarData, setOrignalWebinarData] = useState({});
  const [webinarData, setWebinarData] = useState({
    topic: "",
    description: "",
    date: "",
    startTime: "",
    endTime: "",
    liveLink: "",
    recorderLink: "",
    thumbnail: "",
    domain: [],
    placeholderTag: [],
  });
  const [updatedFields, setUpdatedFields] = useState([]);
  const navigate = useNavigate();

  const placeholderTag = [
    { label: "HEADER", value: "HEADER" },
    { label: "RECOMMENDED", value: "RECOMMENDED" },
    { label: "RECORDED", value: "RECORDED" },
  ];

  useEffect(() => {
    setLoading(true);
    getWebinar().then((something) => {
      let data = something.data.data[0];
      setWebinarData(data);
      setOrignalWebinarData(data);
      setLoading(false);
    });

    async function getWebinar() {
      setLoading(true);
      return await getWebinarEventsApi(id);
    }

    getPtag().then((data) => {
      setDomainTag(data);
      setLoading(false);
    });

    async function getPtag() {
      setLoading(true);
      return await getProgramtag();
    }

    setLoading(false);
  }, []);

  const updatewebinarData = (E) => {
    if (E.target.name === "topic" && E.target.value.length > 60) {
      alert("You have reached max character limit i.e 60 characters");
      return;
    }

    if (E.target.name === "description" && E.target.value.length > 130) {
      alert("You have reached max character limit i.e 130 characters");
      return;
    }

    if (!updatedFields.includes(E.target.name))
      setUpdatedFields((pre) => [...pre, E.target.name]);

    setWebinarData((pre) => {
      return {
        ...pre,
        [E.target.name]: E.target.value,
      };
    });
  };

  const handleDomainChange = (values) => {
    if (!updatedFields.includes("domain"))
      setUpdatedFields((pre) => [...pre, "domain"]);
    setWebinarData((prevState) => ({
      ...prevState,
      domain: values,
    }));
  };

  const handlePlaceholderChange = (values) => {
    if (!updatedFields.includes("placeholderTag"))
      setUpdatedFields((pre) => [...pre, "placeholderTag"]);
    setWebinarData((prevState) => ({
      ...prevState,
      placeholderTag: values,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (webinarData.thumbnail) {
        const url = webinarData.thumbnail;

        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function () {
            if (img.width % 585 !== 0 || img.height % 324 !== 0) {
              // alert('Image dimensions must be equal to or multiples of 585x324.');
              reject(
                new Error(
                  "Thumbnail dimensions must be equal to or multiples of 585x324."
                )
              );
            } else {
              console.log("Image dimensions are correct.");
              resolve();
            }
          };
          img.onerror = function () {
            // alert('Failed to load image. Enter Proper URL');
            reject(
              new Error("Failed to load Thumbnail image. Enter Proper URL")
            );
          };

          img.src = url;
        });
      }

      webinarApproval().then((response) => {
        if (response.data.success == true) {
          getOtprequest(
            {
              Fieldchange: updatedFields,
              Newdata: webinarData,
              Olddata: originalWebinarData,
            },
            response.data.data
          );

          alert("Approval Sent Successfully !!!");
          // navigate("/Approvalwindow");
          window.open("/Approvalwindow", "_self");
        } else {
          alert("Something Went Wrong.");
        }
      });

      async function webinarApproval() {
        return await webinarApprovalApi(
          true,
          webinarData,
          originalWebinarData,
          updatedFields
        );
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="updatedFieldsflex">
          <p className="updatedFieldsLabel">Updated Fields: </p>
          {updatedFields.length > 0 &&
            updatedFields.map((value, index) => {
              return (
                <>
                  <p className="updatedFieldsLKey" key={index}>
                    {value}
                  </p>
                  {"| "}
                </>
              );
            })}
        </div>

        <div className="home_title_div">
          <h1 className="home_title">Update Webinar</h1>
        </div>

        <div className="createUtmmaindiv">
          <div className="createUtmbottomhalf">
            <form onSubmit={submitHandler}>
              <div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Topic <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Topic"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.topic}
                    onChange={updatewebinarData}
                    name="topic"
                  />
                  <label className="note_label">
                    Note : Maximum 60 characters allowed
                  </label>
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Description{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Description"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.description}
                    onChange={updatewebinarData}
                    name="description"
                  />
                  <label className="note_label">
                    Note : Maximum 130 characters allowed
                  </label>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      Webinar Data <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set time and date"
                      className="create_auxinput2"
                      required
                      type="date"
                      value={webinarData.date.split("T")[0]}
                      onChange={updatewebinarData}
                      name="date"
                    />
                  </div>

                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      Start Time <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set Start time"
                      className="create_auxinput2"
                      required
                      type="time"
                      value={webinarData.startTime}
                      onChange={updatewebinarData}
                      name="startTime"
                    />
                  </div>

                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      End Time <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set End time"
                      className="create_auxinput2"
                      required
                      type="time"
                      value={webinarData.endTime}
                      onChange={updatewebinarData}
                      name="endTime"
                    />
                  </div>
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Live link{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Live Link"
                    className="create_auxinput2"
                    required
                    type="text"
                    value={webinarData.liveLink}
                    onChange={updatewebinarData}
                    name="liveLink"
                  />
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Recording link{" "}
                  </h6>
                  <input
                    placeholder=""
                    className="create_auxinput2"
                    type="text"
                    value={webinarData.recordedLink}
                    onChange={updatewebinarData}
                    name="recordedLink"
                  />
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Thumbnail{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Webinar Thumbnail Link"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.thumbnail}
                    onChange={updatewebinarData}
                    name="thumbnail"
                  />
                  <label className="note_label">
                    Note : Dimension should be of 585 * 324
                  </label>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <h6 style={{ paddingBottom: "10px" }}>Domain Tags </h6>
                  <TagPicker
                    data={domainTag}
                    value={webinarData.domain}
                    onChange={handleDomainChange}
                    style={{ width: "100%" }}
                    placeholder="Select Tags"
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <h6 style={{ paddingBottom: "10px" }}>Placeholder Tags </h6>

                  <TagPicker
                    data={placeholderTag}
                    value={webinarData.placeholderTag}
                    onChange={handlePlaceholderChange}
                    style={{ width: "100%" }}
                    placeholder="Select Tags"
                  />
                </div>
              </div>

              <div className="request_approval" style={{ display: "flex" }}>
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                  style={{ marginLeft: "20px" }}
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* <div className="program_name_container">
        <div className="program_container">
      {
        Object.keys(webinarData).map((key, index)=>{
          if(key === '_id' || key === 'act' || key === 'created_at' || key === '__v') return; 
          
          if(typeof(webinarData[key])== 'object'){
            return(
              <>
              <div className="program_container_inside">
                <input
                // onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogTitle"
                value={key}
              />
                <div className="program_container_box">
                  <h2>{key}</h2>
                  <h1>{ webinarData[key] ? 
                  webinarData[key].join(", ") : "- - -"}</h1>
                </div>
              </div>
            </>
            )      
          }else if(key === 'liveLink'){
            return(
              <>
              <div className="program_container_inside">
                <input
                // onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogTitle"
                value={key}
              />
                <div className="program_container_box">
                  <h2>{key}</h2>
                  <a href={webinarData[key]}>{ webinarData[key] ? webinarData[key] : "- - -"}</a>
                </div>
              </div>
            </>
            )
          }else if(key === 'thumbnail'){
            return(
              <>
              <div className="program_container_inside">
                <input
                // onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogTitle"
                value={key}
              />
                <div className="program_container_box">
                  <h2>{key}</h2>
                  <a href={webinarData[key]}>{ webinarData[key] ? webinarData[key] : "- - -" }</a>
                </div>
              </div>
            </>
            )
          }

          return(
            <>
             
              <div className="program_container_inside">
                <input
                // onClick={handlecheckbox}
                className="check_box"
                type="checkbox"
                id=""
                name="ogTitle"
                value={key}
              />
                <div className="program_container_box">
                  <h2>{key}</h2>
                  <h1>{ webinarData[key] ? webinarData[key] : "- - -"}</h1>
                </div>
              </div>
            </>
          )

        }) 
      }
        </div>
      </div> */}
    </>
  );
}

export default ViewWebinarDetails;
