import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";

import {
  getCapiEventsData,
  getFilterCapiEventsData,
} from "../services/leadsquareapi";

function CapiDashboard() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [leaddata, setleadData] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [filterData, setFilterData] = useState({
    email: "",
    contactNumber: "",
  });

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleChangeFilterData = (event) => {
    setFilterData((pre) => {
      return { ...pre, [event.target.name]: event.target.value };
    });
  };

  const handleSearchClick = () => {
    filterleadData().then((data) => {
      console.log("Filtered Data FB", data.data.data);
      setleadData(data.data);
      setLoading(false);
    });

    async function filterleadData() {
      setLoading(true);
      const data = {
        isFacebookSync: isChecked,
        email: filterData.email,
        contactNumber: filterData.contactNumber,
      };

      return await getFilterCapiEventsData(data);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    leadData().then((data) => {
      setleadData(data.data);
      console.log(data.data);
      setLoading(false);
    });

    async function leadData() {
      setLoading(true);
      return await getCapiEventsData();
    }
    //to get the array to be displayed
  }, []);

  return (
    <>
      <div className="home_title_div">
        <h1 className="home_title">Capi Dashboard </h1>
      </div>
      <div className="filter_container">
        <h3 className="filter_title">Filter By :</h3>
        <div className="filter_field">
          <label>Email Id : </label>
          <input
            type="text"
            name="email"
            value={filterData.email}
            onChange={handleChangeFilterData}
          />
        </div>
        <div className="filter_field">
          <label>Contact No. : </label>
          <input
            type="number"
            name="contactNumber"
            value={filterData.contactNumber}
            onChange={handleChangeFilterData}
          />
        </div>
        <div className="filter_field">
          <label>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            Facebook Synced
          </label>
        </div>
        <div className="filter_search" onClick={handleSearchClick}>
          Search
        </div>
      </div>
      <TableContainer className="mainTable">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className="leadtablefont">
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>CCode</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Event Name</TableCell>
              <TableCell>Meta Id</TableCell>
              <TableCell>Aid</TableCell>
              <TableCell>Pid</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Conversion Name Hash</TableCell>
              <TableCell>Conversion Email Hash</TableCell>
              <TableCell>Conversion Contact Hash</TableCell>
              <TableCell>Facebook Sync Status</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : leaddata?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {leaddata.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row.index}>
                      <TableCell>{row.name || "--"}</TableCell>
                      <TableCell>{row.email || "--"}</TableCell>
                      <TableCell>{row.cc || "--"}</TableCell>
                      <TableCell>{row.phone || "--"}</TableCell>
                      <TableCell>{row.event_name || "--"}</TableCell>
                      <TableCell>{row.facebook_lead_id || "--"}</TableCell>
                      <TableCell>{row.aid || "--"}</TableCell>
                      <TableCell>{row.pid || "--"}</TableCell>
                      <TableCell>{row.city || "--"}</TableCell>
                      <TableCell>{row.country || "--"}</TableCell>
                      <TableCell>{row.name_hash || "--"}</TableCell>
                      <TableCell>{row.email_hash || "--"}</TableCell>
                      <TableCell>{row.cc_ph_hash || "--"}</TableCell>
                      <TableCell>
                        {row.facebookSync ? "true" : "false"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default CapiDashboard;
