import React from "react";
import Loader from "./loader/loading";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { getAllLocationPage } from "../services/locationapi";
import "./css/home.css";
import moment from "moment";

function LocationHome() {
  useEffect(() => {
    locData().then((data) => {
      setPropsdata(data.data.data);
      setLoading(false);
    });

    async function locData() {
      setLoading(true);
      return await getAllLocationPage();
    }
  }, []);

  const [propsdata, setPropsdata] = useState("");
  const [loading, setLoading] = useState(false);

  return (
    <>
      {loading && <Loader />}
      <div
        className="container-fluid"
        style={{ height: "100vh", maxWidth: "1500px" }}
      >
        <div className="row" style={{ height: "100%" }}>
          <div className="home_container">
            <div className="home_title_div create_location_page_div">
              <h1 className="home_title">Location Pages</h1>

              <div className>
                <a href="/CreateLocation">
                  <button className="create_location_button">
                    Create Location Page
                  </button>
                </a>
              </div>
            </div>
            <div className="home_table">
              <table className="table table-striped">
                <thead>
                  <tr className="uper_table_container">
                    <th
                      scope="col"
                      style={{ width: "28%", paddingRight: "10px" }}
                    >
                      Location Page
                    </th>
                    <th scope="col">Last Change Date</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {propsdata.length > 0 &&
                    propsdata.map((data, idx) => {
                      return (
                        <tr className="uper_table_container" key={idx}>
                          <th scope="row" style={{ paddingRight: "20px" }}>
                            {data.Location}
                          </th>
                          <th>
                            {moment(data.updatedAt).format(
                              "DD/MM/YYYY, h:mm:ss a"
                            )}
                          </th>
                          <th>
                            <Link
                              style={{ textDecoration: "none", color: "#fff" }}
                              to={`/LocationDetail${data._id}`}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                alt="edit icon"
                                height="20px"
                                width="20px"
                              />
                            </Link>
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default LocationHome;
