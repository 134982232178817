import React, { useState } from "react";
import "./topHeader.css";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";

function TopHeader({ login }) {
  let navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (value) => {
    navigate(`/${value}`);
    handleClose();
  };

  // let handleClick = (e) => {
  //   navigate(`/${e.target.value}`);
  // };

  return (
    <div className="headerbar">
      <div className="nav_main">
        <a href="/">
          <img
            src="https://cdn.pegasus.imarticus.org/images/imarticus-new-logo.svg"
            alt="Imarticu Logo"
            height={40}
            width={195}
          />
        </a>
        <div className="HeaderDiv">
          <p className="top_heading">Marketing Ops Panel</p>
          <div>
            {/* <div>
              <select className="PopButton" onClick={handleClick}>
              <option value="" selected disabled hidden>Settings</option>
                <option value='Settings'>
                    Global Settings
                </option>
              </select>
            </div> */}
            {login && (
              <div>
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="PopButton"
                  startIcon={<SettingsIcon />}
                >
                  Settings
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <InputLabel id="WebinarForm">Form Schemas</InputLabel>
                <Select labelId="WebinarForm" className="PopitemMain" > */}
                  <MenuItem
                    onClick={() => {
                      handleOpen("Settings");
                    }}
                    className="Popitem"
                    value="Webinar"
                  >
                    Webinar Schema Setting
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("Newsview");
                    }}
                    className="Popitem"
                    value="Webinar"
                  >
                    News Management
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("Createauxpage");
                    }}
                    className="Popitem"
                    value="Auxiliary"
                  >
                    Create Auxiliary Page
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("Lead");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Lead Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("ViewB2BLead");
                    }}
                    className="Popitem"
                    value="ViewB2BLead"
                  >
                    B2B Lead Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("capidashboard");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Facebook CAPI Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("googlecapidashboard");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Google CAPI Dashboard
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("UtmHome");
                    }}
                    className="Popitem"
                    value="Utm"
                  >
                    UTM Management
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("HaptikProgramNameAutomation");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Haptik Program
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("ManageProgramTags");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Manage Program Tags
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("CreateLead");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Create Leads
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("MediaUploader");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Image Media Uploader
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("FbLeadDashboard");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Fb Lead Dashboard
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleOpen("LeadDistributionPanel");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Lead Distribution Panel
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      handleOpen("UnProcessedFbLead");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Un Processed FB Leads
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleOpen("metaAdsUpload");
                    }}
                    className="Popitem"
                    value="Lead"
                  >
                    Meta Ads Upload
                  </MenuItem>
                  {/* </Select> */}
                </Menu>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopHeader;
