import React, { useState } from "react";
import Loader from "./loader/loading";
import { readMetaCsvFile } from "../services/marketingapi";
import styles from "../Components/css/metaads.module.scss";
function MetaAdsUpload() {
  const [loading, setLoading] = useState(false);
  const [csvFile, setCsvFile] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log({ csvFile }, { fromDate }, { toDate });
    let response;
    if (csvFile && fromDate && toDate) {
      try {
        response = await readMetaCsvFile(csvFile, fromDate, toDate);
        console.log({ response });
        if (response && response.success == true) {
          alert(response.data);
        } else {
          alert("Something went wrong");
        }
      } catch (e) {
        console.log(e);
        alert(e);
        setLoading(false);
      }
    } else {
      alert("Please Fill all the fields");
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="home_container">
        <div className="home_title_div">
          <h1 className="home_title">Meta Ads Upload</h1>
        </div>

        <div className={styles.metaAdsUploadContainer}>
          <form onSubmit={handleSubmit}>
            <div className={styles.inputs}>
              <div>
                <p>From Date </p>
                <input
                  className={styles.metaCsvFileInput}
                  type="file"
                  accept=".csv"
                  required
                  onChange={(e) => setCsvFile(e.target.files[0])}
                />
              </div>
              <div className={styles.dateInput}>
                <div>
                  <p>From Date </p>
                  <input
                    className={styles.calender}
                    type="date"
                    required
                    id=""
                    onChange={(e) => setFromDate(e.target.value)}
                    name=""
                  />
                </div>
                <div>
                  <p>To Date </p>
                  <input
                    className={styles.calender}
                    type="date"
                    required
                    id=""
                    onChange={(e) => setToDate(e.target.value)}
                    min={fromDate}
                    name=""
                  />
                </div>
              </div>
            </div>
            <button className={styles.metaAdsUploadContainerBtn} type="submit">
              Upload CSV
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default MetaAdsUpload;
