import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "./loader/loading";
import "./css/webinar.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import ViewCourseAttribute from "./ViewCourseAttribute";
import ViewProgramContent from "./ViewProgramContent";
import { getLocationProgramSpecific } from "../services/locationProgram";

function Programlocation() {
  const [loading, setLoading] = useState(true);
  const [propsdata, setPropsdata] = useState("");
  const { id } = useParams();

  useEffect(() => {
    specificdata().then((specificdata) => {
      setPropsdata(specificdata.data);
      setLoading(false);
    });

    async function specificdata() {
      return await getLocationProgramSpecific(id);
    }
  }, []);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="main_container_edit_viewCourse">
          <h1 className="header_location">{"Program Location"}</h1>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h3 className="locationprogramacchead">Program Attributes</h3>
            </AccordionSummary>
            <AccordionDetails>
              <ViewCourseAttribute data={propsdata || ""} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <h3 className="locationprogramacchead">Program Content</h3>
            </AccordionSummary>
            <AccordionDetails>
              <ViewProgramContent data={propsdata || ""} />
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
            >
              <h3 className="locationprogramacchead">Program Json</h3>
            </AccordionSummary>
            <AccordionDetails>
              {propsdata.programSchema ? (
                <div
                  className="program_container_box_content"
                  style={{ width: "100%" }}
                >
                  <h4 style={{ fontSize: "16px", color: "#3c4852" }}>
                    Program Json
                  </h4>
                  <div>
                    <Link to={`/Viewlocatonprogramjson${propsdata._id}`}>
                      <button className="edit_program_json">
                        Edit Program Json
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                "Connect to developer to add the program json"
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </div>
  );
}

export default Programlocation;
