import React, { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { getProgramtag } from "../services/marketinggetapi";
import { useNavigate } from "react-router-dom";
import { TagPicker } from "rsuite";
import Webinar from "./Webinars";
import { addWebinarApi, webinarApprovalApi } from "../services/webinarapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";
import { Flag } from "@mui/icons-material";

function AddWebinar() {
  const [loading, setLoading] = useState(false);
  const [domainTag, setDomainTag] = useState("");
  const [webinarData, setWebinarData] = useState({
    topic: "",
    description: "",
    date: "",
    startTime: "",
    endTime: "",
    liveLink: "",
    thumbnail: "",
    domain: [],
    placeholderTag: [],
  });

  const navigate = useNavigate();

  const placeholderTag = [
    { label: "HEADER", value: "HEADER" },
    { label: "RECOMMENDED", value: "RECOMMENDED" },
    { label: "RECORDED", value: "RECORDED" },
  ];

  useEffect(() => {
    getPtag().then((data) => {
      setDomainTag(data);
      setLoading(false);
    });

    async function getPtag() {
      setLoading(true);
      return await getProgramtag();
    }
  }, []);

  const updatewebinarData = (E) => {
    if (E.target.name === "topic" && E.target.value.length > 60) {
      alert("You have reached max character limit i.e 60 characters");
      return;
    }

    if (E.target.name === "description" && E.target.value.length > 130) {
      alert("You have reached max character limit i.e 130 characters");
      return;
    }

    setWebinarData((prev) => ({
      ...prev,
      [E.target.name]: E.target.value,
    }));
  };

  const handleDomainChange = (values) => {
    setWebinarData((prevState) => ({
      ...prevState,
      domain: values,
    }));
  };

  const handlePlaceholderChange = (values) => {
    setWebinarData((prevState) => ({
      ...prevState,
      placeholderTag: values,
    }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      if (webinarData.thumbnail) {
        const url = webinarData.thumbnail;

        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = function () {
            if (img.width % 585 !== 0 || img.height % 324 !== 0) {
              // alert('Image dimensions must be equal to or multiples of 585x324.');
              reject(
                new Error(
                  "Thumbnail dimensions must be equal to or multiples of 585x324."
                )
              );
            } else {
              console.log("Image dimensions are correct.");
              resolve();
            }
          };
          img.onerror = function () {
            // alert('Failed to load image. Enter Proper URL');
            reject(
              new Error("Failed to load Thumbnail image. Enter Proper URL")
            );
          };

          img.src = url;
        });
      }

      webinarApproval().then((response) => {
        if (response.data.success == true) {
          getOtprequest(
            {
              Fieldchange: ["New Webinar"],
              Newdata: webinarData,
              Olddata: { Data: "New Webinar Event" },
            },
            response.data.data
          );

          alert("Approval Sent Successfully !!!");
          // navigate("/Approvalwindow");
          window.open("/Approvalwindow", "_self");
        } else {
          alert("Something Went Wrong.");
        }
      });

      async function webinarApproval() {
        return await webinarApprovalApi(false, webinarData);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div className="home_title_div">
          <h1 className="home_title">Create Webinar</h1>
        </div>

        <div className="createUtmmaindiv">
          <div className="createUtmbottomhalf">
            <form onSubmit={submitHandler}>
              <div>
                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Topic <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Topic"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.topic}
                    onChange={updatewebinarData}
                    name="topic"
                  />
                  <label className="note_label">
                    Note : Maximum 60 characters allowed
                  </label>
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Description{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Description"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.description}
                    onChange={updatewebinarData}
                    name="description"
                  />
                  <label className="note_label">
                    Note : Maximum 130 characters allowed
                  </label>
                </div>

                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      Webinar Data <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set time and date"
                      className="create_auxinput2"
                      required
                      type="date"
                      value={webinarData.date}
                      onChange={updatewebinarData}
                      name="date"
                    />
                  </div>

                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      Start Time <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set Start time"
                      className="create_auxinput2"
                      required
                      type="time"
                      value={webinarData.startTime}
                      onChange={updatewebinarData}
                      name="startTime"
                    />
                  </div>

                  <div>
                    <h6 style={{ paddingBottom: "10px" }}>
                      End Time <span className="createUtmmandatory">*</span>
                    </h6>
                    <input
                      placeholder="Set End time"
                      className="create_auxinput2"
                      required
                      type="time"
                      value={webinarData.endTime}
                      onChange={updatewebinarData}
                      name="endTime"
                    />
                  </div>
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Live link{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Enter Webinar Live Link"
                    className="create_auxinput2"
                    required
                    type="text"
                    value={webinarData.liveLink}
                    onChange={updatewebinarData}
                    name="liveLink"
                  />
                </div>

                <div>
                  <h6 style={{ paddingBottom: "10px" }}>
                    Webinar Thumbnail{" "}
                    <span className="createUtmmandatory">*</span>
                  </h6>
                  <input
                    placeholder="Webinar Thumbnail Link"
                    className="create_auxinput3"
                    required
                    type="text"
                    value={webinarData.thumbnail}
                    onChange={updatewebinarData}
                    name="thumbnail"
                  />
                  <label className="note_label">
                    Note : Dimension should be of 585 * 324
                  </label>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <h6 style={{ paddingBottom: "10px" }}>Domain Tags </h6>
                  <TagPicker
                    data={domainTag}
                    value={webinarData.domain}
                    onChange={handleDomainChange}
                    style={{ width: "100%" }}
                    placeholder="Select Tags"
                  />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <h6 style={{ paddingBottom: "10px" }}>Placeholder Tags </h6>

                  <TagPicker
                    data={placeholderTag}
                    value={webinarData.placeholderTag}
                    onChange={handlePlaceholderChange}
                    style={{ width: "100%" }}
                    placeholder="Select Tags"
                  />
                </div>
              </div>

              <div className="request_approval" style={{ display: "flex" }}>
                <button
                  type="submit"
                  id="edit-submit-btn"
                  className="request_approval_btn"
                  style={{ marginLeft: "20px" }}
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddWebinar;
