import React, { useState } from "react";
import { useEffect } from "react";
import { getAllNewsScheam } from "../services/newsapi";
import Loader from "./loader/loading";
import { Link } from "react-router-dom";
import NewsModel from "./NewsModel";
import moment from "moment";

function Newsview() {
  const [newsdata, setNewsdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    newsdata().then((data) => {
      setNewsdata(data.data.alldata);
      setLoading(false);
    });

    async function newsdata() {
      setLoading(true);
      return await getAllNewsScheam();
    }
  }, []);

  const opennewsmodel = () => {
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid" style={{ height: "100vh" }}>
        {show && <NewsModel show={show} handleClose={handleClose} />}
        <div className="home_title_div news_heading_buttondiv">
          <h1 className="home_title">News</h1>
          <div>
            <button onClick={opennewsmodel} className="add_news">
              Add News
            </button>
          </div>
        </div>
        <div className="program_container">
          <div lassName="home_table">
            <table className="table table-striped">
              <thead>
                <tr className="uper_table_container">
                  <th scope="col" style={{ paddingRight: "10px" }}>
                    News Description
                  </th>
                  <th scope="col">Last Edited</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {newsdata.length > 0 &&
                  newsdata.map((data, idx) => {
                    return (
                      <tr className="uper_table_container" key={idx}>
                        <th scope="row" style={{ paddingRight: "20px" }}>
                          {data.description}
                        </th>
                        <th>
                          {moment(data.updatedAt).format(
                            "DD/MM/YYYY, h:mm:ss a"
                          )}
                        </th>
                        <th>
                          <Link
                            style={{ textDecoration: "none", color: "#fff" }}
                            to={`/Newsspecific${data._id}`}
                          >
                            <img
                              src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                              alt="edit icon"
                              height="20px"
                              width="20px"
                            />
                          </Link>
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Newsview;
