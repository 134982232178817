import React, { useEffect, useState } from "react";
import WebinarAllField from "../services/webinarapi";
import Loader from "./loader/loading";
import WebinarJsonForm from "../services/webinarFormApi";
import "./css/webinar.css";
// import { CreateNewWebinar } from "../services/webinarapi";

function Settings() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [webinar, setWebinar] = useState(false);
  const [viewuischema, setViewuischema] = useState();
  const [formDataSchema, setFormDataSchema] = useState();
  const [edituischema, setEdituischema] = useState();

  useEffect(() => {
    async function allData() {
      await WebinarJsonForm("Webinar Schema").then((res) => {
        console.log(res);
        setLoading(true);
        setViewuischema(res.data.Viewuischema);
        setFormDataSchema(res.data.FormDataSchema);
        setEdituischema(res.data.Edituischema);
        setLoading(false);
      });
    }
    allData();
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="Main-div">
            <div className="Main-div1">
              <h1 className="Setting-heading">Form Json Schema</h1>
              <div className="Setting-data-div">
                <div className="Setting-data-div1">
                  <pre>{JSON.stringify(formDataSchema, null, 4)}</pre>
                </div>
              </div>
            </div>
            <div className="Main-div1">
              <h1 className="Setting-heading">ViewUi Json Schema</h1>
              <div className="Setting-data-div">
                <div className="Setting-data-div1">
                  <pre>{JSON.stringify(viewuischema, null, 4)}</pre>
                </div>
              </div>
            </div>
            <div className="Main-div1">
              <h1 className="Setting-heading">EditUi Json Schema</h1>
              <div className="Setting-data-div">
                <div className="Setting-data-div1">
                  <pre>{JSON.stringify(edituischema, null, 4)}</pre>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Settings;
