import JSON from "../Components/json/program.json";
const CountryCodes = ({ value, onChange, styles }) => {
  return (
    <div className="form-inpt-contnr">
      <select
        onChange={onChange}
        // defaultValue={firstvalue}
        value={value}
        className="form-inpt enquireFormInput3"
        style={{
          fontSize: "16px",
          padding: "8px",
          backgroundColor: "#fff",
          borderRadius: "4px",
          width: "114px",
          height: "48px",
          border: "1px solid rgba(33, 42, 57, 0.25)",
          boxSizing: "border-box",
          marginRight: "16px",
          cursor: "pointer",
          textDecoration: "none !important",
          color: "#000000 !important",
        }}
        name="CountryCode"
        id="CountryCode"
      >
        {JSON.countryData.map((data, index) => {
          return (
            <option
              key={index}
              data-countrycode={data.countrycode}
              value={data.value}
            >
              {data.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default CountryCodes;
