import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { uploadMediaImages } from "../services/newsapi";
import {
  getApprovalspecificschema,
  getOtprequest,
} from "../services/approvalapi";

function MediaUploaderModel(props) {
  console.log(props, "modal call");

  console.log(props.folder, "folder");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [bannerimg, setbannerimg] = useState();
  const [logoimg, setlogoimg] = useState();
  //   let { id } = useParams();
  const [editdata, setEditdata] = useState({});

  let id = props.id;

  let folderkey = props.folder;

  const buttonClick = async (data) => {
    const formdata = new FormData();
    formdata.append("file", data);
    setLoading(true);
    let body = { ...editdata };
    const res = await uploadMediaImages(id, formdata, folderkey);
    console.log({ res });
    if (res.data.success === true) {
      setlogoimg(res.data.data);
      body["logo"] = res.data.data;
      setEditdata(body);
      alert("Image uploaded successfully ");
      setLoading(false);
    } else {
      if (res.status == 612) {
        alert("Image already present. Please try to rename it");
        setLoading(false);
      } else {
        console.log("in else 2");
        alert("Image Size Exceeded, It should be under 80KB");
        setLoading(false);
      }
    }
  };

  //   const submitHandler = async(e)=>{
  //     e.preventDefault()
  //     const res = await getFolder(id,data);
  //     console.log(res,"my response")
  //     if (res.success === true) {

  //       alert("Folder Created ");
  //       setLoading(false);
  //     } else {
  //       alert("Something Wrong");
  //       setLoading(false);
  //     }
  //   }

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="news_model_container"
      >
        <Modal.Header closeButton> Upload Image</Modal.Header>
        <div className="new_model_div" style={{ padding: "18px" }}>
          <form>
            <div
              className="program_container_box"
              style={{
                display: "flex",
                alignItems: "baseline",
              }}
            >
              <h2>Upload Image</h2>
              <p style={{ color: "red" }}> Maximum image size : 80KB</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <input
                  style={{ marginTop: "10px" }}
                  type="file"
                  accept="image/*"
                  name="file"
                  onChange={(e) => {
                    buttonClick(e.target.files[0]);
                  }}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h1 style={{ fontSize: "18px", opacity: ".75" }}>PREVIEW</h1>

              {logoimg ? (
                <a
                  href={logoimg}
                  target="_blank"
                  style={{ marginLeft: "20px" }}
                >
                  {logoimg}
                </a>
              ) : (
                " "
              )}
            </div>

            {/* <div className="request_approval">
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Create
              </button>
            </div> */}
          </form>
        </div>
      </Modal>
    </>
  );
}

export default MediaUploaderModel;
