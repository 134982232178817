import React from "react";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import { useState } from "react";
import { SchemaRender } from "../services/helper";
function ViewOldSchemaDetail(data) {
  const [propsdata, setPropsdata] = useState(data.propsdata);

  return (
    <div>
      {propsdata.old_schema &&
        SchemaRender(propsdata.schema_name) &&
        Object.keys(propsdata.old_schema).map((data) => {
          if (
            data == "_id" ||
            data == "programSchema" ||
            data == "__v" ||
            data == "act" ||
            data == "created_at" ||
            data == "updatedAt" ||
            data == "pageJson"
          ) {
            console.log("::::::: Data ::::::::", data);
            return;
          }
          console.log("::::::: Data 2 ::::::::", data);
          return (
            <div className="change_content_box">
              <p>{data}</p>
              {data == "tag" ? (
                <h1>{propsdata.old_schema[data].join(", ")}</h1>
              ) : data == "locationNames" ? (
                <h1>{propsdata.old_schema[data].join(", ")}</h1>
              ) : data == "domain" ? (
                propsdata.old_schema[data] ? (
                  <h1>{propsdata.old_schema[data].join(", ")}</h1>
                ) : (
                  "- - -"
                )
              ) : data == "placeholderTag" ? (
                propsdata.old_schema[data] ? (
                  <h1>{propsdata.old_schema[data].join(", ")}</h1>
                ) : (
                  "- - -"
                )
              ) : data == "modeOfLearning" ? (
                <h1>{propsdata.old_schema[data].join(", ")}</h1>
              ) : data === "brochureFileName" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "placementReportName" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "liveLink" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "recordedLink" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "thumbnail" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "link" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "bannerimg" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "logo" ? (
                <a
                  href={propsdata.old_schema[data]}
                  target="_blank"
                  style={{ overflowWrap: "break-word" }}
                >
                  {propsdata.old_schema[data]
                    ? propsdata.old_schema[data]
                    : "---"}
                </a>
              ) : data === "act" ? (
                <p>{propsdata.old_schema[data] == true ? "True" : "False"}</p>
              ) : data === "isMainDomain" ? (
                <p>{propsdata.old_schema[data] == true ? "True" : "False"}</p>
              ) : (
                <h1>{propsdata.old_schema[data]}</h1>
              )}
            </div>
          );
        })}

      {propsdata.old_schema &&
        propsdata.schema_name.includes("Program Schema") && (
          <JSONInput
            id="a_unique_id"
            placeholder={propsdata.old_schema.programSchema}
            width={"493px"}
            locale={locale}
            height=""
            viewOnly={true}
          />
        )}

      {propsdata.old_schema &&
        propsdata.schema_name.includes("Auxiliary Schema") &&
        propsdata.old_schema.pageJson && (
          <JSONInput
            id="a_unique_id"
            placeholder={propsdata.old_schema.pageJson}
            width={"493px"}
            locale={locale}
            height=""
            viewOnly={true}
          />
        )}

      {propsdata.old_schema &&
        !SchemaRender(propsdata.schema_name) &&
        Object.keys(propsdata.old_schema).map((data) => {
          if (data == "_id") return "";
          if (data == "ProgramName") return "";
          if (data == "Location") return "";
          if (data == "__v") return "";
          return (
            <div>
              <div className="webinarHeadingMain">{data}</div>
              <div>
                {Object.keys(propsdata.old_schema[data]).map((data1) => {
                  return (
                    <div>
                      <div className="webinarHeading">{data1}</div>
                      <div>
                        {data1.includes("mage") &&
                          typeof propsdata.old_schema[data][data1] ===
                            "object" &&
                          Object.keys(propsdata.old_schema[data][data1]).map(
                            (data2) => {
                              return (
                                <div className="webinarHeadingDiv">
                                  <div className="webinarHeading2">{data2}</div>
                                  <div>
                                    {propsdata.old_schema[data][data1][data2]}
                                  </div>
                                </div>
                              );
                            }
                          )}
                        {data1.includes("mage") &&
                          typeof propsdata.old_schema[data][data1] !==
                            "object" && (
                            <div>{propsdata.old_schema[data][data1]}</div>
                          )}
                      </div>
                      <div>
                        {data1.includes("Info") &&
                          propsdata.old_schema[data][data1].map(
                            (data3, index) => {
                              return (
                                <>
                                  <div className="webinarHeadingDiv">
                                    <div>
                                      {data1.split("Info")[0] +
                                        " " +
                                        (index + 1)}
                                    </div>
                                    <ul>
                                      {Object.keys(
                                        propsdata.old_schema[data][data1][index]
                                      ).map((data2) => {
                                        if (data2 == "_id") return "";
                                        return (
                                          <li className="webinarHeadingDiv1">
                                            {
                                              propsdata.old_schema[data][data1][
                                                index
                                              ][data2]
                                            }
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </>
                              );
                            }
                          )}
                      </div>
                      <div>
                        {data1.includes("Description") &&
                          propsdata.old_schema[data][data1].map(
                            (data3, index) => {
                              return (
                                <ul className="webinarHeadingDiv">
                                  {Object.keys(
                                    propsdata.old_schema[data][data1][index]
                                  ).map((data2) => {
                                    if (data2 == "_id") return "";
                                    return (
                                      <li className="webinarHeadingDiv1">
                                        {
                                          propsdata.old_schema[data][data1][
                                            index
                                          ][data2]
                                        }
                                      </li>
                                    );
                                  })}
                                </ul>
                              );
                            }
                          )}
                      </div>
                      <div>
                        {data1.includes("career") &&
                          propsdata.old_schema[data][data1].map(
                            (data3, index) => {
                              return (
                                <div>
                                  <div className="webinarHeadingDiv">
                                    {"Student" + " " + (index + 1)}
                                  </div>
                                  <ul>
                                    {Object.keys(
                                      propsdata.old_schema[data][data1][index]
                                    ).map((data2) => {
                                      if (data2 == "_id") return "";
                                      return (
                                        <li className="webinarHeadingDiv2">
                                          {
                                            propsdata.old_schema[data][data1][
                                              index
                                            ][data2]
                                          }
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </div>
                              );
                            }
                          )}
                      </div>
                      {console.log(data1, "data1")}
                      <div>
                        {!data1.includes("career") &&
                          !data1.includes("mage") &&
                          !data1.includes("YoutubeVideoThumbnail") &&
                          !data1.includes("Info") &&
                          !data1.includes("Description") && (
                            <div className="webinarHeadingDiv">
                              {console.log(propsdata.old_schema[data][data1])}
                              {typeof propsdata.old_schema[data][data1] ==
                              "boolean"
                                ? propsdata.old_schema[data][data1] == true
                                  ? "True"
                                  : "False"
                                : propsdata.old_schema[data][data1]}
                            </div>
                          )}
                      </div>
                    </div>
                  );

                  // return (<div>{propsdata.old_schema[data][data1]}</div>)
                })}
              </div>
            </div>
          );
        })}
    </div>
  );
}

export default ViewOldSchemaDetail;
