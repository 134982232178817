import React, { useEffect, useState } from "react";
import Loader from "./loader/loading";
import { getUnProcessedFbLead } from "../services/leaddistribution";
import Pagination2 from "./Pagination2";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const PER_PAGE = 10;
function UnProcessedFbLead() {
  const [currentPage, setCurrentPAge] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [size, setSize] = useState(0);
  const [myindex, setMyindex] = useState(-1);
  const [active, setActive] = useState({
    color: "black",
  });

  useEffect(() => {
    fetchData().then((res) => {
      setData(res.data.data);
      setSize(res.data.size);
      setLoading(false);
    });

    async function fetchData() {
      setLoading(true);
      return await getUnProcessedFbLead(currentPage, PER_PAGE);
    }
  }, []);

  const pageCount = Math.ceil(size / PER_PAGE);
  function handleClick({ selected: selectedPage }) {
    setCurrentPAge(selectedPage);
    fetchData().then((dstaus) => {
      setData(dstaus.data.data);
      setLoading(false);
    });
    async function fetchData() {
      setLoading(true);
      return await getUnProcessedFbLead(selectedPage, PER_PAGE);
    }
  }

  const copylink = (data, index) => {
    let cdata = JSON.stringify(data);
    navigator.clipboard.writeText(cdata);
    setMyindex(index);
    setActive({ color: "green" });
  };

  console.log({ data }, { size });

  return (
    <>
      {loading && <Loader />}
      <div className="home_container">
        <div className="home_title_div">
          <h1 className="home_title">Un Processed FB Leads</h1>
        </div>

        <div className="home_table">
          <table className="table table-striped">
            <thead style={{ border: "2px solid black" }}>
              <tr className="uper_table_container_field">
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  FB Lead Gen ID
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Ad Name
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Add Set Name
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Campaign Name
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Page Name
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Form Name
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Tracking Parameter
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Created Time
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  User Data
                </th>
                <th style={{ borderBottom: "2px solid black" }} scope="col">
                  Copy Data
                </th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((data, idx) => {
                  return (
                    <tr className="uper_table_container" key={idx}>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.leadgenId}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.adName || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.adSetName || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.campaignName || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.pageName || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.formName || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {data.trackingParameter || "--"}
                      </th>
                      <th style={{ fontSize: "14px", fontWeight: "400" }}>
                        {(data.retrieveReqBody &&
                          data.retrieveReqBody.created_time) ||
                          "--"}
                      </th>
                      <th>
                        {data.retrieveReqBody &&
                          data.retrieveReqBody.field_data.map((data2) => {
                            return (
                              <p>
                                {data2.name}: {data2.values[0]}
                              </p>
                            );
                          })}
                      </th>
                      <th>
                        <ContentCopyIcon
                          style={
                            idx !== myindex
                              ? { active }
                              : {
                                  color: "green",
                                }
                          }
                          className="copyicon"
                          onClick={() => {
                            copylink(data, idx);
                          }}
                        />
                      </th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div>
          <Pagination2 handleClick={handleClick} pageCount={pageCount} />
        </div>
      </div>
    </>
  );
}

export default UnProcessedFbLead;
