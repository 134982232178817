import moment from "moment-timezone";

export const Nametorender = (key) => {
  return nametorender[key];
};

let nametorender = {
  name: "Program Name",
  displayName: "Program Title",
  metadesc: "Program Meta Description",
  metakeyword: "Program Meta Keywords",
  ogtitle: "Program Og:Title",
  ogdesc: "Program Og:Description",
  launch_date: "Application Deadline",
  tag: "Program Tag",
  launch_date: "Program Date",
  brochureFileName: "Brochure Link",
  placementReportName: "Placement Report Link",
  startDate: "Program Start Date",
};

export const Deploymentrender = (key) => {
  return deploymentrender[key];
};

let deploymentrender = {
  1: "Not Started",
  2: "Pending Staging Deployment",
  3: "Failed",
  4: "Success",
  5: "Staging Deployed, Production Deployment pending",
  7: "Staging Deployed, Production Deployment pending",
};

export const ProgramName = (data) => {
  let filedData = "";
  if (data.schema_name == "Program Schema") {
    return (filedData = data.old_schema.programTitle);
  } else if (data.schema_name == "Auxiliary Schema") {
    return (filedData = data.old_schema.pageUrl);
  } else if (data.schema_name == "Webinar Schema") {
    return (filedData = data.old_schema.ProgramName);
  } else if (data.schema_name == "News Schema") {
    return (filedData = data.old_schema.description
      ? data.old_schema.description.slice(0, 80)
      : data.old_schema.data);
  } else if (data.schema_name == "Location Schema") {
    return (filedData = data.old_schema.Location);
  } else if (data.schema_name == "Utm Schema") {
    return data.old_schema.utmCampaignType
      ? data.old_schema.utmCampaignType
      : data.old_schema.data;
  } else if (data.schema_name == "Location Program Schema") {
    return (filedData = data.old_schema.programTitle);
  } else if (data.schema_name == "Template Page Schema") {
    return (filedData = data.new_schema.programTitle);
  } else if (data.schema_name == "Webinar Events") {
    return (filedData = data.new_schema.topic);
  } else {
    return (filedData = "---");
  }
};

export function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results;
  if (typeof window !== `undefined`) {
    results = regex.exec(window.location.search);
  }
  return !results ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

let utmNameToRender = {
  utmCampaignType: "utm_campaigntype",
  utmMedium: "utm_medium",
  utmSource: "utm_source",
  utmCampaignName: "utm_campaignname",
  utmCampaign: "utm_campaign",
  utmPlacement: "utm_placement",
  utmAddName: "utm_addname",
  utmAddGroup: "utm_addgroup",
  utmTerm: "utm_term",
  utmAddContent: "utm_addcontent",
};

export const UtmNametorender = (key) => {
  return utmNameToRender[key];
};

export const SchemaRender = (schemaName) => {
  if (schemaName == "Webinar Schema") {
    return false;
  } else if (schemaName == "Location Schema") {
    return false;
  } else {
    return true;
  }
};

export const getSalesGroupName = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return `${data[i].salesDisplayName} (${data[i].email})`;
      }
    }
  }
};

export const getSalesGroupEmail = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return `${data[i].salesDisplayName} (${data[i].email})`;
      }
    }
  }
};

export const getSubSalesGroupName = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return data[i].email;
      }
    }
  }
};

export const getSubSalesGroupEmail = (data, id) => {
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === id) {
        return data[i].email;
      }
    }
  }
};

export const downloadFile = async (res) => {
  return new Promise((resolve, reject) => {
    const contentDisposition = res.headers["content-disposition"];
    let fileName = contentDisposition.split(";")[1].split("=")[1];

    console.log("File name : ", fileName);
    fileName = fileName.replaceAll('"', "");
    console.log("File name : ", fileName);

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;

    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();

    resolve(true);
  });
};

export const convertUtcToIst = (utcTime) => {
  return moment.utc(utcTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
};

export const convertUnixTimeToIst = (unixTime) => {
  const date = new Date(Number(unixTime));
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const day = String(date.getUTCDate()).padStart(2, "0");
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  const seconds = String(date.getUTCSeconds()).padStart(2, "0");
  const milliseconds = String(date.getUTCMilliseconds()).padStart(3, "0");
  let utcTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  return moment.utc(utcTime).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
};

export const GetWebinarAsPerTagName = (webinararr, tag, tag2) => {
  let data = webinararr.filter((c) => {
    if (tag && tag2) {
      return c.tag.includes(tag) && c.tag.includes(tag2);
    }
    return c.tag.includes(tag);
  });
  if (data.length == 0) {
    return [];
  }
  return data.sort((a, b) => (a["created_at"] > b["created_at"] ? 1 : -1));
};
export const ValidateLSKey = (coursearr, lskey) => {
  const customCourseArray = [...coursearr];
  customCourseArray.push({
    leadsquareProgramKey: "Not Eligible",
  });
  if (customCourseArray.filter((c) => c.leadsquareProgramKey == lskey)) {
    return true;
  }
  return false;
};
