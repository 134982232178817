import React from "react";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { Link } from "react-router-dom";
import CreateLocationPageModal from "./CreateLocationPageModal";
import { getLocationProgramPage } from "../services/locationProgram";

function Viewprogramlocationpages() {
  let data = useLocation();
  const [propsdata, setPropsdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [parentUrl, setParentUrl] = useState("");

  useEffect(() => {
    setParentUrl(data.state.url);
    locationData(data.state.url).then((data) => {
      setPropsdata(data.data);
      setLoading(false);
    });
    async function locationData(propsdata) {
      setLoading(true);
      return await getLocationProgramPage(propsdata);
    }
  }, []);

  const toggelModal = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
    window.location.reload();
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid" style={{ height: "100vh" }}>
        {show && (
          <CreateLocationPageModal
            show={show}
            handleClose={handleClose}
            parentUrl={parentUrl}
          />
        )}
        <div className="row" style={{ height: "100%" }}>
          <div className="home_container">
            <div className="home_title_div programnameplusbutton">
              <h1 className="home_title">Program List</h1>
              <div style={{ marginBottom: "10px" }}>
                <button
                  onClick={toggelModal}
                  className="create_location_button"
                >
                  Add New Location Page
                </button>
              </div>
            </div>
            <div className="home_table">
              <table className="table table-striped">
                <thead>
                  <tr className="uper_table_container">
                    <th
                      scope="col"
                      style={{ width: "28%", paddingRight: "10px" }}
                    >
                      Program Url
                    </th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {propsdata.length > 0 &&
                    propsdata.map((data, idx) => {
                      return (
                        <tr className="uper_table_container" key={idx}>
                          <th scope="row" style={{ paddingRight: "20px" }}>
                            {data.link}
                          </th>
                          <th>
                            <Link
                              style={{ textDecoration: "none", color: "#fff" }}
                              to={`/Programlocation${data._id}`}
                            >
                              <img
                                src="https://cdn.pegasus.imarticus.org/iit_roorkee/editicon1.svg"
                                alt="edit icon"
                                height="20px"
                                width="20px"
                              />
                            </Link>
                          </th>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Viewprogramlocationpages;
