import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "../loader/loading";
import AddProgramTarget from "./AddProgramTarget";
import AddSalesGroup from "./AddSalesGroup";
import AddSubSalesGroup from "./AddSubSalesGroup";
import AddAdvisor from "./AddAdvisor";
import AddAdmissionTarget from "./AddAdmissionTarget";
import FindLeadByPhone from "./FindLeadByPhone";

function LeadDistributionModel(props) {
  const [loading, setLoading] = useState(false);
  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="leaddistributionmodel"
      >
        <Modal.Header closeButton className="leadmodelheaderheading">
          {props.show.String}
        </Modal.Header>

        {props.show.String == "Add Sales Group" && (
          <AddSalesGroup close={props.handleClose} />
        )}

        {props.show.String == "Add/Edit Programs & Cost" && (
          <AddProgramTarget close={props.handleClose} />
        )}

        {props.show.String == "Add Sub-sales Group" && (
          <AddSubSalesGroup close={props.handleClose} />
        )}

        {props.show.String == "Add Advisors" && <AddAdvisor />}

        {props.show.String == "Add Admission Targets" && (
          <AddAdmissionTarget close={props.handleClose} />
        )}

        {props.show.String == "Find Lead" && (
          <FindLeadByPhone close={props.handleClose} />
        )}
      </Modal>
    </>
  );
}

export default LeadDistributionModel;
