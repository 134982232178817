import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getAllNewsScheam, showFolder } from "../services/newsapi";
import Loader from "./loader/loading";
import { Link, useHref } from "react-router-dom";
import ImageUploaderModel from "./ImageUploaderModel";
import FolderIcon from "@mui/icons-material/Folder";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

function ImageUploader() {
  let { id } = useParams();
  console.log(id);
  const [newsdata, setNewsdata] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const [active, setActive] = useState({
    color: "black",
  });
  const [myindex, setMyindex] = useState(-1);

  const [myindex2, setMyindex2] = useState(-1);

  let mypreview = "https://webcdn.imarticus.org/logos/preview1.png";
  const [link, setLink] = useState(mypreview);
  const parts = link.split("/");
  const filename = parts[parts.length - 1];
  useEffect(() => {
    newsdata().then((data) => {
      setNewsdata(data.data.data);
      setLoading(false);
    });

    async function newsdata() {
      setLoading(true);
      return await showFolder(id);
    }
  }, []);

  const opennewsmodel = () => {
    setShow(true);
  };

  {
  }

  const handleClose = () => {
    return setShow(false), window.location.reload(true);
  };

  //   const something = useLocation();

  console.log(newsdata, "=========");
  //   let folderArr = [];
  //   for (let i = 0; i < something.state.folder.length; i++) {
  //     if (something.state.folder[i].name == "folder") {
  //       let tags = something.state.folder[i].value;
  //       console.log(tags, "folder")
  //       folderArr = tags.split(",");
  //       break;
  //     }
  //   }
  //   console.log(folderArr, "folderdata")

  //   console.log(something.state.folder, "folderdata1")
  //   let navigate = useNavigate();

  const copylink = (data, index) => {
    navigator.clipboard.writeText(data);
    setMyindex(index);
    setActive({ color: "green" });
  };

  const preview = (data, index) => {
    setMyindex2(index);
    setLink(data);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="container-fluid" style={{ height: "100vh" }}>
        {show && (
          <ImageUploaderModel
            id={newsdata._id}
            folder={newsdata.folder}
            show={show}
            handleClose={handleClose}
          />
        )}
        <div className="home_title_div news_heading_buttondiv">
          <h1 className="home_title">{newsdata.folder}</h1>
        </div>
        <div className="image-grid-structure">
          <div className="grid-left">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "18px" }}>
                      Image File
                    </TableCell>
                    <TableCell style={{ fontSize: "18px" }} align="left">
                      CDN Link
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {newsdata &&
                    newsdata.image.map((data, index) => {
                      return (
                        <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <p>{data.split("/").pop()}</p>
                            </TableCell>

                            <TableCell align="left">
                              <a href={data} target="_blank">
                                {data}
                              </a>{" "}
                              &nbsp;&nbsp;
                              <ContentCopyIcon
                                style={
                                  index !== myindex
                                    ? { active }
                                    : {
                                        color: "green",
                                      }
                                }
                                className="copyicon"
                                onClick={() => {
                                  copylink(data, index);
                                }}
                              />
                            </TableCell>

                            <TableCell component="th" scope="row">
                              <RemoveRedEyeOutlinedIcon
                                className="preview"
                                onClick={() => {
                                  preview(data, index);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="grid-right">
            <div
              style={{
                position: "sticky",
                top: "125px",
              }}
            >
              <div>
                <button onClick={opennewsmodel} className="add_news">
                  Upload Image
                </button>
              </div>
              <div className="preview">
                <p>Preview</p>
              </div>
              <div
                className="preview-image"
                style={{
                  backgroundImage: `url(${link})`,
                  backgroundRepeat: "no-repeat",
                }}
              >
                {/* <img className="preview-image" src={link}></img> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ImageUploader;
