import React from "react";
import { Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Loader from "./loader/loading";
import { createLocationProgramPage } from "../services/locationProgram";
function CreateLocationPageModal(props) {
  console.log(props, "modal call");
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [location, setLocation] = useState("");
  useEffect(() => {
    let url = props.parentUrl;
    url = url.substring(0, url.length - 1);
    url = url + "-";
    setUrl(url);
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    let fullUrl = url + location.trim();
    fullUrl = fullUrl + "/";
    fullUrl = fullUrl.toLowerCase();
    console.log(fullUrl);
    try {
      setLoading(true);
      let data = await createLocationProgramPage({
        parentUrl: props.parentUrl,
        newPageUrl: fullUrl,
      });
      if (!data) {
        alert("Please fill the details");
        setLoading(false);
        return;
      }
      setLoading(false);
      alert("Successfully Added");
      props.handleClose();
    } catch (error) {
      console.log(error);
      setLoading(false);
      alert(error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={props.handleClose}
        className="news_model_container"
      >
        <Modal.Header closeButton></Modal.Header>
        <div className="new_model_div" style={{ padding: "18px" }}>
          <form onSubmit={submitHandler}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              ></div>
              <h6>{url}</h6>
              <input
                value={location}
                type="text"
                onChange={(e) => setLocation(e.target.value)}
                className="form-inpt newLocationInput"
                name="Description"
                required
                style={{ textTransform: "lowercase" }}
                placeholder="chennai"
                maxLength={40}
              />
            </div>

            <div className="request_approval">
              <button
                type="submit"
                id="edit-submit-btn"
                className="request_approval_btn"
              >
                Add Location Page
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
}

export default CreateLocationPageModal;
