import axios from "axios";
import { configs } from "../constants";
import Cookies from "universal-cookie";

export async function getSeopageSchema(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/gt_aux_page_content?id=${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function editAuxpage(id, data) {
  try {
    const cookies = new Cookies();
    let aid = cookies.get("aid", { domain: configs.DOMAIN, path: "/" });
    return await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/editseoschema?id=${id}`,
      {
        ...data,
        aid,
      },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export async function getAuxapprovalspecificschema(id) {
  const cookies = new Cookies();
  try {
    const res = await axios.get(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/get_aux_specific_approval?id=${id}`,
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

export async function createAuxPage(data) {
  const cookies = new Cookies();
  try {
    const res = await axios.post(
      `${configs.MARKETING_ENDPOINT}/marketingopspanel/create_aux_page`,
      {
        data,
      },
      {
        headers: {
          "x-access-token": cookies.get("at", {
            domain: configs.DOMAIN,
            path: "/",
          }),
        },
      }
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
