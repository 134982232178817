import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TablePagination,
  TableHead,
  TableBody,
  Skeleton,
} from "@mui/material";

import {
  getAllLead,
  leadSquareKeys,
  Qualification,
} from "../services/leadsquareapi";

function Lead() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [leaddata, setleadData] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    leadData().then((data) => {
      setleadData(data.data);
      console.log(data.data);
      setLoading(false);
    });

    async function leadData() {
      setLoading(true);
      return await getAllLead();
    }
    //to get the array to be displayed
  }, []);

  return (
    <>
      <TableContainer className="mainTable">
        <Table aria-label="custom pagination table">
          <TableHead>
            <TableRow className="leadtablefont">
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Country Code</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Aid</TableCell>
              <TableCell>Pid</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Country</TableCell>
              <TableCell>Conversion Name Hash</TableCell>
              <TableCell>Conversion Email Hash</TableCell>
              <TableCell>Conversion Contact Hash</TableCell>
              <TableCell>Account Email Hash</TableCell>
              <TableCell>Account Contact Hash</TableCell>
              <TableCell>Facebook Sync Status</TableCell>
              <TableCell>LeadSquare Sync Status</TableCell>
              <TableCell>isCallCentreLead</TableCell>
              <TableCell>isUnQualifiedLead</TableCell>
              <TableCell>Highest Qualification</TableCell>
              <TableCell>should_lead_be_saved_on_ls</TableCell>
              <TableCell>Mode Of Delivery</TableCell>
              <TableCell>Book A Time Slot</TableCell>
              <TableCell>Program From Website</TableCell>
              <TableCell>Source Campaign</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>mx_Campaign_Program</TableCell>
              <TableCell>mx_Location_ID</TableCell>
              <TableCell>HTTP Referrer URL</TableCell>
              <TableCell>mx_Website_Page_URL</TableCell>
              <TableCell>mx_utm_medium</TableCell>
              <TableCell>mx_Channel</TableCell>
              <TableCell>mx_City</TableCell>
              <TableCell>mx_New_WebinarID</TableCell>
              <TableCell>mx_Message</TableCell>
              <TableCell>Source Content</TableCell>
              <TableCell>mx_Current_job_function</TableCell>
              <TableCell>mx_Campaign_type</TableCell>
              <TableCell>linkedInId</TableCell>
              <TableCell>mx_Experience</TableCell>
              <TableCell>asyncReqId</TableCell>
              <TableCell>ls_retry_count</TableCell>
              <TableCell>leadSource</TableCell>
              {/*<TableCell>mx_Website_SourceCampaign</TableCell> */}
              <TableCell>mx_Lead_Source_Detail</TableCell>
              <TableCell>mx_keyword_utm_term</TableCell>
              <TableCell>mx_ad_group</TableCell>
              <TableCell>mx_free_Demo_Requested</TableCell>
              <TableCell>mx_lead_device</TableCell>
              <TableCell>act</TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Skeleton variant="rectangular" width={100} height={30} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : leaddata?.length > 0 ? (
            <React.Fragment>
              <TableBody>
                {leaddata.map(
                  (
                    row,
                    index //name, location, l_date
                  ) => (
                    <TableRow key={row.index}>
                      <TableCell>{row.FirstName || "--"}</TableCell>
                      <TableCell>{row.EmailAddress || "--"}</TableCell>
                      <TableCell>{row.CountryCode || "--"}</TableCell>
                      <TableCell>{row.Phone || "--"}</TableCell>
                      <TableCell>{row.mx_Program_3 || "--"}</TableCell>
                      <TableCell>{row.aid || "--"}</TableCell>
                      <TableCell>{row.pid || "--"}</TableCell>
                      <TableCell>{row.mx_Training_Location || "--"}</TableCell>
                      <TableCell>{row.mx_country || "--"}</TableCell>
                      <TableCell>{row.name_hash || "--"}</TableCell>
                      <TableCell>{row.email_hash || "--"}</TableCell>
                      <TableCell>{row.cc_ph_hash || "--"}</TableCell>
                      <TableCell>{row.email_hash_account || "--"}</TableCell>
                      <TableCell>{row.cc_ph_hash_account || "--"}</TableCell>
                      <TableCell>
                        {row.facebookSync ? "true" : "false"}
                      </TableCell>
                      <TableCell>
                        {row.is_lead_saved_ls ? "true" : "false"}
                      </TableCell>
                      <TableCell>
                        {row.should_lead_be_saved_on_ls === false 
                        && row.mx_Program_3 != "Not Eligible" && 
                        row.mx_H_Qualification !== Qualification &&
                        !leadSquareKeys.includes(row.mx_Program_3)
                          ? "true"
                          : "false"}
                      </TableCell>
                      <TableCell>
                        {row.should_lead_be_saved_on_ls === false
                        && row.mx_Program_3  == "Not Eligible" &&
                        row.mx_H_Qualification == Qualification &&
                        leadSquareKeys.includes(row.mx_Program_3)
                          ? "true"
                          : "false"}
                      </TableCell>
                      <TableCell>{row.mx_H_Qualification || "--"}</TableCell>
                      <TableCell>
                        {row.should_lead_be_saved_on_ls == true
                          ? "true"
                          : "false"}
                      </TableCell>
                      <TableCell>{row.mx_Mode_of_Delivery || "--"}</TableCell>
                      <TableCell>{row.mx_Book_a_time_slot || "--"}</TableCell>
                      <TableCell>
                        {row.mx_Program_from_website || "--"}
                      </TableCell>
                      <TableCell>{row.SourceCampaign || "--"}</TableCell>
                      <TableCell>{row.Source || "--"}</TableCell>
                      <TableCell>{row.mx_Campaign_Program || "--"}</TableCell>
                      <TableCell>{row.mx_Location_ID || "--"}</TableCell>
                      <TableCell>{row.HTTPReferrerURL || "--"}</TableCell>
                      <TableCell>{row.mx_Website_Page_URL || "--"}</TableCell>
                      <TableCell>{row.mx_utm_medium || "--"}</TableCell>
                      <TableCell>{row.mx_Channel || "--"}</TableCell>
                      <TableCell>{row.mx_City || "--"}</TableCell>
                      <TableCell>{row.mx_New_WebinarID || "--"}</TableCell>
                      <TableCell>{row.mx_Message || "--"}</TableCell>
                      <TableCell>{row.SourceContent || "--"}</TableCell>
                      <TableCell>
                        {row.mx_Current_job_function || "--"}
                      </TableCell>
                      <TableCell>{row.mx_Campaign_type || "--"}</TableCell>
                      <TableCell>{row.linkedInId || "--"}</TableCell>
                      <TableCell>{row.mx_Experience || "--"}</TableCell>
                      <TableCell>{row.asyncReqId || "--"}</TableCell>
                      <TableCell>{row.ls_retry_count || "--"}</TableCell>
                      <TableCell>{row.leadSource || "--"}</TableCell>
                      {/*<TableCell>{row.mx_Website_SourceCampaign || '--'}</TableCell> */}
                      <TableCell>{row.mx_Lead_Source_Detail || "--"}</TableCell>
                      <TableCell>{row.mx_keyword_utm_term || "--"}</TableCell>
                      <TableCell>{row.mx_ad_group || "--"}</TableCell>
                      <TableCell>
                        {row.mx_free_Demo_Requested || "--"}
                      </TableCell>
                      <TableCell>{row.mx_lead_device || "--"}</TableCell>
                      <TableCell>
                        {row.act == true ? "true" : "false"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </React.Fragment>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={8}
                  align="center"
                >
                  <span style={{ textAlign: "center", fontSize: "18spanx" }}>
                    No entries fetched.
                  </span>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

export default Lead;
