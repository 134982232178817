import React, { useEffect, useState } from "react";
import Loader from "./loader/loading";
import { getUtm } from "../services/utmapi";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { UtmNametorender } from "../services/helper";

function UtmDetail() {
  const { id } = useParams();
  const [copyText, setcopyText] = useState("");
  const [copyImage, setcopyImage] = useState(
    "https://cdn.pegasus.imarticus.org/b2b/Copy.svg"
  );
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    getUtmdata().then((data) => {
      setPropsdata(data.data.data);
      setLoading(false);
    });

    async function getUtmdata() {
      setLoading(true);
      return await getUtm(id);
    }
  }, []);

  function handlecheckbox(e) {
    let arrayToDo = [];
    if (e.target.checked) {
      if (checkbox && checkbox.length > 0) {
        arrayToDo = [
          ...checkbox,
          { name: e.target.name, value: e.target.value },
        ];
      } else arrayToDo = [e.target.name.trim()];
    } else {
      var index = checkbox
        .map((data) => data.name)
        .indexOf(e.target.name.trim());
      if (index !== -1) {
        checkbox.splice(index, 1);
        arrayToDo = checkbox;
      } else {
        arrayToDo = checkbox;
      }
    }

    setCheckbox([...arrayToDo]);
    return;
  }

  const [loading, setLoading] = useState(false);
  const [propsdata, setPropsdata] = useState("");
  const [checkbox, setCheckbox] = useState([{ name: "", value: "" }]);

  const handleCopy = () => {
    navigator.clipboard.writeText(string);
    setcopyImage("https://cdn.pegasus.imarticus.org/b2b/Tick.svg");
    setIsCopied(true);
  };

  useEffect(() => {
    let timerid;
    timerid = setTimeout(() => {
      setcopyImage("https://cdn.pegasus.imarticus.org/b2b/Copy.svg");
      setIsCopied(false);
    }, 1000);
    return () => {
      clearTimeout(timerid);
    };
  }, [isCopied]);

  let temp = Object.keys(propsdata);
  let string = "?";
  for (let i = 0; i < temp.length; i++) {
    if (
      temp[i] == "_id" ||
      temp[i] == "callCenterLead" ||
      temp[i] == "otpRecaptchaValidation" ||
      temp[i] == "act" ||
      temp[i] == "createdAt" ||
      temp[i] == "updatedAt" ||
      temp[i] == "leadSource" ||
      temp[i] == "__v"
    ) {
      continue;
    } else {
      console.log(i);
      console.log("================================");
      console.log(string);
      string += `${
        propsdata[temp[i]]
          ? `${UtmNametorender(temp[i])}=${propsdata[temp[i]]}`
          : ""
      }&`;
    }
  }

  console.log("================================================");
  console.log(string);
  string = string.substring(0, string.length - 1);
  string = string.replaceAll(" ", "");
  // let string  = `? ${propsdata.utmCampaignType?'utm_campaigntype'=propsdata.utmCampaignType: ''}`

  // let string = propsdata.utmCampaignType?`?utmCampaignType=${propsdata.utmCampaignType}`:""
  // console.log(string);

  return (
    <>
      {loading && <Loader />}
      <div className="main_container_edit_viewCourse">
        <div>
          <h1 className="program_page">UTM's Attributes</h1>
        </div>
        <div className="program_container">
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Campaign Type</h2>
              <h1>{propsdata.utmCampaignType || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Medium</h2>
              <h1>{propsdata.utmMedium || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Source</h2>
              <h1>{propsdata.utmSource || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Lead Source</h2>
              <h1>{propsdata.leadSource || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Campaign Name</h2>
              <h1>{(propsdata && propsdata.utmCampaignName) || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Campaign</h2>
              <h1>{(propsdata && propsdata.utmCampaign) || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Add Group</h2>
              <h1>{propsdata.utmAddGroup || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Placement</h2>
              <h1>{propsdata.utmPlacement || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Add Content</h2>
              <h1>{propsdata.utmAddContent || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Add Name</h2>
              <h1>{propsdata.utmAddName || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Add Group</h2>
              <h1>{propsdata.utmAddGroup || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Utm Term</h2>
              <h1>{propsdata.utmTerm || "--"}</h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Call Center Lead</h2>
              <h1>
                {propsdata && propsdata.callCenterLead ? "true" : "false"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <div className="program_container_box">
              <h2>Otp Recaptcha Validation</h2>
              <h1>
                {propsdata && propsdata.otpRecaptchaValidation
                  ? "true"
                  : "false"}
              </h1>
            </div>
          </div>
          <div className="program_container_inside">
            <input
              onClick={handlecheckbox}
              className="check_box"
              type="checkbox"
              id=""
              name="act"
              value={propsdata.act || "---"}
            />
            <div className="program_container_box">
              <h2>Delete</h2>
              <h1>{(propsdata && propsdata.act) || "--"}</h1>
            </div>
          </div>
          <div className="program_container_box_copy">
            <div>
              <h1>{string || "--"}</h1>
            </div>
            <div onClick={handleCopy} className="copybtndivutmdetail">
              <img src={copyImage} width={20} height={20} />
              {/* <p>Copied</p> */}
            </div>
          </div>
        </div>
      </div>
      <div>
        {checkbox && checkbox.length >= 2 ? (
          <div className="floater_edit">
            <p>{checkbox.length - 1} Field Selected</p>
            <Link
              to={`/UtmEdit${propsdata._id}`}
              state={{ checkbox }}
              style={{ textDecoration: "none" }}
            >
              <button className="floater_button">
                <p>Edit</p>
              </button>
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default UtmDetail;
