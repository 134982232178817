import React, { useState, useEffect } from "react";
import Form from "react-jsonschema-form";
import { useNavigate, useParams } from "react-router-dom";
import WebinarJsonForm from "../services/webinarFormApi";
import Loader from "./loader/loading";
import "./css/webinar.css";
import { getAllLocationPage } from "../services/locationapi";
function LocationDetail() {
  const [data, setData] = useState();
  const [viewuischema, setViewuischema] = useState();
  const [formDataSchema, setFormDataSchema] = useState();
  const [edituischema, setEdituischema] = useState();
  const [loading, setLoading] = useState(true);
  const [programName, setProgramName] = useState();
  const { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    getAllData().then((res) => {
      let ApiData = res.data.data;
      setProgramName(res.data.data.Location);
      for (let i in ApiData) {
        if (
          i == "_id" ||
          i == "createdAt" ||
          i == "updatedAt" ||
          i == "Location" ||
          i == "__v"
        ) {
          delete ApiData[i];
        }
      }
      for (let i in ApiData) {
        for (let j in ApiData[i]) {
          let data1 = ApiData[i][j];
          delete ApiData[i][j];
          ApiData[i][j] = { data1: data1 };
        }
      }
      setData(ApiData);
      setLoading(false);
    });

    async function getAllData() {
      setLoading(true);
      await WebinarJsonForm("Location Schema").then((res) => {
        setViewuischema(res.data.Viewuischema);
        setFormDataSchema(res.data.FormDataSchema);
        setEdituischema(res.data.Edituischema);
      });
      return await getAllLocationPage(id);
    }
  }, []);

  const handleclick = (e) => {
    navigate(`/LocationEdit${id}`, {
      state: {
        state: e.formData,
        edituischema: edituischema,
        formDataSchema: formDataSchema,
      },
    });
  };
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="main_container_edit_viewCourse">
          <h1 className="header_location">{programName}</h1>
          <Form
            schema={formDataSchema}
            uiSchema={viewuischema}
            formData={data}
            onSubmit={(e) => {
              handleclick(e);
            }}
          >
            <div>
              <button type="submit" className="button">
                <p>Edit Fields</p>
              </button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
}

export default LocationDetail;
