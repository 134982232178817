import React, { useState, useEffect } from "react";
import Loader from "../loader/loading";
import {
  getPgLsKeyAndCost,
  addProgramCost,
} from "../../services/leaddistribution";

function AddProgramTarget(props) {
  const [program, setProgram] = useState("");
  const [programList, setProgramList] = useState();
  const [target, setTarget] = useState("");
  const [loading, setLoading] = useState();

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    let d = {
      program: program,
      target: target,
    };
    let response = await addProgramCost(d);
    if (response.status === 200) {
      alert(response.data.data);
    } else if (response.status === 420) {
      alert(response.data.message);
    } else alert("Something went wrong");
    setLoading(false);
  };

  useEffect(() => {
    getPrograms()
      .then((data) => {
        setProgramList(data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    async function getPrograms() {
      setLoading(true);
      return await getPgLsKeyAndCost();
    }
  }, []);

  const SetProgram = (e) => {
    setProgram(e.target.value);
    let temp =
      programList &&
      programList.filter((data) => data.leadsquareProgramKey == e.target.value);
    console.log({ temp });
    temp = (temp && temp[0]?.programCost) || 0;
    setTarget(temp);
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <form onSubmit={submitHandler}>
          <div className="addprogram2container">
            <div>
              <h3 className="addprogramheading">Select Program</h3>
              <div>
                <select
                  value={program}
                  onChange={(e) => SetProgram(e)}
                  required
                  className="addprograminput"
                  name="cars"
                  id="cars"
                >
                  <option disabled selected value="">
                    Select Program
                  </option>
                  {programList &&
                    programList.map((data) => {
                      return (
                        <option value={data.leadsquareProgramKey}>
                          {data.leadsquareProgramKey}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div>
              <h3 className="addprogramheading">Add Program Cost</h3>
              <div>
                <input
                  value={target}
                  className="addprograminput"
                  placeholder="Enter Amount"
                  type="number"
                  id="target"
                  required
                  onChange={(e) => setTarget(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="addprogrambtns">
            <button onClick={props.close} className="addprogramcancelbtn">
              Cancel
            </button>
            <button
              onClick={() => submitHandler()}
              className="addprogramsubmitbtn"
            >
              Done
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddProgramTarget;
