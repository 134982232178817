import "./sideHeader.css";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { configs } from "../constants";
import Loader from "./loader/loading";
import Leadsideheader from "./Leadsideheader";
import MainSideHeader from "./MainSideHeader";
const SideHeader = (props) => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(false);
  const [paths, setPaths] = useState(window.location.href.split("/")[3] || "");

  useEffect(() => {
    setPaths(window.location.href.split("/")[3] || "");
  }, []);

  useEffect(() => {
    setPaths(window.location.href.split("/")[3] || "");
  }, [window.location.href]);

  function handleclick(event, path) {
    console.log(path);
    setPaths(path);
  }

  let history = useNavigate();
  const logout = () => {
    setLoading(true);
    cookies.remove("at", { domain: configs.DOMAIN, path: "/" });
    cookies.remove("aid", { domain: configs.DOMAIN, path: "/" });
    cookies.remove("pid", { domain: configs.DOMAIN, path: "/" });
    cookies.remove("rt", { domain: configs.DOMAIN, path: "/" });
    cookies.remove("isLoggedIn", { domain: configs.DOMAIN, path: "/" });
    cookies.remove("cid", { domain: configs.DOMAIN, path: "/" });
    setLoading(false);
    window.open("/", "_self");
  };
  return (
    <>
      {loading && <Loader />}
      <div
        style={{
          backgroundColor: "#055646",
          width: "100%",
          height: "150vh",
          paddingTop: "35px",
        }}
      >
        <ul style={{ paddingLeft: "0rem" }}>
          {paths.includes("LeadDistributionPanel") ||
          paths.includes("WebinarCampaign") ||
          paths.includes("FbFormMapper") ||
          paths.includes("LeadDistributionTarget") ? (
            <Leadsideheader
              props={props}
              leadData={props.leadData}
              paths={paths}
              handleclick={handleclick}
              resetLeadFilter={props.resetLeadFilter}
            />
          ) : (
            <MainSideHeader
              props={props}
              handleclick={handleclick}
              paths={paths}
            />
          )}
        </ul>
        <div style={{ padding: "28%", position: "absolute", bottom: "30px" }}>
          <button className="logout" onClick={logout}>
            Log Out
          </button>
        </div>
      </div>
    </>
  );
};

export default SideHeader;
