import React, { useEffect } from "react";
import JSONInput from "react-json-editor-ajrm";
import { useParams } from "react-router-dom";
import locale from "react-json-editor-ajrm/locale/en";
import { useState } from "react";
import { getOtprequest } from "../services/approvalapi";
import { getApprovalspecificschema } from "../services/approvalapi";
import { editCourseApi } from "../services/editCourseapi";
import Loader from "./loader/loading";
import { getSeopageSchema, editAuxpage } from "../services/seoapproval";

function ViewPageContent() {
  let { id } = useParams();
  const [propsdata, setPropsdata] = useState();
  const [editdata, setEditdata] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    specificdata().then((data) => {
      console.log({ data });
      setPropsdata(data.data.specificdata.pageJson);
      setLoading(false);
    });

    async function specificdata() {
      setLoading(true);
      return await getSeopageSchema(id);
    }
  }, []);

  function isJson(item) {
    item = typeof item !== "string" ? JSON.stringify(item) : item;
    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }
    if (typeof item === "object" && item !== null) {
      return true;
    }
    return false;
  }

  let onC = (e) => {
    console.log({ e }, "_____+++++");
    setEditdata({ pageJson: e.jsObject });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      let body = editdata;
      const isvalid = isJson(body);
      if (!isvalid) {
        alert("Please Provide Valid Json");
        return;
      }
      console.log({ body });
      const data = await editAuxpage(id, body);

      programJson().then((something) => {
        console.log({ something });
        getOtprequest(
          {
            Fieldchange: something.data.data.field_name,
            Newdata: something.data.data.new_schema,
            Olddata: something.data.data.old_schema,
          },
          something.data.data._id
        );
        // console.log(maildata, ":::::::::::::::;;maildata");
        setLoading(false);
        alert("Successfully Added");
        window.open("/Approvalwindow", "_self");
      });

      async function programJson() {
        return await getApprovalspecificschema(data.data.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      alert("Only use the white listed cdn url");
    }
  };
  return (
    <>
      {loading && <Loader />}

      <div style={{ marginTop: "-35px" }}>
        <div className="programjsoninstruction">
          <ul>
            <h6>Rules For Edit the Page JSON:</h6>
            <li>
              1. Don’t change keys (Keys are of{" "}
              <spna style={{ color: "#3CA8EE", fontWeight: "bold" }}>Blue</spna>{" "}
              colors).
            </li>
            <li>
              <li>
                2. Editing this JSON will impact Tablet, Mobile, Website views
              </li>
              3. Don’t make a change unless you are sure (check with tech-team
              for clarification).
            </li>
            <li>
              4. If you are changing text - feel free to do the same - but make
              sure that text length before and after are similar to ensure that
              UI works properly.
            </li>
            <li>
              5. Don't use the external urls for images. Their host should be
              one of ('webcdn.imarticus.org', 'cdn.pegasus.imarticus.org',
              'imarticus.org', 'blog.imarticus.org')
            </li>
            <li>
              6. If the{" "}
              <spna style={{ color: "green", fontWeight: "bold" }}>
                Green tick
              </spna>{" "}
              turn into{" "}
              <span style={{ color: "red", fontWeight: "bold" }}>Red</span> then
              please don't send the approval request and contact to tech-team.
            </li>
            <li>
              7. Only whitelisted urls are allowed eg: 'webcdn.imarticus.org',
              'cdn.pegasus.imarticus.org', 'imarticus.org',
              'blog.imarticus.org'.
            </li>
            <li>8. Only use WEBP, SVG, JPG and PNG format images.</li>
          </ul>
        </div>

        <form onSubmit={submitHandler}>
          <JSONInput
            id="a_unique_id"
            placeholder={propsdata}
            width={"80vw"}
            locale={locale}
            onChange={onC}
            height=""
          />
          <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
            <button
              type="submit"
              id="edit-submit-btn"
              className="request_approval_btn"
            >
              Request Approval
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ViewPageContent;
